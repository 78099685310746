import {useWorkspacesContext} from '@/components/sections/workspaces/WorkspacesProvider/WorkspacesContext';
import useGetUserInfo from './useGetUserInfo';
import {useMemo} from 'react';
import useFeatureFlag from '../user/useFeatureFlag';
import MedeinaFeatures from '@/util/features';

// Check if user is an owner of the selected workspace or is an admin
export default function useIsOwner(): {
    isAnyOwner?: boolean;
    isOwner?: boolean;
    isLoading?: boolean;
} {
    const {
        ownerOfSelectedWorkspace,
        ownerOfAWorkspace,
        isLoading: isLoadingWorkspaceOwner,
    } = useWorkspacesContext();
    const {data: userAuthInfo, isLoading: isLoadingUserInfo} = useGetUserInfo();

    const isWorkspacesEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const userOwnerInfo = useMemo(() => {
        const isLoading = isLoadingWorkspaceOwner || isLoadingUserInfo;
        if (isWorkspacesEnabled) {
            return {
                isAnyOwner: (ownerOfAWorkspace ?? false) || (userAuthInfo?.isAdmin ?? false),
                isOwner: (ownerOfSelectedWorkspace ?? false) || (userAuthInfo?.isAdmin ?? false),
                isLoading: isLoading,
            };
        } else {
            return {
                isAnyOwner: userAuthInfo?.isAdmin ?? false,
                isOwner: userAuthInfo?.isAdmin ?? false,
                isLoading: isLoadingUserInfo,
            };
        }
    }, [
        isLoadingWorkspaceOwner,
        isLoadingUserInfo,
        ownerOfSelectedWorkspace,
        ownerOfAWorkspace,
        userAuthInfo,
        isWorkspacesEnabled,
    ]);

    return {
        ...userOwnerInfo,
    };
}
