import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    container: {
        width: '100vw',
        height: '100vh',
    },
    agentNode: {
        padding: '15px',
        border: '1px solid #ddd',
        borderRadius: '10px',
        background: '#f9f9f9',
        color: '#333',
        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        textAlign: 'center',
        width: '300px',
        cursor: 'pointer',
    },
    agentNodeError: {
        padding: '15px',
        border: '1px solid #ddd',
        borderRadius: '10px',
        background: tokens.colorPaletteRedBackground2,
        boxShadow: '0 2px 5px rgba(0,0,0,0.15)',
        fontFamily: 'Arial, sans-serif',
        fontSize: '14px',
        textAlign: 'center',
        width: '300px',
        cursor: 'pointer',
    },
    agentNodeText: {
        color: 'black',
        marginBottom: '5px',
    },
    agentEdge: {
        stroke: '#fff',
        strokeWidth: '4px',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        color: 'black',
        padding: '20px',
        borderRadius: '10px',
        maxWidth: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
    },
    modalItem: {
        marginBottom: '10px',
    },
    card: {
        margin: 'auto',
        width: '1000px',
        maxWidth: '100%',
        marginBottom: '20px',
        border: '1px solid #ccc', // Add a border to the card
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Add a subtle shadow for better visibility
        borderRadius: '8px', // Optional: Add rounded corners
    },
});
