import {PreviewState} from '../api.types';

export type SkillDescriptor = {
    name: string;
    format?: string;
    displayName: string;
    promptAlignedName: string;
    altName?: string;
    contentTemplate: string;
    description: string;
    hidden: boolean;
    interfaces?: string[];
    skillsetDisplayName: string | null;
    skillsetName: string | null;
    inputs: SkillInputDescriptor[];
    outputs: SkillOutputDescriptor[];
};

export type SkillVariableDescriptor = {
    name: string;
    description?: string;
    type?: SkillTypeDescriptor;
};

export type SkillTypeDescriptor = {
    name: string;
};

export type SkillInputDescriptor = SkillVariableDescriptor & {
    required: boolean;
    defaultValue: string;
    placeholderValue: string;
};

export type SkillOutputDescriptor = SkillVariableDescriptor & {};

export enum ComplianceBoundary {
    None = 'None',
    Azure = 'Azure',
    O365 = 'O365',
}
export type SkillsetDescriptor = {
    name: string;
    description: string | null;
    descriptionDisplay: string | null;
    displayName: string | null;
    category: SkillsetCategory;
    catalogScope: SkillsetCatalogScope;
    icon: string | null;
    enabled: boolean;
    canToggle?: boolean;
    hidden?: boolean;
    prerequisites?: string[] | null;
    settings?: SkillsetSetting[];
    featureFlags?: string[] | null;
    supportedAuthTypes?: SkillsetAuthType[] | null;
    userVisibilityFeatureFlags?: string[] | null;
    userVisibility?: SkillVisibility;
    compliance?: ComplianceBoundary;
    previewState?: PreviewState;
};

export type Skillset = {
    descriptor: SkillsetDescriptor;
    skillGroups: SkillGroup[];
    skillsetIdentifier?: string | null;
};

export type SkillGroup = {
    format: SkillFormat;
    skills: SkillDescriptor[];
    settings: {[key: string]: string};
};

export enum SkillFormat {
    GPT = 'GPT',
    DOTNET = 'DOTNET',
    KQL = 'KQL',
    ChatGptPlugin = 'ChatGptPlugin',
    Python = 'Python',
    LogicApp = 'LogicApp',
}

export enum SkillsetCategory {
    Microsoft = 'Microsoft',
    MicrosoftConnectors = 'MicrosoftConnectors',
    Other = 'Other',
    Web = 'Web',
    Testing = 'Testing',
    Plugin = 'Plugin',
}

export enum SkillsetCatalogScope {
    None = 'None',
    User = 'User',
    Tenant = 'Tenant',
    Global = 'Global',
    // multi-workspaces
    Workspace = 'Workspace',
    UserWorkspace = 'UserWorkspace',
}

export enum SkillsetFormat {
    SkillsetYaml = 'SkillsetYaml',
    SkillsetJson = 'SkillsetJson',
    PluginManifestJson = 'PluginManifestJson',
}

export enum SkillsetContentType {
    Json = 'application/json',
    Yaml = 'application/yaml',
}

export type SkillsetConfigInput = {[key: string]: string};

export type SkillsetRequest = {
    skillsetName: string;
    scope?: SettingsScope | null;
};

export type SkillsetConfiguration = {
    skillsetName: string;
    settings: SkillsetConfigInput;
    authType: SkillsetAuthType;
    authConfigurations: Record<SkillsetAuthType, SkillsetAuthConfiguration>;
    scopeIdentifier?: string | null;
};

export type UpdateSkillsetRequest = {
    name: string;
    body?: string | null;
    scope: string;
    format: string;
    contentType?: string | null;
    contentUrl?: string | null;
};

export type CreateSkillsetRequest = {
    body?: string | null;
    scope: string;
    format: string;
    contentType?: string | null;
    contentUrl?: string | null;
};

export type UpdateSkillsetConfigsRequest = {
    configuration: SkillsetConfiguration;
    scope: SettingsScope;
};

export type SkillsetAuthConfiguration = {
    authType?: SkillsetAuthType | null;
    settings?: SkillsetConfigInput | null;
    hiddenSettings?: SkillsetConfigInput | null;
};

export type SkillsetSetting = {
    name: string;
    label?: string | null;
    description?: string | null;
    hintText?: string | null;
    settingType: SkillsetSettingType;
    required?: boolean | null;
    defaultValue?: string | null;
    value?: string | null;
};

export type SkillsetAuthDescriptor = {
    authType: SkillsetAuthType;
    settings: SkillsetSetting[];
};

export enum SkillsetAuthType {
    None = 'None',
    Basic = 'Basic',
    APIKey = 'APIKey',
    OAuthAuthorizationCodeFlow = 'OAuthAuthorizationCodeFlow',
    OAuthClientCredentialsFlow = 'OAuthClientCredentialsFlow',
    AAD = 'AAD',
    ServiceHttp = 'ServiceHttp',
    AADDelegated = 'AADDelegated',
    OAuthPasswordGrantFlow = 'OAuthPasswordGrantFlow',
}

export type OAuthAuthorizationCodeRequest = {
    skillsetName?: string | null;
    authorizationCode?: string | null;
    state?: string | null;
};

export enum SettingsScope {
    None = 'None',
    User = 'User',
    Tenant = 'Tenant',
    //Global = 'Global',
    // multi-workspaces
    Workspace = 'Workspace',
    UserWorkspace = 'UserWorkspace',
}

export enum SkillVisibility {
    Always,
    Never,
    IfFeatureFlagsEnabled,
}

export type GetSkillsRequest = {
    skillset: string;
};

export type GetSkillsetRequest = {
    skillset: string;
};

export enum SkillsetSettingType {
    String = 'String',
    SecretString = 'SecretString',
}
export type SkillsetRequirementsCheckResult = {
    skillsetName: string;
    errors: SkillsetError[];
};

export type SkillsetError = {
    name: string;
    errorCode: SkillsetErrorCode;
};

export enum SkillsetErrorCode {
    MissingDependency = 'MissingDependency',
    MissingRequiredSetting = 'MissingRequiredSetting',
    MissingAuthRequirement = 'MissingAuthRequirement',
}

export type GetAllTopSkillsRequest = {
    skillsets?: string[] | null;
};

export enum SkillsetFilterTypes {
    All = 'All',
    On = 'Turned on',
    Off = 'Turned off',
    NotSetUp = 'Not set up',
}

export type AcceptedFileType = '.json' | '.yaml' | '.yml';

export enum SkillsetUploadType {
    Copilot = 'copilot',
    OpenAI = 'openAI',
}
