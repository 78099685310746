import * as React from 'react';
import {SkillsetDescriptor, SkillsetFilterTypes} from '@/api/skills';
import {useTenantScopePluginUpload, useUserScopePluginUpload} from '@/api/tenant';
import {SkillRowData} from '../SkillRow';
import {MIN_COLLAPSED_SKILLSETS} from './SkillsetsSection.constants';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

const getSwitchId: (itemName: string, index: number) => string = (itemName, index) => {
    return `${itemName}-switch${index}`;
};

/**
 * Custom hook that provides functions for generating skill row data and filtering out skill rows.
 * @param selectedSkillsets - The selected skillsets.
 * @param configurableSkillsets - The configurable skillsets.
 * @param configuredSkillsets - The configured skillsets.
 * @returns An object containing the `getSkillRowData` and `filteredOutSkillRow` functions.
 */
export const useGetSkillRowData = (
    selectedSkillsets: Set<string>,
    configurableSkillsets: Set<string> | undefined,
    configuredSkillsets: Set<string>,
): {
    getSkillRowData: (
        skillset: SkillsetDescriptor,
        index: number,
        categorySkillsetCount: number,
        visibleSkillsetCount: number,
    ) => SkillRowData;
    filteredOutSkillRow: (
        skillRowData: SkillRowData,
        displayedSkillsetFilter: SkillsetFilterTypes,
    ) => boolean;
} => {
    const {isTenantScopeUploadAllowed} = useTenantScopePluginUpload();
    const {isUserScopeUploadAllowed} = useUserScopePluginUpload();
    const isAdvancedPluginFilteringEnabled = useFeatureFlag(
        MedeinaFeatures.EnableAdvancedPluginFiltering,
    );

    /**
     * Determines whether a skill row should be filtered out (hidden) based on the displayed skillset filter.
     * @param skillRowData - The skill row data.
     * @param displayedSkillsetFilter - The displayed skillset filter.
     * @returns `true` if the skill row should be filtered out, `false` otherwise.
     */
    const filteredOutSkillRow = (
        skillRowData: SkillRowData,
        displayedSkillsetFilter: SkillsetFilterTypes,
    ) => {
        const setUp = !skillRowData.canConfigure || skillRowData.configured;

        var hideEnabledSkillRow = null;
        // If advanced filtering is enabled, then exclude "Not set up" plugins from being displayed under the "Off" filter
        if (isAdvancedPluginFilteringEnabled) {
            hideEnabledSkillRow =
                (skillRowData.selected || !setUp) &&
                displayedSkillsetFilter === SkillsetFilterTypes.Off;
        } else {
            hideEnabledSkillRow =
                skillRowData.selected && displayedSkillsetFilter === SkillsetFilterTypes.Off;
        }
        const hideDisabledSkillRow =
            !skillRowData.selected && displayedSkillsetFilter === SkillsetFilterTypes.On;
        const hideNotSetUpSkillRow =
            setUp && displayedSkillsetFilter === SkillsetFilterTypes.NotSetUp;

        return (
            hideEnabledSkillRow ||
            hideDisabledSkillRow ||
            (!!isAdvancedPluginFilteringEnabled && hideNotSetUpSkillRow)
        );
    };

    /**
     * Generates the data for populating `SkillRow` components.
     * @param skillset - The skillset descriptor.
     * @param index - The index of the skillset.
     * @param categorySkillsetCount - The total number of skillsets in the category.
     * @param visibleSkillsetCount - The number of visible skillsets.
     * @returns The skill row data.
     */
    const getSkillRowData = (
        skillset: SkillsetDescriptor,
        index: number,
        categorySkillsetCount: number,
        visibleSkillsetCount: number,
    ): SkillRowData => {
        const name = skillset.name;
        const selected = selectedSkillsets instanceof Set && selectedSkillsets?.has(name);

        const configurable = configurableSkillsets?.has(name) ?? false;
        // User can configure if 1) User management is allowed and 2) for tenant scope skillset, Tenant management is allowed
        const canUserConfigure = isUserScopeUploadAllowed() && isTenantScopeUploadAllowed(skillset);
        const canConfigure = configurable && canUserConfigure;
        const configured = configuredSkillsets.has(name);

        const isLastRow =
            categorySkillsetCount <= MIN_COLLAPSED_SKILLSETS && index === visibleSkillsetCount - 1;
        const componentId = getSwitchId(name, index);

        return {
            name,
            skillset,
            selected,
            canConfigure,
            configured,
            isLastRow,
            componentId,
        };
    };

    return {getSkillRowData, filteredOutSkillRow};
};
