import {useEffect, useMemo, useState} from 'react';
import {useWorkspacesContext} from '../WorkspacesProvider/WorkspacesContext';
import useClasses from './ManageWorkspaces.styles';
import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridCellFocusMode,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    TableColumnDefinition,
    TableColumnId,
    TableRowId,
    createTableColumn,
    TableColumnSizingOptions,
    mergeClasses,
    Dialog,
    Spinner,
    useId,
    Toast,
    ToastTitle,
    Toaster,
    useToastController,
} from '@fluentui/react-components';
import {useGetWorkspaces, Workspace} from '@/api/workspaces';
import {DataGridSortIcon, AddIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {useGetUserInfo} from '@/api/app';
import {useLocation} from 'react-router-dom';

import {CapacityWarningMessageBar} from './CapacityWarningMessageBar';
import {WorkspaceWarningMessageBar} from './WorkspaceWarningMessageBar';
import WorkspaceForm from '@/components/sections/workspaces/form/WorkspaceForm';
import {WorkspaceColumn} from './TableColumns/WorkspaceColumn';
import {CapacityColumn} from './TableColumns/CapacityColumn';
import {LastUpdatedColumn} from './TableColumns/LastUpdatedColumn';
import {CreatedColumn} from './TableColumns/CreatedColumn';
import MembersColumn from './TableColumns/MembersColumn';
import {Capacity, useGetCapacities} from '@/api/capacities';
import {useQueryClient} from '@tanstack/react-query';
import useIsOwner from '@/api/app/useGetIsOwner';

const getCellFocusMode = (columnId: TableColumnId): DataGridCellFocusMode => {
    switch (columnId) {
        case 'name':
        case 'capacity':
        case 'lastupdated':
        case 'created':
        case 'owners':
        case 'contributors':
        default:
            return 'cell';
    }
};

export default function ManageWorkspaces() {
    const classes = useClasses();
    const {data: user} = useGetUserInfo();
    const [workspaceDialogOpen, setWorkspaceDialogOpen] = useState(false);
    const {t} = useTranslation('admin');
    const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>(undefined));
    const onSelectionChange: DataGridProps['onSelectionChange'] = (e: any, data: any) => {
        setSelectedRows(data.selectedItems);
    };
    const {
        data: workspacesData,
        isLoading: isWorkspacesLoading,
        isFetched: isWorkspacesDataFetched,
        refetch: refetchWorkspaces,
    } = useGetWorkspaces();
    const {data} = useGetCapacities();
    const capacityItems = data?.value || [];
    let availableCapacities: Capacity[] = [];
    let availableWorkspaces: Workspace[] = [];
    const [disconnectedWorkspaces, setDisconnectedWorkspaces] = useState<Workspace[]>([]);
    const [disconnectedCapacities, setDisconnectedCapacities] = useState<Capacity[]>([]);
    const queryClient = useQueryClient();
    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);
    const notifyDelete = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{t('Workspaces.Form.WorkspaceDeleted')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'success'},
        );
    };
    const notifyCreate = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{t('Workspaces.Form.WorkspaceCreated')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'success'},
        );
    };
    const handleDeleteSuccess = () => {
        notifyDelete();
        // Invalidate the workspaces query to refetch the workspaces
        queryClient.invalidateQueries(['workspaces']);
        // refetch workspaces
        refetchWorkspaces();
    };

    const handleCreateSuccess = () => {
        notifyCreate();
        queryClient.invalidateQueries(['workspaces']);
        // refetch workspaces
        refetchWorkspaces();
    };

    const {
        workspaces,
        ownedWorkspaces: ownedWorkspacesfromContext,
        ownerOfAWorkspace,
        isFetched: isFetchedWorkspacesContext,
    } = useWorkspacesContext();

    const {isOwner, isAnyOwner} = useIsOwner();

    const [ownedWorkspaces, setOwnedWorkspaces] = useState<Workspace[]>([]);

    useEffect(() => {
        if (isFetchedWorkspacesContext) {
            availableWorkspaces = (ownedWorkspaces || []).filter(
                (workspace) => !workspace.capacity,
            );
            setDisconnectedWorkspaces(availableWorkspaces);
        }
    }, [ownedWorkspaces]);

    useEffect(() => {
        if (isFetchedWorkspacesContext) {
            availableCapacities = capacityItems.filter(
                (capacity) =>
                    !(workspaces || []).find(
                        (workspace) => workspace.capacity?.referenceName === capacity.name,
                    ),
            );
            setDisconnectedCapacities(availableCapacities);
        }
    }, [workspaces, ownedWorkspaces, capacityItems]);

    const notOwnedWorkspaces: Workspace[] = useMemo(
        () =>
            workspaces?.filter(
                (workspace) =>
                    !ownedWorkspaces
                        .map((ownedWorkspace) => ownedWorkspace.name)
                        .includes(workspace.name),
            ) || [],
        [workspaces, ownedWorkspaces],
    );

    const handleOnOwnerDataProcessed = (isUserOwner: boolean, workspace: Workspace) => {
        if (isUserOwner) {
            setOwnedWorkspaces((prev) => [...prev, workspace]);
        }
    };

    const columnSizingOptions: TableColumnSizingOptions = {
        name: {idealWidth: 287},
        capacity: {idealWidth: 208, minWidth: 150},
        lastupdated: {idealWidth: 200, minWidth: 150},
        created: {idealWidth: 200, minWidth: 150},
        owners: {idealWidth: 200},
        contributors: {idealWidth: 300},
    };

    const columns: TableColumnDefinition<Workspace>[] = [
        createTableColumn<Workspace>({
            columnId: 'name',
            renderHeaderCell: () => {
                return (
                    <DataGridHeaderCell sortIcon={<DataGridSortIcon />}>
                        {t('ManageWorkspaces.TableHeaders.Name')}
                    </DataGridHeaderCell>
                );
            },
            renderCell: (workspace) => {
                return <WorkspaceColumn workspace={workspace} onDelete={handleDeleteSuccess} />;
            },
        }),
        createTableColumn<Workspace>({
            columnId: 'capacity',
            renderHeaderCell: () => {
                return (
                    <DataGridHeaderCell>
                        {t('ManageWorkspaces.TableHeaders.Capacity')}
                    </DataGridHeaderCell>
                );
            },
            renderCell: (workspace) => {
                return (
                    <CapacityColumn
                        workspace={workspace}
                        isWorkspacesDataFetched={isFetchedWorkspacesContext ?? false}
                        workspaces={notOwnedWorkspaces ?? []}
                        disconnectedWorkspaces={disconnectedWorkspaces}
                        setDisconnectedWorkspaces={setDisconnectedWorkspaces}
                        disconnectedCapacities={disconnectedCapacities}
                        setDisconnectedCapacities={setDisconnectedCapacities}
                    />
                );
            },
        }),
        createTableColumn<Workspace>({
            columnId: 'lastupdated',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.LastUpdated'),
            renderCell: (workspace) => <LastUpdatedColumn workspace={workspace} />,
        }),
        createTableColumn<Workspace>({
            columnId: 'created',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Created'),
            renderCell: (workspace) => <CreatedColumn workspace={workspace} />,
        }),
        createTableColumn<Workspace>({
            columnId: 'owners',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Owners'),
            renderCell: (workspace) => (
                <MembersColumn
                    workspace={workspace}
                    getOwnerMembers={true}
                    onOwnerDataProcessed={handleOnOwnerDataProcessed}
                />
            ),
        }),
        createTableColumn<Workspace>({
            columnId: 'contributors',
            renderHeaderCell: () => t('ManageWorkspaces.TableHeaders.Contributors'),
            renderCell: (workspace) => (
                <MembersColumn
                    workspace={workspace}
                    getOwnerMembers={false}
                    onOwnerDataProcessed={handleOnOwnerDataProcessed}
                />
            ),
        }),
    ];

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('openCreateWorkspace') === 'true') {
            setWorkspaceDialogOpen(true);
        }
    }, [location.search]);

    useEffect(() => {
        setOwnedWorkspaces(
            workspaces?.filter((workspace) =>
                (ownedWorkspacesfromContext || []).includes(workspace.name),
            ) || [],
        );
    }, [workspaces]);

    return (
        <>
            {isAnyOwner && (
                <div className={classes.root} data-testid="workspace-list-page">
                    <h1 className={classes.title}>{t('ManageWorkspaces.PageHeading')}</h1>
                    {user?.isAdmin && (
                        <div className={classes.newWorkspaceButton}>
                            <Button
                                icon={<AddIcon />}
                                appearance="secondary"
                                data-testid="new-workspace-button"
                                className={classes.newWorkspaceButton}
                                onClick={() => setWorkspaceDialogOpen(true)}
                            >
                                {t('ManageWorkspaces.NewWorkspaceButton')}
                            </Button>
                        </div>
                    )}
                    <div className={classes.infoContainer}>{t('ManageWorkspaces.InfoContent')}</div>

                    {disconnectedWorkspaces.map((workspace) => (
                        <WorkspaceWarningMessageBar
                            key={workspace.name}
                            workspaceName={workspace.name}
                        />
                    ))}
                    {disconnectedCapacities.map((capacity) => (
                        <CapacityWarningMessageBar
                            key={capacity.name}
                            capacityName={capacity.name}
                        />
                    ))}
                    {!isFetchedWorkspacesContext ? (
                        <div className={classes.filteringInvestigations}>
                            <Spinner
                                labelPosition="below"
                                label={t('ManageWorkspaces.LoadingWorkspaces')}
                            />
                        </div>
                    ) : (
                        <DataGrid
                            items={ownedWorkspaces ?? []}
                            columns={columns}
                            columnSizingOptions={columnSizingOptions}
                            resizableColumns
                            selectionAppearance="neutral"
                            onSelectionChange={onSelectionChange}
                            selectedItems={selectedRows}
                            getRowId={(workspace: {workspaceId: any}) => workspace.workspaceId}
                            className={classes.gridCell}
                            data-testid="workspace-library-grid"
                        >
                            <DataGridHeader>
                                <DataGridRow>
                                    {({renderHeaderCell}: any) => (
                                        <DataGridHeaderCell
                                            className={mergeClasses(
                                                classes.headerCell,
                                                classes.row,
                                            )}
                                        >
                                            {renderHeaderCell()}
                                        </DataGridHeaderCell>
                                    )}
                                </DataGridRow>
                            </DataGridHeader>
                            <DataGridBody<Workspace>>
                                {({item, rowId}: any) => (
                                    <DataGridRow<Workspace> key={rowId}>
                                        {({renderCell, columnId}: any) => (
                                            <DataGridCell
                                                className={classes.row}
                                                focusMode={getCellFocusMode(columnId)}
                                            >
                                                {renderCell(item)}
                                            </DataGridCell>
                                        )}
                                    </DataGridRow>
                                )}
                            </DataGridBody>
                        </DataGrid>
                    )}
                    <Dialog
                        open={workspaceDialogOpen}
                        onOpenChange={(event, data) => setWorkspaceDialogOpen(data.open)}
                    >
                        <WorkspaceForm
                            mode="create"
                            onClose={() => setWorkspaceDialogOpen(false)}
                            onCreate={handleCreateSuccess}
                        />
                    </Dialog>
                    <Toaster toasterId={toasterId} />
                </div>
            )}
        </>
    );
}
