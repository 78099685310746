import {useState, useEffect, useCallback} from 'react';
import i18next from 'i18next';
import {enUS} from 'date-fns/locale';
import {setDefaultOptions, Locale} from 'date-fns';
import {useUserState} from '@/api/user';
import {useFeatureFlags} from '@/api/user';
import {getLanguage, RTL_LANGUAGES, MedeinaLanguageCode} from '@/util/locale';
import {language_fallbacks} from './dateFns_languageFallbacks';
import MedeinaFeatures from '../features';

function useLocale() {
    const [
        _,
        isLanguageEnabled,
        isTimezoneEnabled,
        isLocalizationDisabled,
        isTimezoneDisabled,
        isDebugEnabled,
    ] = useFeatureFlags([
        MedeinaFeatures.EnableLocalization,
        MedeinaFeatures.EnableTimezone,
        MedeinaFeatures.DisableLocalization,
        MedeinaFeatures.DisableTimezone,
        MedeinaFeatures.Debug,
    ]);
    const {preferences, useBrowserLanguage, setUseBrowserLanguage, setUseBrowserTime} =
        useUserState();
    const {locale} = preferences;
    const {languageCode: userSavedLanguageCode} = locale;
    const [localeObject, setLocaleObject] = useState<Locale | null>(null);
    const [loadedLocales, setLoadedLocales] = useState<Record<string, Locale>>({});
    const resolvedLanguage = i18next.resolvedLanguage;

    // This function is used to import the date-fns locale and set it in the state
    // we also cache the loaded locales so we don't have to load them again and prevent an error
    const importAndSetLocale = useCallback(
        (languageCode: string) => {
            const hasBeenLoaded = loadedLocales[languageCode];
            if (hasBeenLoaded) {
                setLocaleObject(loadedLocales[languageCode]);
                return;
            }
            try {
                import(`date-fns/locale/${languageCode}/index.js`).then((localeModule) => {
                    setLocaleObject(localeModule.default);
                    setLoadedLocales({...loadedLocales, [languageCode]: localeModule.default});
                });
            } catch (e) {
                // console.error('Error loading locale', e);
                // if we can't load the locale, we will default to en-US
                setLocaleObject(enUS);
            }
        },
        [loadedLocales],
    );

    const getSupportedLanguage = useCallback((languageCode: string) => {
        return language_fallbacks[languageCode] || 'en-US';
    }, []);

    // When we are not using browser language, we need to update the locale when the language changes
    useEffect(() => {
        // Feature flag is off, we don't want to do anything
        if (!isLanguageEnabled || isLocalizationDisabled) return;
        const languageCode = getLanguage();
        // we use our fallback map to get the language code for date fns which can't handle all the languages
        const supportedLanguage = getSupportedLanguage(languageCode);

        importAndSetLocale(supportedLanguage);
    }, [
        locale,
        isLanguageEnabled,
        isLocalizationDisabled,
        loadedLocales,
        resolvedLanguage,
        importAndSetLocale,
        getSupportedLanguage,
    ]);

    useEffect(() => {
        if (localeObject) {
            setDefaultOptions({locale: localeObject});
        }
    }, [localeObject]);

    // If we change from not using browser language to using browser language, we need to update the locale
    useEffect(() => {
        if (
            isLanguageEnabled &&
            !isLocalizationDisabled &&
            useBrowserLanguage &&
            i18next.resolvedLanguage
        ) {
            // we are pulling from the browser's language using i18next
            const resolvedBrowserLanguage = i18next.resolvedLanguage;
            const supportedLanguage = getSupportedLanguage(resolvedBrowserLanguage);
            importAndSetLocale(supportedLanguage);
        }
        // don't want to run this effect when the loadedLocales changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLanguageEnabled, useBrowserLanguage, isLocalizationDisabled]);

    useEffect(() => {
        // when we change language or change to/from browser language we need to update the page language
        if (isLanguageEnabled && !isLocalizationDisabled) {
            // update page attributes
            const language = getLanguage();
            document.documentElement.lang = language;
            document.documentElement.dir = RTL_LANGUAGES.includes(language as MedeinaLanguageCode)
                ? 'rtl'
                : 'ltr';
        }
    }, [useBrowserLanguage, userSavedLanguageCode, isLanguageEnabled, isLocalizationDisabled]);

    // we need a specific useEffect to handle the use case where the ECS feature flag is
    // turned off and we need to reset the locale in local storage to turn off localization fully
    useEffect(() => {
        if (isLanguageEnabled && isLocalizationDisabled) {
            setUseBrowserLanguage(true);
        }
        // we don't want to rerun this effect when setUseBrowserLanguage changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLanguageEnabled, isLocalizationDisabled]);

    // this controls the time zone field showing up in the settings page
    // we turn this off if the ECS feature flag is off and we override if the environment's feature flag is on
    useEffect(() => {
        if (isTimezoneEnabled && isTimezoneDisabled) {
            setUseBrowserTime(true);
        }
        // we don't want to rerun this effect when setUseBrowserTime changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTimezoneEnabled, isTimezoneDisabled]);

    return localeObject || enUS;
}

export default useLocale;
