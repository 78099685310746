import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import {DATETIME_FORMAT, MILLI_SECONDS_IN_HOUR} from './UsageDashboard.constants';
import {HourlyCapacityUsage, SelectedSearchFacet} from '@/api/capacities/capacities.types';
import {formatLocaleDate as format} from '@/util/locale';
import {addHours} from 'date-fns';
import {TFunction} from 'i18next';
import v2ChartConfigurator from './BarChart/ChartConfigurators/v2ChartConfigurator';
import overageChartConfigurator from './BarChart/ChartConfigurators/overageChartConfigurator';
import {IUsageChartConfigurator} from './BarChart/VStackedBarChart.types';
import defaultUsageChartConfigurator from './BarChart/ChartConfigurators/defaultUsageChartConfigurator';

export function transformDataForGraph(
    data: HourlyCapacityUsage[],
    translate: TFunction,
    version?: 'v2' | 'overage',
    isFilteredSearchState?: boolean,
): IVerticalStackedChartProps[] {
    let configurator: IUsageChartConfigurator | null = null;

    switch (version) {
        case 'v2':
            configurator = new v2ChartConfigurator(translate);
            break;
        case 'overage':
            configurator = isFilteredSearchState
                ? new defaultUsageChartConfigurator(translate)
                : new overageChartConfigurator(translate);
            break;
    }

    return configurator ? data.map(configurator.getChartConfiguration) : [];
}

export const getZonedDate = (date: string): string => {
    const zonedDate = format(date, DATETIME_FORMAT.ISO_STRING);

    return zonedDate;
};

export const getDayName = (date: Date): string => {
    return format(date, DATETIME_FORMAT.BARCHART_HOVERCARD_DAYNAME);
};

export const getHourlyTimeframe = (date: Date): string => {
    const formattedHour = format(date, DATETIME_FORMAT.BARCHART_HOVERCARD_TIMEFRAME);
    const nextHour = format(addHours(date, 1), DATETIME_FORMAT.BARCHART_HOVERCARD_TIMEFRAME);

    return `${formattedHour} - ${nextHour}`;
};

// Number.Epsilon adds a small value to the number to avoid floating point arithmetic errors
export const roundToOneDecimal = (value: number): number =>
    Math.round((value + Number.EPSILON) * 10) / 10;

export const roundToDecimalPlaces = (value: number, decimalPlaces: number): number => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round((value + Number.EPSILON) * factor) / factor;
};

const convertDateToResponseFormat = (utcDateString: string): string => {
    const date = new Date(utcDateString);
    const utcString = date.toISOString().split('.')[0];
    return utcString;
};

export const fillBarGraphDataGaps = (
    data: HourlyCapacityUsage[],
    startTime: Date,
    endTime: Date,
): HourlyCapacityUsage[] => {
    const filledData: HourlyCapacityUsage[] = [];

    if (data.length > 0) {
        const dataStartDate = new Date(getZonedDate(data[0].aggregateStartTime));
        const dataEndDate = new Date(getZonedDate(data[data.length - 1].aggregateStartTime));
        const startDate = startTime;
        const endDate = endTime;
        const dataLength = data.length;

        // Calculate the difference in hours
        const diffInPreHours =
            (dataStartDate.getTime() - startDate.getTime()) / MILLI_SECONDS_IN_HOUR;

        // Fill the gap before the first data point
        for (let j = 1; j <= diffInPreHours; j++) {
            const gapTime = new Date(dataStartDate);
            gapTime.setHours(dataStartDate.getHours() - j);
            let {
                assignedCapacity,
                maxAllowedCapacity,
                overageAvailable,
                overageUsed,
                overageExceeded,
            } = data[0];

            filledData.push({
                assignedCapacity,
                usedCapacity: 0,
                maxAllowedCapacity,
                aggregateStartTime: convertDateToResponseFormat(gapTime.toISOString()),
                overageAvailable: overageAvailable ?? 0,
                overageUsed: overageUsed ?? 0,
                overageExceeded: overageExceeded ?? 0,
            });
        }
        filledData.sort(
            (a, b) =>
                new Date(a.aggregateStartTime).getTime() - new Date(b.aggregateStartTime).getTime(),
        );

        for (let i = 0; i < dataLength - 1; i++) {
            let {
                assignedCapacity,
                maxAllowedCapacity,
                overageAvailable,
                overageUsed,
                overageExceeded,
            } = data[i];

            filledData.push({
                ...data[i],
                overageAvailable: overageAvailable ?? 0,
                overageUsed: overageUsed ?? 0,
                overageExceeded: overageExceeded ?? 0,
            });

            const currentTime = new Date(getZonedDate(data[i].aggregateStartTime));
            const nextTime = new Date(getZonedDate(data[i + 1].aggregateStartTime));

            // Calculate the difference in hours
            const diffInHours =
                (nextTime.getTime() - currentTime.getTime()) / MILLI_SECONDS_IN_HOUR;

            // If there's a gap of more than 1 hour, fill it
            if (diffInHours > 1) {
                for (let j = 1; j < diffInHours; j++) {
                    const gapTime = new Date(currentTime);
                    gapTime.setUTCHours(currentTime.getUTCHours() + j);

                    filledData.push({
                        assignedCapacity,
                        usedCapacity: 0,
                        maxAllowedCapacity,
                        aggregateStartTime: convertDateToResponseFormat(gapTime.toISOString()),
                        overageAvailable: overageAvailable ?? 0,
                        overageUsed: overageUsed ?? 0,
                        overageExceeded: overageExceeded ?? 0,
                    });
                }
            }
        }

        let lastButOneItem = data[dataLength - 2];

        filledData.push({
            ...lastButOneItem,
            overageAvailable: lastButOneItem.overageAvailable ?? 0,
            overageUsed: lastButOneItem.overageUsed ?? 0,
            overageExceeded: lastButOneItem.overageExceeded ?? 0,
        });

        // Calculate the difference in hours
        const diffInPostHours = (endDate.getTime() - dataEndDate.getTime()) / MILLI_SECONDS_IN_HOUR;

        // If the gap from the last data point to the end time
        for (let j = 1; j <= diffInPostHours; j++) {
            let {
                assignedCapacity,
                maxAllowedCapacity,
                overageAvailable,
                overageUsed,
                overageExceeded,
            } = data[dataLength - 1];

            const gapTime = new Date(dataEndDate);
            gapTime.setHours(dataEndDate.getHours() + j);

            filledData.push({
                assignedCapacity,
                usedCapacity: 0,
                maxAllowedCapacity,
                aggregateStartTime: convertDateToResponseFormat(gapTime.toISOString()),
                overageAvailable: overageAvailable ?? 0,
                overageUsed: overageUsed ?? 0,
                overageExceeded: overageExceeded ?? 0,
            });
        }
    }

    return filledData;
};

export function convertFacetsToSelectedFilters(facets: SelectedSearchFacet[]): {
    [key: string]: string[];
} {
    const filters: {[key: string]: string[]} = {};
    facets.forEach((facet) => {
        filters[facet.category] = facet.values as string[];
    });
    return filters;
}
