import {PromptbookPrompts, PromptInputs} from '@/api/promptbooks';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useClasses from './PromptbookPrompt.styles';
import {
    Button,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Switch,
    ToolbarButton,
    Tooltip,
    mergeClasses,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {
    AcceptIcon,
    ArrowDownIcon,
    ArrowUpIcon,
    DeleteIcon,
    DismissIcon,
    EditIcon,
    SkillIcon,
} from '@/components/ui/icons';
import {EMPTY_PROMPTBOOK_PROMPT_CONTENT} from './Promptbook.types';
import {MoreHorizontalIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import PromptbookPromptForm from './PromptbookPromptForm';
import {SkillDescriptor, SkillInputDescriptor} from '@/api/skills';
import DirectSkillsMenu from '../../ui/PromptBar/PromptBarMenu';
import {PromptType} from '@/api/prompts';
export interface PromptbookPromptProps {
    promptDefn: PromptbookPrompts;
    totalItems: number;
    // Optional index for use in lists
    index?: number;
    isModal?: boolean;
    onPromptDeleted?: (index: number) => void;
    onPromptMovedDown?: (index: number) => void;
    onPromptMovedUp?: (index: number) => void;
    onPromptEdited?: (index: number, promptDefn: PromptbookPrompts) => void;
    onPromptDataLoaded?: (index: number, promptDefn: PromptbookPrompts) => void;
}

export default function PromptbookPrompt(props: PromptbookPromptProps) {
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const isPromptbookContinueOnFailureEnabled = useFeatureFlag(
        MedeinaFeatures.EnablePromptbookContinueOnFailure,
    );
    const classes = useClasses();
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const editIconRef = useRef<HTMLButtonElement>(null);
    const [editMode, setEditMode] = useState(props.promptDefn.editMode ?? false);
    const [tempPromptDefn, setTempPromptDefn] = useState(props.promptDefn);
    const [promptDefn, setPromptDefn] = useState<PromptbookPrompts>(props.promptDefn);
    const [skillListOpen, setSkillListOpen] = useState<boolean>(false);
    const inputWrapperRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const {t} = useTranslation('promptbooks');
    // handlers for restoring focus when switching from edit mode back to normal mode
    const restoreFocusSource = useRestoreFocusSource();
    const restoreFocusTarget = useRestoreFocusTarget();
    const promptRef = useRef<HTMLDivElement | null>(null);
    const [tempContinueOnFailure, setTempContinueOnFailure] = useState<boolean | undefined>(
        props.promptDefn.continueOnFailure,
    );

    const emptyPromptDefn: PromptbookPrompts = {
        content: '',
        skillName: undefined,
        skill: undefined,
        skillInputDescriptors: [],
        inputs: {},
        plugins: [],
        promptType: PromptType.Prompt,
        editMode: false,
        checkValidity: false,
    };
    // Used to adjust height of menu based on position in viewport
    const calcMenuHeight = () => {
        if (promptRef.current && !props.isModal) {
            const rect = promptRef.current?.getBoundingClientRect();
            const spaceAbove = rect.top; // Space from the top of the viewport
            const heightRanges = [
                {max: 300, value: '7vh'},
                {max: 400, value: '13vh'},
                {max: 500, value: '20vh'},
                {max: Infinity, value: '30vh'},
            ];
            const selectedHeight = heightRanges.find((range) => spaceAbove < range.max)?.value;
            return selectedHeight || '30vh';
        }
        return '30vh';
    };
    const [menuHeight, setMenuHeight] = useState(calcMenuHeight);

    const userSkillsets = useMemo<string[] | undefined>(() => {
        const params = new URLSearchParams(window.location.search);
        return (
            params
                .get('skillsets')
                ?.split(',')
                .map((item: string) => item.trim()) ?? undefined
        );
    }, [typeof window !== 'undefined' && window.location.search]);

    const handleEditPrompt = (promptDefn: PromptbookPrompts) => {
        setEditMode(true);
        setTempPromptDefn(promptDefn);
        isPromptbookContinueOnFailureEnabled &&
            setTempContinueOnFailure(promptDefn.continueOnFailure ?? false);
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    };

    const handleDeletePrompt = (index: number) => {
        if (index > -1) {
            props.onPromptDeleted?.(index);
        }
    };

    const handlePromptMoveUp = (index: number) => {
        if (index > -1) {
            props.onPromptMovedUp?.(index);
        }
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    };

    const handlePromptMoveDown = (index: number) => {
        if (index > -1) {
            props.onPromptMovedDown?.(index);
        }
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    };

    const handleResetSkill = (index: number) => {
        const newDefn: PromptbookPrompts = {
            ...promptDefn!,
            content: '',
            skillName: undefined,
            skill: undefined,
            skillInputDescriptors: [],
            inputs: {},
            plugins: [],
            checkValidity: false,
            promptType: PromptType.Prompt,
        };
        setTempPromptDefn(newDefn);
    };

    // Handle skill selection.
    const handleSelect = useCallback(
        (skill: SkillDescriptor | undefined) => {
            const skillset = skill?.skillsetName;
            const newDefn: PromptbookPrompts = {
                ...tempPromptDefn!,
                promptType: PromptType.Skill,
                skillName: skill?.name ?? skill?.displayName,
                skill: skill,
                inputs: skill?.inputs.reduce((values, input) => {
                    if (input.required) {
                        values[input.name] = '';
                    }
                    return values;
                }, {} as PromptInputs),
                plugins: skillset ? [skillset] : [],
                skillInputDescriptors: skill?.inputs,
                continueOnFailure: tempContinueOnFailure,
            };
            setTempPromptDefn(newDefn);
            setSkillListOpen(false);
            textareaRef.current?.focus();
        },
        [textareaRef, setSkillListOpen],
    );

    const handleAcceptEditPrompt = (index: number) => {
        setPromptDefn(tempPromptDefn);
        props.onPromptEdited?.(index, tempPromptDefn);
        setTempContinueOnFailure(false);
        setEditMode(false);
    };

    const handleClosePromptEditing = () => {
        setTempPromptDefn(emptyPromptDefn);
        setTempContinueOnFailure(false);
        setEditMode(false);
    };

    useEffect(() => {
        if (editMode && textAreaRef && textAreaRef.current) {
            textAreaRef.current.focus();
        } else if (!editMode && editIconRef && editIconRef.current) {
            editIconRef.current.focus();
        }
    }, [editMode]);

    const handlePromptInputChange = (inputs: PromptInputs) => {
        const newDefn: PromptbookPrompts = {
            ...tempPromptDefn!,
            inputs: inputs,
        };
        setTempPromptDefn(newDefn);
        props.onPromptEdited?.(props.index ?? -1, newDefn);
    };

    // Calculate what the menu height should be on first render
    useEffect(() => {
        isDirectSkillInvocationEnabled && setMenuHeight(calcMenuHeight);
    }, []);

    function renderEditModeContent() {
        return tempPromptDefn.skillName ? (
            <div
                className={mergeClasses(classes.flexContainerRow, classes.promptLabel)}
                role="group"
                tabIndex={0}
                {...restoreFocusTarget}
            >
                <div className={classes.skill}>
                    <Button
                        appearance="outline"
                        shape="rounded"
                        icon={<SkillIcon className={classes.skillIcon} />}
                        onClick={() => handleResetSkill(props.index ?? -1)}
                        data-testid="prompt-remove-skill-button"
                    >
                        {tempPromptDefn.skillName}
                        <DismissIcon className={classes.dismissIcon} />
                    </Button>
                </div>
                {renderToolbarContent()}
            </div>
        ) : (
            <div
                className={mergeClasses(classes.flexContainerRow, classes.promptLabel)}
                data-test-id={'edit-duplicate-prompt'}
                {...restoreFocusSource}
            >
                <textarea
                    className={mergeClasses(classes.flexGrow, classes.promptTextArea)}
                    placeholder={t(EMPTY_PROMPTBOOK_PROMPT_CONTENT)}
                    value={tempPromptDefn.content}
                    data-testid="edit-prompt-textarea"
                    onChange={(e) => {
                        setTempPromptDefn({
                            ...tempPromptDefn!,
                            content: e.target.value,
                        });
                    }}
                    ref={textAreaRef}
                />
                <div className={classes.promptbookInputActionsContainer}>
                    {renderToolbarContent()}
                </div>
            </div>
        );
    }

    function renderNormalModeContent() {
        return tempPromptDefn.skillName ? (
            <div className={mergeClasses(classes.flexGrow, classes.flexContainerRow)}>
                <div className={classes.promptNumber}>{props.index ? props.index! + 1 : 1}</div>
                <div className={classes.promptFormWrapper}>
                    <PromptbookPromptForm
                        skillName={tempPromptDefn.skillName}
                        skillInputDescriptors={tempPromptDefn.skillInputDescriptors ?? []}
                        inputs={tempPromptDefn.inputs ?? undefined}
                        checkValidity={props.promptDefn.checkValidity ?? false}
                        isModal={props.isModal ?? false}
                        onCancel={() => handleSelect(undefined)}
                        onChange={(inputs) => handlePromptInputChange(inputs)}
                    />
                </div>
            </div>
        ) : (
            <div className={mergeClasses(classes.flexGrow, classes.flexContainerRow)}>
                <div className={classes.promptNumber}>{props.index ? props.index! + 1 : 1}</div>
                <div className={classes.promptFormWrapper}>
                    <PromptbookPromptForm
                        promptContent={promptDefn.content}
                        inputs={props.promptDefn.inputs}
                        isModal={props.isModal ?? false}
                        onChange={(inputs) => handlePromptInputChange(inputs)}
                        checkValidity={props.promptDefn.checkValidity ?? false}
                    />
                </div>
            </div>
        );
    }

    function renderPromptOptions() {
        return (
            <div
                className={mergeClasses(
                    classes.flexContainerRow,
                    classes.flexGrow,
                    classes.promptOptionsContainer,
                )}
            >
                <ToolbarButton
                    className={editMode ? classes.toolBarButton : classes.toolBarButtonPrompt}
                    appearance="subtle"
                    data-testid="edit-prompt"
                    icon={<EditIcon />}
                    aria-label={t('EditPrompt')}
                    role="button"
                    ref={editIconRef}
                    onClick={() => handleEditPrompt(props.promptDefn)}
                />
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        <Button
                            icon={<MoreHorizontalIcon />}
                            data-testid="more-options"
                            appearance="subtle"
                            className={
                                editMode ? classes.toolBarButton : classes.toolBarButtonPrompt
                            }
                            aria-label={t('SessionOptionsButton')}
                        />
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuItem
                                icon={<DeleteIcon />}
                                data-testid="delete-prompt"
                                onClick={() => {
                                    handleDeletePrompt(props.index ?? -1);
                                }}
                            >
                                {t('Delete')}
                            </MenuItem>
                            {props.index !== 0 && (
                                <MenuItem
                                    icon={<ArrowUpIcon />}
                                    data-testid="move-up-prompt"
                                    onClick={() => {
                                        handlePromptMoveUp(props.index ?? -1);
                                    }}
                                >
                                    {t('Move Up')}
                                </MenuItem>
                            )}
                            {props.index !== props.totalItems - 1 && (
                                <MenuItem
                                    icon={<ArrowDownIcon />}
                                    data-testid="move-down-prompt"
                                    onClick={() => {
                                        handlePromptMoveDown(props.index ?? -1);
                                    }}
                                >
                                    {t('Move Down')}
                                </MenuItem>
                            )}
                        </MenuList>
                    </MenuPopover>
                </Menu>
            </div>
        );
    }

    function renderToolbarContent() {
        return (
            <div
                className={mergeClasses(
                    classes.flexContainerRow,
                    classes.flexGrow,
                    classes.toolBarContainer,
                )}
            >
                {isDirectSkillInvocationEnabled && (
                    <>
                        <Tooltip
                            content={t('DirectSkill')}
                            relationship="label"
                            positioning="above"
                        >
                            <ToolbarButton
                                appearance="subtle"
                                icon={<SkillIcon />}
                                className={classes.toolBarButton}
                                aria-label={t('ChooseSkill')}
                                data-testid="choose-skill-prompt"
                                onClick={() => setSkillListOpen(!skillListOpen)}
                            />
                        </Tooltip>
                        <div className={classes.directSkillsMenu}>
                            <DirectSkillsMenu
                                data-testid="skill-menu"
                                ref={inputWrapperRef}
                                open={skillListOpen}
                                skillsets={userSkillsets ?? undefined}
                                onSkillSelect={handleSelect}
                                onHide={() => setSkillListOpen(false)}
                                directSkillMenu={true}
                                menuHeight={menuHeight}
                            />
                        </div>
                        <div className={classes.verticalLine} />
                    </>
                )}
                {isPromptbookContinueOnFailureEnabled && (
                    <>
                        <Menu persistOnItemClick={true}>
                            <MenuTrigger>
                                <Button
                                    className={classes.toolBarButton}
                                    icon={<MoreHorizontalIcon />}
                                    data-testid="more-prompt-options"
                                    appearance="subtle"
                                    aria-label={t('PromptOptionsButton')}
                                />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    {isPromptbookContinueOnFailureEnabled && (
                                        <>
                                            <MenuItem data-testid="continue-on-failure">
                                                <Switch
                                                    label={t('ContinueOnFailure')}
                                                    checked={tempContinueOnFailure}
                                                    onChange={(_, data) => {
                                                        setTempContinueOnFailure(data.checked);
                                                    }}
                                                />
                                            </MenuItem>
                                        </>
                                    )}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </>
                )}
                <ToolbarButton
                    className={classes.toolBarButton}
                    appearance="subtle"
                    data-testid="accept-edit-prompt"
                    aria-label={t('AcceptEdit')}
                    icon={<AcceptIcon />}
                    onClick={() => handleAcceptEditPrompt(props.index ?? -1)}
                />
                <ToolbarButton
                    className={classes.toolBarButton}
                    appearance="subtle"
                    data-testid="close-edit-prompt"
                    aria-label={t('CloseEdit')}
                    icon={<DismissIcon />}
                    onClick={() => handleClosePromptEditing()}
                />
            </div>
        );
    }

    return (
        <>
            {props.promptDefn &&
                (editMode ? (
                    renderEditModeContent()
                ) : (
                    <div
                        className={mergeClasses(
                            classes.flexContainerRow,
                            classes.flexGrow,
                            classes.promptLabel,
                        )}
                        ref={promptRef}
                        role="group"
                        tabIndex={0}
                        data-test-id={'edit-duplicate-prompt'}
                        {...restoreFocusTarget}
                    >
                        {renderNormalModeContent()}
                        {renderPromptOptions()}
                    </div>
                ))}
        </>
    );
}
