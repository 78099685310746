import React, {useEffect, useState, useMemo, useCallback} from 'react';
import WorkspacesContext from './WorkspacesContext';
import useGetInitialWorkspaces from '@/api/workspaces/useGetInitialWorkspaces';
import {writeToSessionStorage} from '@/util/sessionStorage.util';
import {WORKSPACE_NAMESPACE} from './workspaces.constants';
import {WorkspaceSessionData, isGeoPodEnabled} from './';
import {useGetWorkspaceByName, Workspace} from '@/api/workspaces';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useGetCurrentWorkspace from '@/api/user/useGetCurrentWorkspace';
import {isUserSignedIn} from '@/util/msal/authConfig';
import {msalInstance} from '@/util/msal/authConfig';
import {getCurrentWorkspaceCache, setCurrentWorkspaceCache} from './';
import {formatWorkspaceToSessionData} from './';
import useUpdateCurrentWorkspace from '@/api/user/useUpdateCurrentWorkspace';

/** Manages rendering the WorkspacesContextProvider if enabled
 * @returns the WorkspacesProvider component or children
 *
 *
 */
const WorkspacesProvider = ({children}: {children: React.ReactNode}) => {
    const isSignedIn = isUserSignedIn(msalInstance);
    // ECS based flag returned from userInfo is populated the UserContext
    // isGeoPodEnabled will check the session storage value from UserContext
    const [workspaceName, setWorkspaceName] = useState('');

    // needed to update current workspace if value is empty
    const updateUserPreferences = useUpdateCurrentWorkspace();

    // Click stop 2 should include the call to get /workspaces
    const {
        data: workspaces,
        isLoading: isListWorkspacesLoading,
        isError: isListWorkspacesError,
        refetch,
    } = useGetInitialWorkspaces({enabled: Boolean(isSignedIn)});

    const hasCachedWorkspace = useCallback(() => {
        // console.log('checking cache');
        return Boolean(getCurrentWorkspaceCache()?.name);
    }, []);

    const hasWorkspaces = useMemo(
        () => isSignedIn && ((workspaces?.count && workspaces.count > 0) || hasCachedWorkspace()),
        [hasCachedWorkspace, isSignedIn, workspaces],
    );
    // A user that is signed and has no workspaces
    // OR we have finished the workspaces call and there are no workspaces
    // OR we have an error listing workspaces
    const needsWorkspaceCreation = useMemo(
        () =>
            isSignedIn &&
            !hasWorkspaces &&
            ((!isListWorkspacesLoading && !isListWorkspacesError) ||
                (!isListWorkspacesLoading && isListWorkspacesError)) &&
            !hasCachedWorkspace(),
        [
            hasCachedWorkspace,
            hasWorkspaces,
            isListWorkspacesError,
            isListWorkspacesLoading,
            isSignedIn,
        ],
    );
    // console.log(
    //     `needsWorkspaceCreation ${needsWorkspaceCreation} isSigned:${isSignedIn} hasWorkspaces:${hasWorkspaces} isListWorkspacesLoading:${isListWorkspacesLoading} isListWorkspacesError:${isListWorkspacesError} hasCachedWorkspace:${hasCachedWorkspace()}`,
    // );

    // When the user is not signed in
    // or the list workspaces endpoint returns an error, indicating the account needs to be setup or user has no permissions
    // or workspaces list returned but has no workspaces
    const renderPassthrough = useMemo(
        () =>
            !isSignedIn ||
            (!isListWorkspacesLoading && isListWorkspacesError) ||
            needsWorkspaceCreation,
        [isListWorkspacesError, isListWorkspacesLoading, isSignedIn, needsWorkspaceCreation],
    );

    /** Wait for the user to be signed and either need a workspace or has a workspace and we have a stored cached workspace */
    const renderReady = useMemo(
        () => isSignedIn && (needsWorkspaceCreation || (hasWorkspaces && hasCachedWorkspace())),
        [hasCachedWorkspace, isSignedIn, needsWorkspaceCreation, hasWorkspaces],
    );

    // console.log(
    //     `renderPassthrough ${renderPassthrough} renderReady ${renderReady} needsWorkspaceCreation:${needsWorkspaceCreation} workspaceName:${workspaceName} isSigned:${isSignedIn} isListWorkspacesError:${isListWorkspacesError}   hasCachedWorkspace:${hasCachedWorkspace()}`,
    // );

    // MultiWorkspaces should include the call to get /workspacesettings
    // WorkspacesSettings will give us the currentWorkspace last selected by the user
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {data: currentWorkspace} = useGetCurrentWorkspace({
        enabled: Boolean(isWorkspacesTestingEnabled && isSignedIn),
    });
    // if we have a current workspace we can use it to get the workspace from list workspaces
    // console.log(
    //     `currentWorkspace:${currentWorkspace} isWorkspacesTestingEnabled:${isWorkspacesTestingEnabled}`,
    // );

    // GA flow for setting geo pod workspace information
    const setGAWorkspace = useCallback(async (geoPodWorkspace: Workspace) => {
        try {
            const workspaceData = {
                name: geoPodWorkspace.name,
                path: geoPodWorkspace.path,
                managementUrl: geoPodWorkspace.managementUrl,
                // we want to capture the second part of the path of  pods/82e853fb-c82c-4d62-9812-439bf8a887c3/workspaces/default,
                podId: geoPodWorkspace.path?.split('/')[1],
            };
            setCurrentWorkspaceCache(formatWorkspaceToSessionData(workspaceData));
            setWorkspaceName(geoPodWorkspace.name);
        } catch (e) {
            console.error(`error setting workspace ${geoPodWorkspace.name}`);
        }
    }, []);

    useEffect(() => {
        if (workspaces?.value?.length) {
            // When geopods are enabled we need to update how we route microsoft security graph requests
            // First we store the geo pod workspace in session storage
            // right now there's only one workspace so pull the first one in non-multiworkspaces scenario
            const storedCurrentWorkspace = workspaces.value.find(
                (workspace) => workspace.name === currentWorkspace,
            );
            if (isWorkspacesTestingEnabled) {
                if (!currentWorkspace || !storedCurrentWorkspace) {
                    updateUserPreferences.mutate({
                        currentWorkspace: workspaces?.value[0].name,
                    });
                }
            }
            const geoPodWorkspace =
                isWorkspacesTestingEnabled && storedCurrentWorkspace
                    ? storedCurrentWorkspace
                    : workspaces?.value[0];
            if (geoPodWorkspace) {
                // console.log(`setting workspace ${geoPodWorkspace.name}`);
                setGAWorkspace(geoPodWorkspace);
            }
        }
    }, [currentWorkspace, isWorkspacesTestingEnabled, setGAWorkspace, workspaces]);

    console.log(
        `renderPassThrough:${renderPassthrough} renderReady:${renderReady} isWorkspacesTestingEnabled : ${isWorkspacesTestingEnabled} isSignedIn:${isSignedIn} needsWorkspaceCreation:${needsWorkspaceCreation} hasWorkspaces:${hasWorkspaces} hasCachedWorkspace:${hasCachedWorkspace()} isWorkspacesListError: ${isListWorkspacesError}`,
    );

    /** If we are loading from the cache we want to force a refresh of workspace name to continue rendering
     * MultiWorkspaces: this also allows us to catch a refresh of the workspace
     */
    useEffect(() => {
        if (hasCachedWorkspace() && !workspaceName) {
            const currentWorkspace = getCurrentWorkspaceCache();
            if (currentWorkspace) {
                setWorkspaceName(currentWorkspace?.name || '');
            } else {
                throw new Error('currentWorkspace is null');
            }
        }
    }, [hasCachedWorkspace, workspaceName]);

    // if we have the workspace we can render the context provider so that workspace state provider
    // it will check that the workspace is correctly setup
    return renderPassthrough || hasCachedWorkspace() ? (
        <WorkspacesContext>{children}</WorkspacesContext>
    ) : null;
};

export default WorkspacesProvider;
