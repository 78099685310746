import * as React from 'react';
import {
    catalogScopeToSettingsScope,
    SettingsScope,
    SkillsetDescriptor,
    SkillsetFilterTypes,
    SkillsetRequirementsCheckResult,
    useCheckRequirements,
    useGetSkillsets,
} from '@/api/skills';
import SkillRow, {SkillRowData} from './SkillRow';
import SkillsetsExpansionButton from './SkillsetsExpansionButton';
import {useGetSkillRowData} from './Utils/useSkillRowData';
import {findSkillset} from '../Utils/Skillsets.utils';
import {useVisibleCategorySkillsets} from './Utils/useVisibleCategorySkillsets';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';

interface SkillsetsSectionProps {
    skillsetsInSection: SkillsetDescriptor[];
    selectedSkillsets: Set<string>;
    configurableSkillsets: Set<string> | undefined;
    configuredSkillsets: Set<string>;
    displayedSkillsetFilter: SkillsetFilterTypes;
    onOpenAdminView?: (
        skillset: string,
        errors: SkillsetRequirementsCheckResult | null,
        scope: SettingsScope | null,
        skillsetDescriptor: SkillsetDescriptor | null,
    ) => void;
    onSwitchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    isExpanded: boolean;
    onExpandCollapse: (expanded: boolean) => void;
    switchRefs: React.MutableRefObject<{
        [keyId: string]: HTMLInputElement | null;
    }>;
}

export default function SkillsetsSection({
    skillsetsInSection,
    selectedSkillsets,
    configurableSkillsets,
    configuredSkillsets,
    displayedSkillsetFilter,
    onOpenAdminView,
    onSwitchChange,
    isExpanded,
    onExpandCollapse,
    switchRefs,
}: SkillsetsSectionProps) {
    const {data: requirementsCheck} = useCheckRequirements();
    const {getVisibleSkillsets, showExpandCollapseButton, moreSkillsetsCount} =
        useVisibleCategorySkillsets(
            skillsetsInSection,
            configurableSkillsets,
            configuredSkillsets,
            displayedSkillsetFilter,
        );
    const {getSkillRowData, filteredOutSkillRow} = useGetSkillRowData(
        selectedSkillsets,
        configurableSkillsets,
        configuredSkillsets,
    );
    const [showAll, setShowAll] = React.useState(isExpanded);
    const visibleSkillsets: SkillsetDescriptor[] = React.useMemo(
        () => getVisibleSkillsets(showAll),
        [getVisibleSkillsets, showAll],
    );

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const handleOpenAdminView = (skillsetName: string) => {
        const descriptor = skillsetsInSection.find((s) => s.name === skillsetName) ?? null;
        // Convert the skillset descriptor SkillsetCatalogScope to a SettingsScope
        const settingsScopeFallback = isWorkspacesTestingEnabled
            ? SettingsScope.UserWorkspace
            : null;
        const scope = catalogScopeToSettingsScope(descriptor?.catalogScope, settingsScopeFallback);

        onOpenAdminView?.(
            skillsetName,
            requirementsCheck?.value.filter((r) => r.skillsetName == skillsetName)[0] ?? null,
            scope,
            descriptor,
        );
    };

    const handleSectionExpand = () => {
        setShowAll(!showAll);
        onExpandCollapse(!showAll);
    };

    return (
        <div data-testid={'skillsets-section'}>
            {visibleSkillsets.map((skillset, index) => {
                const skillRowData: SkillRowData = getSkillRowData(
                    skillset,
                    index,
                    skillsetsInSection.length,
                    visibleSkillsets.length,
                );

                if (filteredOutSkillRow(skillRowData, displayedSkillsetFilter)) {
                    return null;
                }

                return (
                    <SkillRow
                        key={skillRowData.name}
                        data={skillRowData}
                        componentRef={(el) => (switchRefs.current[skillRowData.componentId] = el)}
                        onOpenSettings={() => handleOpenAdminView(skillRowData.name)}
                        onSetUp={(ev) => handleOpenAdminView(ev.currentTarget.value)}
                        onSwitchChange={onSwitchChange}
                    />
                );
            })}
            {showExpandCollapseButton && (
                <SkillsetsExpansionButton
                    isExpanded={showAll}
                    moreSkillsetsCount={moreSkillsetsCount}
                    onClick={handleSectionExpand}
                />
            )}
        </div>
    );
}
