'use client';

import React, {useEffect, useRef, useState} from 'react';
import {Editor, type Monaco} from '@monaco-editor/react';
import useClasses from './SkillsetEditor.styles';
import {Dropdown, Option, useId, mergeClasses, Button} from '@fluentui/react-components';
import {
    SkillsetCatalogScope,
    SkillsetCategory,
    SkillsetContentType,
    SkillsetDescriptor,
    SkillsetFormat,
    useGetAllSkills,
    useGetSkillsets,
    useGetSkillsetCode,
    useUpdateSkillset,
    useCreateSkillset,
    SettingsScope,
} from '@/api/skills';
import {CancellationToken, Position, languages, type editor} from 'monaco-editor';
import {AddIcon, CheckmarkIcon} from '../icons';
import ToastNotification from '../Toasts/ToastNotification';
import {Intent, ToastPositionUI} from '../Toasts/ToastNotification.types';
import {ColorScheme, useAppState} from '@/api/app';
import * as yaml from 'js-yaml';
import {SkillsetEditorProps} from './SkillsetCreator.types';
import {usePatchSettings} from '@/api/settings';
import MedeinaFeatures, {MedeinaUrlParamFeatures} from '@/util/features';
import {useFeatureFlag} from '@/api/user';

const SkillsetEditor = ({
    onValueChange,
    currentSkillsetYaml,
    currentSkillsetName,
    onChangeCurrentSkillset,
}: SkillsetEditorProps) => {
    const classes = useClasses();
    const createSkillset = useCreateSkillset();
    const updateSkillset = useUpdateSkillset();
    const patchSettings = usePatchSettings();
    const saveButtonId = useId('saveButton');
    const {
        data: skillsets,
        status: skillsetsLoadStatus,
        refetch: refetchSkillsets,
    } = useGetSkillsets();
    const [saveButtonContent, setSaveButtonContent] = useState<string>('Save');
    const [activeSkillset, setActiveSkillset] = useState<SkillsetDescriptor | null>(null);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [isCreatingSkillset, setIsCreatingSkillset] = useState<boolean>(false);
    const {data: skillsetText, refetch: refetchSkillset} = useGetSkillsetCode({
        skillset: activeSkillset?.name ?? '',
    });
    const [activeError, setActiveError] = useState<string | null>(null);
    const {data: allSkills, refetch: refetchAllSkills} = useGetAllSkills();
    const [yamlCompletionProviderRegistered, setYamlCompletionProviderRegistered] =
        useState<boolean>(false);
    const {colorScheme} = useAppState();
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
    const monacoRef = useRef<Monaco | null>(null);
    const handleEditorDidMount = (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => {
        editorRef.current = editor;
        monacoRef.current = monaco;
        editor.onDidChangeModelContent((e: editor.IModelContentChangedEvent) => {
            setUnsavedChanges(true);
            if (editorRef.current) {
                onValueChange?.(editorRef.current.getModel()?.getValue());
            }
        });
        editor.setModel(
            monaco.editor.createModel('', 'yaml', monaco.Uri.parse('content://skillset')),
        );
        editor.updateOptions({
            wordWrap: 'on',
            suggest: {
                filterGraceful: false,
                selectionMode: 'always',
                preview: true,
            },
            suggestOnTriggerCharacters: true,
            tabFocusMode: false,
            tabSize: 2,
        });
        monaco.editor.addKeybindingRule({
            keybinding: monaco.KeyCode.Enter,
            command: 'jumpToNextSnippetPlaceholder',
            when: 'inSnippetMode',
        });
        monaco.editor.addCommand({
            id: 'saveSkillset',
            run: () => document.getElementById(saveButtonId)?.click(),
        });
        monaco.editor.addKeybindingRule({
            keybinding: monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS,
            command: 'saveSkillset',
        });
    };

    const handleUpdateSkillset = async () => {
        if (!editorRef?.current) {
            return;
        }
        if (!allSkills) {
            await refetchAllSkills();
        }
        setUnsavedChanges(false);
        setSaveButtonContent('Saving...');
        const currentCode = editorRef.current.getModel()?.getValue();
        if (!currentCode) {
            return;
        }
        const skillsetName = tryGetSkillsetName(currentCode, activeSkillset);
        if (!skillsetName) {
            setActiveError('Could not determine skillset name');
            return;
        }
        const skillsetAlreadyExists =
            skillsets?.value && skillsets.value.findIndex((s) => s.name === skillsetName) !== -1;
        const upsertPromise = skillsetAlreadyExists
            ? updateSkillset.mutateAsync({
                  body: currentCode,
                  name: skillsetName,
                  scope: 'User',
                  format: SkillsetFormat.SkillsetYaml,
                  contentType: SkillsetContentType.Yaml,
                  contentUrl: '',
              })
            : createSkillset.mutateAsync({
                  body: currentCode,
                  scope: 'User',
                  format: SkillsetFormat.SkillsetYaml,
                  contentType: SkillsetContentType.Yaml,
                  contentUrl: '',
              });
        await upsertPromise
            .then(
                (success) => {
                    setUnsavedChanges(false);
                    if (monacoRef.current && editorRef.current) {
                        monacoRef.current.editor.setModelMarkers(
                            editorRef.current.getModel()!,
                            'skillsetValidator',
                            [],
                        );
                        editorRef.current.trigger(null, 'closeMarkersNavigation', null);
                    }
                    let basePromise = Promise.resolve();
                    setActiveSkillset(success.descriptor);
                    if (isCreatingSkillset) {
                        setIsCreatingSkillset(false);
                        basePromise = refetchSkillsets().then(
                            () => {},
                            () => {},
                        );
                    }
                    basePromise = basePromise.then(() => addEnabledSkillset(skillsetName));
                    return basePromise;
                },
                (failure) => {
                    setUnsavedChanges(true);
                    if (failure?.name === 'ClientApiError') {
                        failure.response.text().then((failureBody: string) => {
                            tryApplyErrorMarkers(failureBody);
                            setActiveError(failureBody);
                        });
                        // editorRef.current.marker
                    } else {
                        setActiveError(failure.toString());
                    }
                },
            )
            .finally(() => {
                setSaveButtonContent('Save');
            });
    };

    const tryApplyErrorMarkers = (failureBody: string) => {
        try {
            const errorMessage = (JSON.parse(failureBody) as any).message as string | null;
            if (!errorMessage) {
                return;
            }
            const errorRegex = /\(Line: (\d+), Col: (\d+), .*Line: (\d+), Col: (\d+), .*\): (.*)/;
            const groups = errorMessage.match(errorRegex);
            if (groups) {
                const startLine = parseInt(groups[1]);
                const startColumn = parseInt(groups[2]);
                const endLine = parseInt(groups[3]);
                const endColumn = parseInt(groups[4]);
                const message = groups[5];
                if (!monacoRef.current || !editorRef.current) {
                    return;
                }
                editorRef.current.focus();
                monacoRef.current.editor.setModelMarkers(
                    editorRef.current.getModel()!,
                    'skillsetValidator',
                    [
                        {
                            startLineNumber: startLine,
                            endLineNumber: endLine + 1,
                            startColumn: startColumn,
                            endColumn: endColumn + 1,
                            message: message,
                            severity: monacoRef.current.MarkerSeverity.Error,
                        },
                    ],
                );
                editorRef.current.trigger(null, 'editor.action.marker.next', null);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getIndentationLevel = (line: string) => {
        let indentation = 0;
        for (let i = 0; i < line.length; i++) {
            if (line[i] == '\t') {
                indentation += 4;
            } else if (line[i] == ' ' || line[i] == '-') {
                indentation += 1;
            } else {
                break;
            }
        }
        return indentation;
    };

    const getScopeLines = (lines: string[], position: Position) => {
        const lineNumberZeroIndexed = position.lineNumber - 1;
        if (lineNumberZeroIndexed >= lines.length || lineNumberZeroIndexed < 0) {
            return null;
        }
        let currentIndentation = getIndentationLevel(lines[lineNumberZeroIndexed]);
        let scopeLines = [];
        for (let i = lineNumberZeroIndexed; i >= 0; i--) {
            const indentationLevel = getIndentationLevel(lines[i]);
            if (currentIndentation >= indentationLevel) {
                scopeLines.push(lines[i]);
                currentIndentation = indentationLevel;
            }
        }
        return scopeLines;
    };

    const tryGetSkillsetName = (
        skillsetBody: string,
        activeSkillset: SkillsetDescriptor | null,
    ) => {
        try {
            const parsedSkillset = yaml.load(skillsetBody) as any;
            return (parsedSkillset.Descriptor.Name as string | null) ?? activeSkillset?.name;
        } catch (e) {
            console.error(e);
            return activeSkillset?.name;
        }
    };

    const provideCompletionItems = (
        model: editor.ITextModel,
        position: Position,
        context: languages.CompletionContext,
        token: CancellationToken,
    ) => {
        const word = model.getWordUntilPosition(position);
        if (!allSkills) {
            return {suggestions: []};
        }
        const lines = model.getLinesContent();
        const scopeLines = getScopeLines(lines, position);
        let isCompletingChildSkills = true;
        if (scopeLines) {
            // Determine if we are autocompleting a ChildSkills
            if (scopeLines.filter((l) => l.trimStart().startsWith('ChildSkills')).length == 0) {
                isCompletingChildSkills = false;
            }
        }
        if (!isCompletingChildSkills) {
            return {suggestions: []};
        }
        return {
            suggestions: [
                ...allSkills.value.map((skill) => {
                    return {
                        label: skill.displayName ?? skill.name,
                        kind: monacoRef.current!.languages.CompletionItemKind.Function,
                        insertText: skill.name,
                        range: {
                            startLineNumber: position.lineNumber,
                            endLineNumber: position.lineNumber,
                            startColumn: word.startColumn,
                            endColumn: word.endColumn,
                        },
                        detail: skill.description,
                        documentation: skill.description,
                    };
                }),
            ],
        };
    };

    const newSkillset = () => {
        if (!editorRef.current) {
            return;
        }
        const editor = editorRef.current;
        const contribution = editor.getContribution('snippetController2');
        if (!contribution) {
            return;
        }
        setIsCreatingSkillset(true);
        setActiveSkillset({
            name: 'New Skillset',
            description: '',
            descriptionDisplay: '',
            category: SkillsetCategory.Other,
            displayName: 'New Skillset',
            icon: null,
            catalogScope: isWorkspacesTestingEnabled
                ? SkillsetCatalogScope.UserWorkspace
                : SkillsetCatalogScope.User,
            enabled: true,
        });
        onChangeCurrentSkillset?.('New Skillset');
        editor.setValue('');
        (contribution as any).insert(
            `Descriptor:
  Name: AgentWorkshop
  Description: Build an agent workshop
  DisplayName: Agent Workshop
SkillGroups:
- Format: AGENT
  Skills:
  - Name: UserAgent
    Description: |
      Specialized security agent that can lookup information about users and their sign-in activity.
    Interfaces:
    - Agent
    Inputs:
    - Required: true
      Name: Input
      Description: The user account to investigate
    Settings:
      Instructions: |
        You are a specialized security agent that can lookup information about users and their sign-in activity.
    ChildSkills:
    - GetEntraUserDetails
    - GetEntraGroupDetails
    - GetAccountObjectIDFromAccountUPN
    - FindLoginActivityForAccount
  Settings:
    HistoryPassDownMode: None
    IncludeSessionHistory: false`,
        );
        editor.focus();
    };

    useEffect(() => {
        const initializeEditor = async () => {
            if (currentSkillsetYaml) {
                while (!editorRef.current) {
                    await new Promise((resolve) => setTimeout(resolve, 100));
                }
                const editor = editorRef.current;
                const contribution = editor.getContribution('snippetController2');
                editor.setValue('');
                (contribution as any).insert(currentSkillsetYaml);
            }
        };
        initializeEditor();
    }, []);

    useEffect(() => {
        if (currentSkillsetName) {
            setActiveSkillset(skillsets?.value?.find((n) => n.name == currentSkillsetName) || null);
        }
    }, [currentSkillsetName]);

    const getEnabledSkillsets = (): string[] | undefined => {
        return skillsets?.value
            ?.filter((s) => s.enabled && s.category.toString() !== 'Hidden')
            ?.map((s) => s.name);
    };

    const isAgentEnableOnSaveEnabled = useFeatureFlag(MedeinaUrlParamFeatures.AgentEnableOnSave);
    const addEnabledSkillset = async (skillsetToEnable: string) => {
        if (!isAgentEnableOnSaveEnabled) {
            return;
        }
        const enabledSkillsets = getEnabledSkillsets();
        if (!enabledSkillsets) {
            return;
        }
        if (enabledSkillsets.indexOf(skillsetToEnable) !== -1) {
            return;
        }
        enabledSkillsets.push(skillsetToEnable);
        await patchSettings.mutateAsync({
            enabledSkillsets,
            scope: isWorkspacesTestingEnabled ? SettingsScope.UserWorkspace : SettingsScope.User,
        });
    };

    useEffect(() => {
        if (!monacoRef.current) {
            return;
        }
        if (!yamlCompletionProviderRegistered) {
            monacoRef.current.languages.registerCompletionItemProvider('yaml', {
                provideCompletionItems,
                triggerCharacters: [':', ' '],
            });
            setYamlCompletionProviderRegistered(true);
        }
    }, [allSkills]);

    useEffect(() => {
        if (
            !activeSkillset ||
            !editorRef ||
            !editorRef.current ||
            !skillsetText ||
            typeof skillsetText !== 'string' ||
            isCreatingSkillset
        ) {
            return;
        }
        editorRef.current.setValue(skillsetText ?? '');
        setUnsavedChanges(false);
    }, [editorRef, skillsetText, isCreatingSkillset]);

    return (
        <>
            <div className={classes.editorToolbar}>
                <Dropdown
                    className={mergeClasses(classes.dropdownSelector, classes.editorContent)}
                    value={activeSkillset?.name ?? currentSkillsetName ?? ''}
                    selectedOptions={activeSkillset?.name ? [activeSkillset.name] : []}
                    onOptionSelect={(_, data) => {
                        setIsCreatingSkillset(false);
                        setActiveSkillset(
                            skillsets?.value?.find((n) => n.name == data.optionValue) || null,
                        );
                        onChangeCurrentSkillset?.(data.optionValue ?? '');
                    }}
                    placeholder={
                        skillsetsLoadStatus === 'loading'
                            ? 'Loading skillsets...'
                            : 'Select skillset'
                    }
                >
                    {skillsets &&
                        skillsets.value
                            .filter(
                                (skillset) =>
                                    skillset.catalogScope ==
                                    (isWorkspacesTestingEnabled
                                        ? SkillsetCatalogScope.UserWorkspace
                                        : SkillsetCatalogScope.User),
                            )
                            .map((skillset) => (
                                <Option key={skillset.name} value={skillset.name}>
                                    {skillset.displayName ?? ''}
                                </Option>
                            ))}
                </Dropdown>
                <Button
                    icon={<CheckmarkIcon />}
                    id={saveButtonId}
                    size="medium"
                    onClick={handleUpdateSkillset}
                    disabled={!unsavedChanges}
                    className={classes.editorContent}
                >
                    {saveButtonContent}
                </Button>
                <Button
                    icon={<AddIcon />}
                    size="medium"
                    onClick={newSkillset}
                    className={classes.editorContent}
                >
                    New
                </Button>
            </div>
            <Editor
                height="calc(100vh - 100px)"
                defaultLanguage="yaml"
                theme={
                    colorScheme === ColorScheme.Dark
                        ? 'vs-dark'
                        : colorScheme === ColorScheme.Light
                        ? 'light'
                        : colorScheme === ColorScheme.HighContrast
                        ? 'hc-black'
                        : 'vs-dark'
                }
                onMount={handleEditorDidMount}
            />
            {activeError && (
                <ToastNotification
                    intent={Intent.Error}
                    message={activeError}
                    position={ToastPositionUI.Bottom_End}
                ></ToastNotification>
            )}
        </>
    );
};

export default SkillsetEditor;
