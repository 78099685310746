import * as React from 'react';
import {
    SettingsScope,
    SkillsetCategory,
    SkillsetDescriptor,
    SkillsetFilterTypes,
    SkillsetRequirementsCheckResult,
} from '@/api/skills';
import ConnectorCategoryEntry from './ConnectorCategoryEntry';
import {categoryStringMappings} from '../Utils/ConnectorModalContent.types';
import {useTranslation} from 'react-i18next';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface ConnectorCategoryEntriesProps {
    visibleSkillsets: SkillsetDescriptor[];
    selectedSkillsets: Set<string>;
    configurableSkillsets: Set<string> | undefined;
    configuredSkillsets: Set<string>;
    displayedSkillsetFilter: SkillsetFilterTypes;
    selectedCategories: Record<string, string[]>;
    onOpenAdminView?: (
        skillset: string,
        errors: SkillsetRequirementsCheckResult | null,
        scope: SettingsScope | null,
        skillsetDescriptor: SkillsetDescriptor | null,
    ) => void;
    onOpenExternalView: (
        type: string,
        skillsetDescriptor: SkillsetDescriptor | null,
        showDelete: boolean,
    ) => void;
    onCategoryChangeHandler: (category: string, checked: boolean) => void;
    onSwitchChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    addPluginButtonRef: React.RefObject<HTMLButtonElement>;
    switchRefs: React.MutableRefObject<{
        [keyId: string]: HTMLInputElement | null;
    }>;
}

export default function ConnectorCategoryEntries({
    visibleSkillsets,
    selectedSkillsets,
    configurableSkillsets,
    configuredSkillsets,
    displayedSkillsetFilter,
    selectedCategories,
    onOpenAdminView,
    onOpenExternalView,
    onCategoryChangeHandler,
    onSwitchChange,
    addPluginButtonRef,
    switchRefs,
}: ConnectorCategoryEntriesProps) {
    const {t} = useTranslation('plugins');

    const getAllSkillsetsInCategory = React.useMemo(
        () =>
            (category: string): SkillsetDescriptor[] => {
                return visibleSkillsets.filter((skillset) => skillset.category === category);
            },
        [visibleSkillsets],
    );

    const getSelectedSkillsetsInCategory = React.useMemo(
        () =>
            (category: string): SkillsetDescriptor[] => {
                return visibleSkillsets?.filter(
                    (skillset) =>
                        skillset.category === category && selectedSkillsets.has(skillset.name),
                );
            },
        [visibleSkillsets, selectedSkillsets],
    );

    const isAdvancedPluginFilteringEnabled = useFeatureFlag(
        MedeinaFeatures.EnableAdvancedPluginFiltering,
    );
    // Returns skillsets that are unselected only if they have been configured
    const getUnselectedSkillsetsInCategory = React.useMemo(
        () =>
            (category: string): SkillsetDescriptor[] => {
                // Original logic
                if (!isAdvancedPluginFilteringEnabled) {
                    return visibleSkillsets?.filter(
                        (skillset) =>
                            skillset.category === category && !selectedSkillsets.has(skillset.name),
                    );
                }
                // New logic which excludes unconfigurable skillsets
                return visibleSkillsets?.filter((skillset) => {
                    // Skillset is configurable and has been configured but is not selected
                    if (configurableSkillsets?.has(skillset.name)) {
                        return (
                            skillset.category === category &&
                            configuredSkillsets?.has(skillset.name) &&
                            !selectedSkillsets.has(skillset.name)
                        );
                    }
                    // Skillset is not configurable and not selected
                    else {
                        return (
                            skillset.category === category && !selectedSkillsets.has(skillset.name)
                        );
                    }
                });
            },
        [visibleSkillsets, selectedSkillsets],
    );

    // Returns skillsets that are not set up (configurable but not configured)
    const getNotSetUpSkillsetsInCategory = React.useMemo(
        () =>
            (category: string): SkillsetDescriptor[] => {
                return visibleSkillsets?.filter(
                    (skillset) =>
                        skillset.category === category &&
                        configurableSkillsets?.has(skillset.name) &&
                        !configuredSkillsets?.has(skillset.name),
                );
            },
        [visibleSkillsets, selectedSkillsets],
    );

    // Mapping of whether a category has any selectable skillsets after searching/filtering
    const categoryHasSelectable = React.useMemo(() => {
        const hasSelectable = new Map<string, boolean>();
        for (const category of Object.keys(SkillsetCategory)) {
            if (
                (displayedSkillsetFilter === SkillsetFilterTypes.On &&
                    getSelectedSkillsetsInCategory(category).length > 0) ||
                (displayedSkillsetFilter === SkillsetFilterTypes.Off &&
                    getUnselectedSkillsetsInCategory(category).length > 0) ||
                (displayedSkillsetFilter === SkillsetFilterTypes.NotSetUp &&
                    getNotSetUpSkillsetsInCategory(category).length > 0)
            ) {
                hasSelectable.set(category, true);
            } else {
                hasSelectable.set(category, false);
            }
        }
        return hasSelectable;
    }, [displayedSkillsetFilter, getSelectedSkillsetsInCategory, getUnselectedSkillsetsInCategory]);

    const categoryHasSelected = React.useMemo(() => {
        const hasSelected = new Map<string, boolean>();
        for (const category of Object.keys(SkillsetCategory)) {
            if (getSelectedSkillsetsInCategory(category).length > 0) {
                hasSelected.set(category, true);
            } else {
                hasSelected.set(category, false);
            }
        }
        return hasSelected;
    }, [getSelectedSkillsetsInCategory]);

    return (
        <>
            {Object.entries(categoryStringMappings).map(([category, categoryInfoStringIds]) => {
                const skillsetsInCategory = getAllSkillsetsInCategory(category);
                const categoryName = t(categoryInfoStringIds[0]);
                const categoryDescription = t(categoryInfoStringIds[1]);
                const shouldShowComponent =
                    categoryName != '' &&
                    categoryDescription != '' &&
                    category != SkillsetCategory.Testing &&
                    (displayedSkillsetFilter == SkillsetFilterTypes.All ||
                        categoryHasSelectable.get(category)) &&
                    (Object.keys(selectedCategories).length == 0 ||
                        Object.keys(selectedCategories).includes(categoryName));
                if (!shouldShowComponent) {
                    return null;
                }

                return (
                    <ConnectorCategoryEntry
                        key={category}
                        category={category}
                        categoryName={categoryName}
                        categoryDescription={categoryDescription}
                        skillsetsInCategory={skillsetsInCategory}
                        selectedSkillsets={selectedSkillsets}
                        configurableSkillsets={configurableSkillsets}
                        configuredSkillsets={configuredSkillsets}
                        displayedSkillsetFilter={displayedSkillsetFilter}
                        onOpenAdminView={onOpenAdminView}
                        onOpenExternalView={onOpenExternalView}
                        onCategoryChangeHandler={onCategoryChangeHandler}
                        onSwitchChange={onSwitchChange}
                        categorySelected={categoryHasSelected.get(category) ?? false}
                        addPluginButtonRef={addPluginButtonRef}
                        switchRefs={switchRefs}
                    />
                );
            })}
        </>
    );
}
