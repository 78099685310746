import {useQuery} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {SettingsScope, SkillsetRequest} from './skills.types';
import {useFeatureFlag} from '../user';
import MedeinaFeatures from '@/util/features';

export default function useGetSkillsetOAuthAuthorizationEndpoint({skillsetName}: SkillsetRequest) {
    const {customFetch} = useFetch();
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const queryParams = `?scope=${
        isWorkspacesTestingEnabled ? SettingsScope.UserWorkspace : SettingsScope.User
    }`;
    return useQuery({
        // Cache for entire session, as this rarely changes.
        queryKey: ['skillset', skillsetName],
        queryFn: async () => {
            const response = await customFetch<Response>(
                `/skillsets/${skillsetName}/oauth2/authorize${queryParams}`,
                {
                    method: 'GET',
                },
            );
            return response;
        },
        enabled: false,
        refetchOnMount: false,
    });
}
