import React, {useReducer, useEffect, useState} from 'react';
import {
    Card,
    Caption1,
    Caption1Strong,
    mergeClasses,
    Body1,
    Spinner,
} from '@fluentui/react-components';
import {QuestionMarkIcon} from '@/components/ui/icons';
import {PromptSuggestionDescriptor} from '@/api/prompts/suggestedPrompts.types';
import useClasses from './ExploreCardList.styles';
import ExploreCardList from './ExploreCardList';
import ExploreCardEmpty from './ExploreCardEmpty';
import {ExploreCardProps} from './ExploreCopilot.types';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useGetSkillsets} from '@/api/skills';
import useGetStarterPrompts from '@/api/prompts/useGetStarterPrompts';
import ExploreCardBadge from './ExploreCardBadge';
import {ChatInputToken} from '@fluentui-copilot/react-copilot';
import {useAppState} from '@/api/app';
import {ColorScheme} from '@/api/app/app.types';

interface State {
    promptList: PromptSuggestionDescriptor[];
    filteredPromptList: PromptSuggestionDescriptor[];
}

const initialState: State = {
    promptList: [],
    filteredPromptList: [],
};

type PluginBadgeProps = {
    skillsetName: string;
};

const enum ReducerActions {
    SetPromptList,
    SearchPromptList,
}

type Action =
    | {type: ReducerActions.SetPromptList; payload: PromptSuggestionDescriptor[]}
    | {type: ReducerActions.SearchPromptList; payload: string};

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ReducerActions.SetPromptList:
            return {
                ...state,
                promptList: action.payload,
                filteredPromptList: action.payload,
            };
        case ReducerActions.SearchPromptList:
            const filterValue = action.payload.toLowerCase();
            const filteredList = state.promptList.filter(
                (prompt) =>
                    prompt.title.toLowerCase().includes(filterValue) ||
                    prompt.prompt.toLowerCase().includes(filterValue),
            );
            return {
                ...state,
                filteredPromptList: filteredList,
            };
        default:
            return state;
    }
};

export default function ExplorePromptLibrary({
    className,
    filter,
    search = '',
    persona = [],
    plugins = [],
    handleSuggestedPromptClick,
}: ExploreCardProps & {
    filter?: string;
    search?: string;
    persona?: string[];
    plugins?: string[];
    handleSuggestedPromptClick: (prompt: PromptSuggestionDescriptor) => void;
}) {
    const classes = useClasses();
    const {t} = useTranslation('common');

    const {data: prompts, isLoading: ispromptsLoading} = useGetStarterPrompts({
        personas: persona,
        skillsets: plugins,
    });

    const {data: skillsets, isLoading: isskillsetsLoading} = useGetSkillsets();

    const [defaultPrompt, setDefaultPrompt] = useState<PromptSuggestionDescriptor | null>(null);
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (prompts?.suggestedPrompts) {
            const sortedPrompts = [...prompts.suggestedPrompts].sort((a, b) =>
                a.title.localeCompare(b.title),
            );
            dispatch({type: ReducerActions.SetPromptList, payload: sortedPrompts});
        }
    }, [prompts]);

    const PluginBadge = ({skillsetName}: PluginBadgeProps) => {
        const skillset = skillsets?.value?.find((skillset) => skillset.name === skillsetName);
        const plugin = {
            skillset: skillsetName,
            icon: skillset?.icon || '',
            displayName: skillset?.displayName || '',
        };
        return <ExploreCardBadge className={className} plugin={plugin} />;
    };

    useEffect(() => {
        dispatch({type: ReducerActions.SearchPromptList, payload: search || ''});
    }, [search]);

    const wrapInputText = (text: string) => {
        const parts = text.split(/(<[^>]+>)/g);
        return parts.map((part, index) => {
            if (part.startsWith('<') && part.endsWith('>')) {
                return (
                    <ChatInputToken key={index} className={classes.promptInputField}>
                        {part.slice(1, -1)}
                    </ChatInputToken>
                );
            }
            return part;
        });
    };

    const conditionalClass =
        className && className in classes ? classes[className as keyof typeof classes] : '';

    const {colorScheme} = useAppState();
    const isHighContrast = colorScheme === ColorScheme.HighContrast;

    return (
        <div className={conditionalClass} data-testid="PromptLibraryContainer">
            {ispromptsLoading && (
                <div className={classes.loadingbox}>
                    <Spinner labelPosition="below" label={t('homeLibrary.loadingPrompts')} />
                </div>
            )}
            {!ispromptsLoading && state.filteredPromptList.length === 0 && (
                <div className={classes.emptybox}>
                    <QuestionMarkIcon />
                    <Body1 className={classes.notFoundBody}>{t('homeLibrary.noPrompts')}</Body1>
                </div>
            )}
            {!ispromptsLoading && state.filteredPromptList.length > 0 && (
                <ExploreCardList>
                    {state.filteredPromptList?.map((prompt, index) =>
                        prompt ? (
                            <Card
                                className={mergeClasses(
                                    classes.card,
                                    classes.promptCard,
                                    isHighContrast && classes.cardHighContrastHover,
                                )}
                                key={index}
                                focusMode="tab-only"
                                aria-label={`${t('homeLibrary.selectPrompt')}: ${prompt.title}`}
                                data-testid="prompt-card"
                                tabIndex={-1}
                                onClick={() => {
                                    handleSuggestedPromptClick(prompt);
                                    setDefaultPrompt(prompt);
                                }}
                                onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        handleSuggestedPromptClick(prompt);
                                        setDefaultPrompt(prompt);
                                    }
                                }}
                            >
                                <div className={mergeClasses(classes.cardContent)}>
                                    <div className={classes.cardHeader}>
                                        <Caption1Strong
                                            block
                                            truncate
                                            wrap={false}
                                            title={prompt.title}
                                            className={mergeClasses(
                                                classes.cardTitle,
                                                classes.promptCardTitle,
                                            )}
                                            onClick={() => {
                                                setDefaultPrompt(prompt);
                                            }}
                                            onKeyDown={(
                                                event: React.KeyboardEvent<HTMLDivElement>,
                                            ) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault();
                                                    handleSuggestedPromptClick(prompt);
                                                    setDefaultPrompt(prompt);
                                                }
                                            }}
                                            tabIndex={0}
                                        >
                                            {prompt.title}
                                        </Caption1Strong>
                                    </div>
                                    <Caption1 className={classes.cardDescription}>
                                        {wrapInputText(prompt.prompt)}
                                    </Caption1>
                                </div>
                                <div className={mergeClasses(classes.cardFooter)}>
                                    <div className={classes.summary}>
                                        <PluginBadge skillsetName={prompt.skillsetName} />
                                    </div>
                                </div>
                            </Card>
                        ) : (
                            <ExploreCardEmpty key={index} />
                        ),
                    )}
                </ExploreCardList>
            )}
        </div>
    );
}
