import useViewportSize from '@/components/ui/Grid/useViewportSize';
import {Button} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {AriaLabels, ButtonLabels} from '../UsageDashboard/UsageDashboard.constants';
import {ExternalLinkIcon} from '@/components/ui/icons';
import useClasses from './ChangeCapacity.styles';
import {useEffect, useRef} from 'react';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import useGetCapacityParameters from '@/util/useGetCapacityParameters';

/**
 * Props for the ChangeUnitsButtonPalette component.
 *
 * @interface ChangeUnitsButtonPaletteProps
 * @property {boolean} shouldFocusChangeUnitsButton - Determines if the Change Units button should be focused.
 * @property {() => void} onChangeUnitsClick - Callback function to handle the Change Units button click event.
 */
interface ChangeUnitsButtonPaletteProps {
    shouldFocusChangeUnitsButton: boolean;
    onChangeUnitsClick: () => void;
}

export default function ChangeUnitsButtonPalette(props: ChangeUnitsButtonPaletteProps) {
    const {sm: isSmallScreen} = useViewportSize();
    const {t: tAdmin} = useTranslation('admin');
    const buttonSize = isSmallScreen ? 'small' : 'medium';
    const buttonTestId = isSmallScreen ? 'change-units-button-small' : 'change-units-button';

    const classes = useClasses();

    const changeCapacityButtonRef = useRef<HTMLButtonElement>(null);

    const {capacityId} = useWorkspaceState();
    const tenantId = getCurrentTenantId();

    const capacityParameters = useGetCapacityParameters({
        capacityId: capacityId ?? '',
        tenantId: tenantId,
    });

    useEffect(() => {
        if (props.shouldFocusChangeUnitsButton) {
            changeCapacityButtonRef.current?.focus();
        }
    }, [props.shouldFocusChangeUnitsButton]);

    return (
        <>
            <div className={classes.overageButtonWrapper} data-testid="change-units-button-palette">
                <Button
                    size={buttonSize}
                    data-testid={buttonTestId}
                    appearance="primary"
                    onClick={props.onChangeUnitsClick}
                    ref={changeCapacityButtonRef}
                    aria-label={tAdmin(AriaLabels.CHANGE_CAPACITY)}
                >
                    {tAdmin(ButtonLabels.CHANGE_UNITS)}
                </Button>
                <Button
                    as="a"
                    appearance="secondary"
                    size={buttonSize}
                    href={capacityParameters?.links?.billingTabUrl?.url}
                    icon={<ExternalLinkIcon />}
                    iconPosition="before"
                    aria-label={tAdmin(AriaLabels.VIEW_AZURE_RESOURCES)}
                    target="_blank"
                >
                    {tAdmin(ButtonLabels.VIEW_AZURE_BILLING)}
                </Button>
            </div>
        </>
    );
}
