import React, {useEffect} from 'react';
import {Text, mergeClasses} from '@fluentui/react-components';
import {GridContainer, GridItem} from '@/components/ui/Grid';
import useClasses from './Dev.styles';
import MockServiceWorker from './MockServiceWorker';

function Dev() {
    const classes = useClasses();
    return (
        <GridContainer className={classes.root}>
            <div className={classes.row}>
                <div>
                    <h1>Development sandbox</h1>
                    <Text>
                        This is a developer sandbox that can be used to build or test new utilities
                        and portal features. This page should only be accessible locally or in dev.
                    </Text>
                </div>
            </div>
            <MockServiceWorker />
        </GridContainer>
    );
}

export default Dev;
