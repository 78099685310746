import ConsumptionModelHoverCard from './ConsumptionModelHoverCard';
import OverageHoverCard from './OverageHoverCard';

export const createHoverCard = (type: 'v2' | 'overage', props: any): JSX.Element => {
    switch (type) {
        case 'v2':
            return <ConsumptionModelHoverCard {...props}></ConsumptionModelHoverCard>;
        case 'overage':
            return <OverageHoverCard {...props}></OverageHoverCard>;
    }
};
