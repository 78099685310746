import {Button, mergeClasses} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {SettingsScope, SkillsetAuthType} from '@/api/skills';
import {useTranslation} from 'react-i18next';
import {useTenantScopePluginUpload, useUserScopePluginUpload} from '@/api/tenant';
import {SkillsetDescriptor} from '@/api/skills';
import {DeleteIcon} from '@/components/ui/icons';
import {has} from 'lodash-es';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
interface SkillsetSetupControlsProps {
    authType: string | undefined;
    scope: SettingsScope;
    onConnectAuth: () => void;
    onSaveSetup: () => void;
    onCancel: () => void;
    onReturn: () => void;
    isSetUp: boolean;
    hasExistingConfigs: boolean;
    isPlugin: boolean;
    hasUserSettingValues: boolean;
    onDelete: () => void;
    skillsetDescriptor: SkillsetDescriptor | null;
}

export default function SkillsetSetupControls({
    authType,
    scope,
    onConnectAuth,
    onSaveSetup,
    onCancel,
    onReturn,
    isSetUp,
    hasExistingConfigs,
    isPlugin,
    hasUserSettingValues,
    // only for custom plugins and when not in setup mode
    onDelete,
    skillsetDescriptor,
}: SkillsetSetupControlsProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const {isUserScopeUploadAllowed} = useUserScopePluginUpload();
    const {isTenantScopeUploadAllowed} = useTenantScopePluginUpload();

    const allowPluginManagement =
        skillsetDescriptor !== null && isTenantScopeUploadAllowed(skillsetDescriptor ?? undefined);

    // Always show buttons, but disable "save" button if user has not changed current settings values.
    // In the case of non-existant configurations or missing settings, still show buttons for consistency.
    const shouldEnableSave = hasUserSettingValues && isUserScopeUploadAllowed();

    // Enable connect when existing settings are present and user is allowed to upload settings.
    const shouldEnableConnect =
        (hasUserSettingValues || hasExistingConfigs) && isUserScopeUploadAllowed();

    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    return (
        <div className={mergeClasses(classes.buttons, isSetUp && isPlugin && classes.setupButtons)}>
            <div>
                {isPlugin && !isSetUp && allowPluginManagement ? (
                    <Button
                        onClick={onDelete}
                        className={classes.deleteButton}
                        appearance="subtle"
                        size="small"
                        icon={<DeleteIcon />}
                    >
                        {t('common.Delete')}
                    </Button>
                ) : (
                    <div style={{width: '1px'}} />
                )}
            </div>
            <div>
                {isSetUp && isPlugin && (
                    <Button
                        className={classes.cancelButton}
                        appearance="subtle"
                        size="medium"
                        onClick={onReturn}
                    >
                        {t('Admin.DoThisLater')}
                    </Button>
                )}
                {!(isSetUp && isPlugin) && (
                    <Button
                        className={classes.cancelButton}
                        appearance="subtle"
                        size="medium"
                        onClick={shouldEnableSave ? onCancel : onReturn} // Show confirmation of cancel only if user is able to manage plugins.
                    >
                        {t('common.Cancel')}
                    </Button>
                )}
                {authType == SkillsetAuthType.OAuthAuthorizationCodeFlow && (
                    <Button
                        className={classes.saveButton}
                        appearance="primary"
                        type="submit"
                        onClick={onConnectAuth}
                        disabled={!shouldEnableConnect}
                    >
                        {t('Admin.Connect')}
                    </Button>
                )}
                {(authType !== SkillsetAuthType.OAuthAuthorizationCodeFlow ||
                    scope === SettingsScope.Tenant ||
                    (isWorkspacesTestingEnabled ? scope === SettingsScope.Workspace : false)) && (
                    <Button
                        className={classes.saveButton}
                        appearance="primary"
                        type="submit"
                        onClick={onSaveSetup}
                        disabled={!shouldEnableSave}
                    >
                        {isSetUp ? t('common.Setup') : t('common.Save')}
                    </Button>
                )}
            </div>
        </div>
    );
}
