import {useQuery} from '@tanstack/react-query';
import {PageResponse} from '../api';
import useFetch from '../useFetch';
import {SettingsScope, SkillsetConfiguration, SkillsetRequest} from './skills.types';
import {useFeatureFlag} from '../user';
import MedeinaFeatures from '@/util/features';

export default function useGetSkillsetConfigurations({scope}: SkillsetRequest) {
    const {customFetch} = useFetch();
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    return useQuery({
        queryKey: ['skillsets', 'configurations'],
        queryFn: async () => {
            return await customFetch<PageResponse<SkillsetConfiguration>>(
                `/skillsets/configurations?scope=${
                    scope ??
                    (isWorkspacesTestingEnabled ? SettingsScope.UserWorkspace : SettingsScope.User)
                }`,
                {
                    method: 'GET',
                },
            );
        },
    });
}
