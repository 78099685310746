import React, {useEffect} from 'react';
import {signOut} from '@/util/msal/authConfig';
import {msalInstance} from '@/util/msal/authConfig';

function Signout() {
    const handleSignout = async () => {
        await signOut(msalInstance);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        sessionStorage.clear();
        localStorage.clear();
        debugger;
        handleSignout();
    }, []);
    return <div>Signing out</div>;
}

export default Signout;
