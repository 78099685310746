import {useState, useEffect, useMemo, useRef} from 'react';
import useClasses from '../ManageWorkspaces.styles';
import {
    TableCellLayout,
    Menu,
    MenuButton,
    MenuTrigger,
    MenuGroup,
    MenuGroupHeader,
    MenuDivider,
    MenuPopover,
    MenuList,
    MenuItem,
    Link,
    useId,
    Toast,
    ToastTitle,
    useToastController,
    Toaster,
} from '@fluentui/react-components';
import {AddIcon, OpenIcon, CheckmarkIcon} from '@/components/ui/icons';
import {Capacity} from '@/api/capacities/capacities.types';
import {Workspace} from '@/api/workspaces';
import {useGetCapacityByName, useGetCapacities} from '@/api/capacities';
import {useTranslation} from 'react-i18next';
import MedeinaVariables from '@/util/variables';
import DisconnectCapacityDialog from '../DisconnectCapacityDialog';
import CreateCapacity from '@/components/CreateCapacity';
import usePutWorkspace from '@/api/workspaces/usePutWorkspace';
import WorkspacesLoadingOverlay from '../WorkspacesLoadingOverlay';

interface CapacityColumnProps {
    workspace: Workspace;
    isWorkspacesDataFetched: boolean;
    workspaces: Workspace[];
    disconnectedWorkspaces: Workspace[];
    setDisconnectedWorkspaces: (workspace: Workspace[]) => void;
    disconnectedCapacities: Capacity[];
    setDisconnectedCapacities: (capacity: Capacity[]) => void;
}

export const CapacityColumn: React.FC<CapacityColumnProps> = ({
    workspace,
    isWorkspacesDataFetched,
    workspaces,
    disconnectedWorkspaces,
    setDisconnectedWorkspaces,
    disconnectedCapacities,
    setDisconnectedCapacities,
}) => {
    const classes = useClasses();
    const {t} = useTranslation('admin');
    // API call to update workspace capacity linkage
    const putWorkspace = usePutWorkspace();
    const {data: capacityData} = useGetCapacityByName(workspace?.capacity?.referenceName || '', {
        enabled: isWorkspacesDataFetched,
        refetchOnMount: true,
    });
    const [overlay, setOverlay] = useState<boolean>(false);
    const {data: data} = useGetCapacities({});
    const capacities: Capacity[] = useMemo(
        () =>
            (data?.value || []).filter(
                (capacity) =>
                    !(workspaces || []).find(
                        (workspace) => workspace.capacity?.referenceName === capacity.name,
                    ),
            ) || [],
        [data],
    );

    let capacityDisplayName: string | null = capacityData?.name || null;
    if (
        disconnectedWorkspaces.some(
            (disconnectedWorkspace) => disconnectedWorkspace.name === workspace.name,
        )
    ) {
        capacityDisplayName = null;
    }
    // this should work when backend integration happens
    const [selectedCapacity, setSelectedCapacity] = useState<string | null>(capacityDisplayName);
    // success toast
    const toasterId = useId('toaster');
    const {dispatchToast} = useToastController(toasterId);
    const notify = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{t('ManageWorkspaces.CapacitySetSuccess')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'success'},
        );
    };
    // fail toast
    const notifyFail = () => {
        dispatchToast(
            <Toast>
                <ToastTitle>{t('ManageWorkspaces.CapacitySetFailure')}</ToastTitle>
            </Toast>,
            {position: 'bottom', intent: 'error'},
        );
    };
    useEffect(() => {
        setSelectedCapacity(capacityDisplayName);
    }, [capacityDisplayName]);
    // check if in disconnected worksapces array and set accordingly

    const [tempSelectedCapacity, setTempSelectedCapacity] = useState<string | null>(null);
    const [attachedWorkspace, setAttachedWorkspace] = useState<Workspace>();

    const [isDisconnectCapacityDialogOpen, setDisconnectCapacityDialogOpen] =
        useState<boolean>(false);

    const handleMenuItemClick = (capacity: Capacity) => {
        if (capacity.name === selectedCapacity) {
            return;
        }
        const tempCapacityDisplayName = capacity.name;
        setTempSelectedCapacity(capacity.name);
        // check if capacity is part of disconnected capacities
        const isDisconnected = disconnectedCapacities.some(
            (disconnectedCapacity) => disconnectedCapacity.name === capacity.name,
        );
        let tempDisconnectedCapacities: Capacity[] = [...disconnectedCapacities];
        // the capacity chosen is already attached to another workspace
        if (!isDisconnected) {
            // find the corresponding workspace to the selected capacity that is to be disconnected
            const tempAttachedWorkspace = workspaces.find(
                (workspace: Workspace) => workspace.capacity?.referenceName === capacity.name,
            );
            // set attached workspace for disconnect capacity dialog
            setAttachedWorkspace(tempAttachedWorkspace);
            setDisconnectCapacityDialogOpen(true);
        }
        // capacity was available and not attached to any workspace
        else {
            // check if there was a valid original selected capacity
            if (selectedCapacity) {
                // add old capacity to disconnected capacities
                const originalCapacity = capacities.find(
                    (capacity) => capacity.name === selectedCapacity,
                );
                if (originalCapacity) {
                    tempDisconnectedCapacities.push(originalCapacity);
                }
            }
            // set capacity to new capacity
            setOverlay(true);
            // update workspace with new capacity
            putWorkspace.mutate(
                {
                    request: {
                        ...workspace,
                        name: workspace.name,
                        capacity: {
                            type: 'CapacityReference',
                            referenceName: capacity.name,
                        },
                    },
                    targetWorkspace: workspace,
                },
                {
                    onSuccess: () => {
                        capacityDisplayName = tempCapacityDisplayName;
                        setSelectedCapacity(tempCapacityDisplayName);
                        setOverlay(false);
                        notify();
                    },
                    onError: () => {
                        setOverlay(false);
                        notifyFail();
                    },
                },
            );
            // remove capacity from disconnected capacities
            tempDisconnectedCapacities = tempDisconnectedCapacities.filter(
                (c) => c.name !== capacity.name,
            );
            setDisconnectedCapacities(tempDisconnectedCapacities);
            // remove workspace from disconnected workspaces
            const newDisconnectedWorkspaces = disconnectedWorkspaces.filter(
                (disconnectedWorkspace) => disconnectedWorkspace.name !== workspace.name,
            );
            setDisconnectedWorkspaces(newDisconnectedWorkspaces);
        }
    };

    const handleDisconnectConfirm = () => {
        setOverlay(true);
        if (tempSelectedCapacity) {
            // add original capacity to disconnected capacities
            const originalCapacity = capacities.find(
                (capacity) => capacity.name === selectedCapacity,
            );
            if (originalCapacity) {
                setDisconnectedCapacities([...disconnectedCapacities, originalCapacity]);
            }
        }
        if (attachedWorkspace) {
            // add attached workspace to disconnected workspaces and remove current workspace from disconnected workspaces
            const newDisconnectedWorkspaces = disconnectedWorkspaces.filter(
                (disconnectedWorkspace) => disconnectedWorkspace.name !== workspace.name,
            );
            setDisconnectedWorkspaces([...newDisconnectedWorkspaces, attachedWorkspace]);
        }
        // disconnect capacity from attached workspace
        putWorkspace.mutate(
            {
                request: {
                    ...attachedWorkspace,
                    name: attachedWorkspace?.name || '',
                    capacity: null,
                },
                targetWorkspace: attachedWorkspace,
            },
            {
                onSuccess: (data) => {
                    putWorkspace.mutate(
                        {
                            request: {
                                ...workspace,
                                name: workspace.name,
                                capacity: {
                                    type: 'CapacityReference',
                                    referenceName: tempSelectedCapacity || '',
                                },
                            },
                            targetWorkspace: workspace,
                        },
                        {
                            onSuccess: () => {
                                setSelectedCapacity((prev) => tempSelectedCapacity);
                                setOverlay(false);
                                notify();
                            },
                            onError: () => {
                                setOverlay(false);
                                notifyFail();
                            },
                        },
                    );
                    setDisconnectCapacityDialogOpen(false);
                },
                onError: () => {
                    setOverlay(false);
                },
            },
        );
        setDisconnectCapacityDialogOpen(false);
    };

    // new capacity button ref
    const newCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);

    const [isWorkspaceDisconnected, setIsWorkspaceDisconnected] = useState<boolean>(false);
    useEffect(() => {
        const checkIfWorkspaceDisconnected = disconnectedWorkspaces.some(
            (disconnectedWorkspace) => disconnectedWorkspace.name === workspace.name,
        );
        setIsWorkspaceDisconnected(checkIfWorkspaceDisconnected);
    }, [disconnectedWorkspaces, workspace.name]);
    return (
        <>
            <TableCellLayout>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                        {isWorkspaceDisconnected ? (
                            <MenuButton
                                data-testid="capacity-menu-button"
                                className={classes.errorMenuButton}
                            >
                                {t('ManageWorkspaces.MenuButtons.NoCapacitySelected')}
                            </MenuButton>
                        ) : (
                            <MenuButton appearance="transparent" data-testid="capacity-menu-button">
                                {selectedCapacity}
                            </MenuButton>
                        )}
                    </MenuTrigger>
                    <MenuPopover>
                        <MenuList>
                            <MenuGroup>
                                <MenuGroupHeader>
                                    {t('ManageWorkspaces.MenuButtons.CapacitiesYouOwn')}
                                </MenuGroupHeader>
                                {capacities.map((capacity: Capacity, index: number) => {
                                    const isDisconnected = disconnectedCapacities.some(
                                        (disconnectedCapacity) =>
                                            disconnectedCapacity.name === capacity.name,
                                    );
                                    return (
                                        <MenuItem
                                            key={index}
                                            className={
                                                isDisconnected ? classes.errorMenuButton : ''
                                            }
                                            icon={
                                                capacity.name === selectedCapacity ? (
                                                    <CheckmarkIcon />
                                                ) : (
                                                    <div className={classes.fillerIcon} />
                                                )
                                            }
                                            onClick={() => handleMenuItemClick(capacity)}
                                        >
                                            {isDisconnected
                                                ? capacity.name +
                                                  ' ' +
                                                  t(
                                                      'ManageWorkspaces.MenuButtons.NotConnectedError',
                                                  )
                                                : capacity.name}
                                        </MenuItem>
                                    );
                                })}
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<AddIcon />}
                                    data-testid="new-capacity-menu-item"
                                    onClick={() => {
                                        setIsCreateCapacityDialogOpen(true);
                                    }}
                                >
                                    {t('ManageWorkspaces.MenuButtons.NewCapacity')}
                                </MenuItem>
                            </MenuGroup>
                            <MenuDivider />
                            <MenuGroup>
                                <MenuItem
                                    icon={<OpenIcon />}
                                    data-testid="manage-capacities-menu-item"
                                >
                                    <Link
                                        href={MedeinaVariables.ManageCapacityAzureUri}
                                        target="_blank"
                                        aria-label={t(
                                            'ManageWorkspaces.ariaLabel.ManageCapacityAzureUri',
                                        )}
                                        appearance="subtle"
                                    >
                                        {t('ManageWorkspaces.MenuButtons.ManageCapacitiesAzure')}
                                    </Link>
                                </MenuItem>
                            </MenuGroup>
                        </MenuList>
                    </MenuPopover>
                </Menu>
                <DisconnectCapacityDialog
                    title={t('ManageWorkspaces.DisconnectCapacityDialog.Title', {
                        0: tempSelectedCapacity,
                    })}
                    description={t('ManageWorkspaces.DisconnectCapacityDialog.Description', {
                        0: attachedWorkspace?.name,
                    })}
                    switchButtonLabel={t(
                        'ManageWorkspaces.DisconnectCapacityDialog.DisconnectButton',
                    )}
                    isDisconnectCapacityDialogOpen={isDisconnectCapacityDialogOpen}
                    newCapacityName={tempSelectedCapacity || ''}
                    workspaceName={attachedWorkspace?.name || ''}
                    onClose={() => {
                        setDisconnectCapacityDialogOpen(false);
                    }}
                    onConfirm={handleDisconnectConfirm}
                />
                <CreateCapacity
                    isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                    onCreateCapacityDialogClose={() => {
                        setIsCreateCapacityDialogOpen(false);
                        newCapacityButtonRef.current?.focus();
                    }}
                    hideWorkspaceSelectField={false}
                />
            </TableCellLayout>
            {overlay && <WorkspacesLoadingOverlay label={t('ManageWorkspaces.SettingCapacity')} />}
            <Toaster toasterId={toasterId} />
        </>
    );
};
