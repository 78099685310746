import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    Button,
    DialogActions,
    Tooltip,
    ToolbarButton,
} from '@fluentui/react-components';
import {DismissIcon, RerunIcon} from '@/components/ui/icons';
import {useCreateEvaluation, useCreateEvaluationAndRerunPrompts} from '@/api/evaluations';
import useClasses from './RerunPromptDialog.styles';
import {RerunPromptDialogProps} from './RerunPromptDialog.types';
import {useEffect, useRef, useState} from 'react';
import {useErrorMessages, useErrorMessagesWorkspaces} from '@/api/errors';
export * from './RerunPromptDialog.types';
import {useTranslation} from 'react-i18next';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export default function RerunPromptDialog({
    sessionId,
    promptId,
    open,
    isLastPrompt = false,
    onClose = () => null,
    setErrorMessage = () => null,
    label,
    className,
}: RerunPromptDialogProps) {
    const classes = useClasses();
    const {t} = useTranslation('session');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {getErrorMessage} = useErrorMessages();
    const {getErrorMessageWorkspaces} = useErrorMessagesWorkspaces();
    const {mutate: createEvaluation, isError, error} = useCreateEvaluation();
    const {
        mutate: createEvaluationAndRerunPrompts,
        isError: isAllEvalError,
        error: allEvalError,
    } = useCreateEvaluationAndRerunPrompts();

    // Focus the primary button by default.
    // This is only used for focusing, not managing dialog `open` state.
    const [isOpen, setIsOpen] = useState<boolean>(open ?? false);
    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        // Focus on first visible render.
        if (isOpen && buttonRef.current) {
            buttonRef.current.focus();
        }
    }, [isOpen, buttonRef.current]);

    useEffect(() => {
        if (isError) {
            if (isWorkspacesTestingEnabled) {
                var message = getErrorMessageWorkspaces((error as any).response.status);
                setErrorMessage(message);
            } else {
                var message = getErrorMessage((error as any).response.status);
                setErrorMessage(message);
            }
        } else if (allEvalError) {
            if (isWorkspacesTestingEnabled) {
                var message = getErrorMessageWorkspaces((allEvalError as any).response.status);
                setErrorMessage(message);
            } else {
                var message = getErrorMessage((allEvalError as any).response.status);
                setErrorMessage(message);
            }
        }
    }, [isError, isAllEvalError]);

    // If this is the last prompt, skip the dialog.
    if (isLastPrompt) {
        return (
            <Tooltip content={t('ReRunPromptTooltip')} relationship="label" positioning="after">
                <ToolbarButton
                    appearance="subtle"
                    icon={<RerunIcon />}
                    onClick={() =>
                        createEvaluation({
                            sessionId,
                            promptId,
                        })
                    }
                    className={className}
                >
                    {label}
                </ToolbarButton>
            </Tooltip>
        );
    }

    return (
        <Dialog open={open} onOpenChange={(event, data) => setIsOpen(data.open)}>
            {open === undefined ? (
                <DialogTrigger disableButtonEnhancement>
                    <Tooltip
                        content={t('ReRunPromptTooltip')}
                        relationship="label"
                        positioning="after"
                    >
                        <ToolbarButton
                            appearance="subtle"
                            data-testid="rerun-prompt-button"
                            icon={<RerunIcon />}
                            className={className}
                        >
                            {label}
                        </ToolbarButton>
                    </Tooltip>
                </DialogTrigger>
            ) : (
                <></>
            )}
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<DismissIcon />}
                                    onClick={onClose}
                                />
                            </DialogTrigger>
                        }
                    >
                        {t('ReRunPromptDialogTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <p>{t('ReRunPromptDialogContent')}</p>
                    </DialogContent>
                    <DialogActions position="start">
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                data-testid="yes-rerun-button"
                                ref={buttonRef}
                                appearance="primary"
                                className={classes.actionButton}
                                onClick={() => {
                                    createEvaluationAndRerunPrompts({
                                        sessionId,
                                        promptId,
                                    });
                                    onClose?.();
                                }}
                            >
                                {t('ReRunPromptYesButton')}
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="secondary"
                                className={classes.actionButton}
                                onClick={() => {
                                    createEvaluation({
                                        sessionId,
                                        promptId,
                                    });
                                    onClose?.();
                                }}
                            >
                                {t('ReRunPromptNoButton')}
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
