import {
    Button,
    Popover,
    PopoverSurface,
    PopoverTrigger,
    PopoverProps,
    Dialog,
    DialogTrigger,
    Tooltip,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    DialogActions,
} from '@fluentui/react-components';
import {DismissIcon, SendIcon, LinkMultipleIcon} from '@/components/ui/icons';
import useClasses from './ShareSessionDialog.styles';
import {ShareSessionDialogProps} from './ShareSessionDialog.types';
import {SessionSharingMailRequest, useShareSession, useSessionCopyLink} from '@/api/sessions';
import {useCallback, useEffect, useState} from 'react';
import {LinkIcon} from '@/components/ui/icons';
import {useTourable} from '@/components/ui/Tour/useTourable';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import useShareSessionEmail from '@/api/sessions/useShareSessionEmail';
import PeoplePicker from './PeoplePicker';
import {UserProfile} from '@/api/user/user.types';
import {useMsal} from '@azure/msal-react';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';
import {useTranslation} from 'react-i18next';
import LinkCopiedDialog from '../promptbooks/LinkCopiedDialog';

export * from './ShareSessionDialog.types';
const MAX_LENGTH_DISPLAYED_ON_EMAIL = 50;

export default function ShareSessionDialog({
    sessionId,
    sessionName,
    isUserSessionOwner,
    onClose = () => null,
    onSuccess = () => null,
    open,
}: ShareSessionDialogProps) {
    const {t} = useTranslation(['session']); // Initialize the useTranslation hook
    const tourRef = useTourable({id: 'shareButton'});
    const classes = useClasses();
    const [shareUrl, setShareUrl] = useState<string>('');
    const {mutate: shareSession} = useShareSession({sessionId});
    const {mutate: trackEvent} = useTrackEvent();
    const {mutate: sendEmail, isSuccess, isError} = useShareSessionEmail({sessionId});
    const {instance} = useMsal();
    const {showLinkCopiedModel} = useSessionCopyLink();

    // Link to share the session with another user. Should point to the sessions page.
    const getShareUrlBase = useCallback(() => {
        return `${window.location.origin}/sessions/${sessionId}`;
    }, [sessionId]);

    const handleOpenChange: PopoverProps['onOpenChange'] = useCallback(
        (e: any, data: {open: any}) => {
            generateShareUrl(sessionId);
        },
        [setShareUrl, shareSession, sessionId],
    );

    const generateShareUrl = (sessionId: string) => {
        shareSession(
            {sessionId},
            {
                onSuccess: ({token}) => {
                    if (token && typeof window !== 'undefined') {
                        setShareUrl(`${window.location.origin}/sessions/${sessionId}?st=${token}`);
                    }
                },
            },
        );
    };

    const [isPopoverVisible, setPopoverVisible] = useState(false);

    const handleCopyToClipboard = useCallback(() => {
        if (shareUrl && typeof window !== 'undefined') {
            window.navigator.clipboard.writeText(shareUrl);
            trackEvent({
                name: MedeinaTelemetryEvent.Sessions.ShareSession,
                eventType: MedeinaEvent.ActionEvent,
            });
        }
        setPopoverVisible(true);

        // Set a timer to hide the popover after 5 seconds
        setTimeout(() => {
            setPopoverVisible(false);
        }, 5000);
    }, [shareUrl]);

    useEffect(() => {
        if (shareUrl) {
            handleCopyToClipboard();
        }
    }, [shareUrl]);

    useEffect(() => {
        if (showLinkCopiedModel) {
            generateShareUrl(sessionId);
        }
    }, [showLinkCopiedModel]);

    const PopoverContent = () => {
        return (
            <div className={classes.sharePopoverContainer} aria-live="polite" tabIndex={0}>
                <LinkIcon className={classes.sharePopoverIcon} />
                <div className={classes.sharePopoverTextContainer} aria-live="polite" tabIndex={0}>
                    {t('ShareSessionDialogLinkCopied')} {/* Replace with localized string */}
                </div>
            </div>
        );
    };

    const [userShareDetails, setUserShareDetails] = useState<UserProfile[]>([]);

    // Callback function to receive data from the child component
    const receiveDataFromPeoplePicker = (data: UserProfile[]) => {
        setUserShareDetails(data);
    };
    const handleClose = () => {
        setUserShareDetails([]);
        onClose();
    };

    const handleSendEmail = () => {
        if (userShareDetails.length > 0) {
            const userRequests = userShareDetails.map((profile) => ({
                userId: profile.id,
                role: 'viewer',
                global: false,
                Email: profile.mail,
            }));

            const emailRequest: SessionSharingMailRequest = {
                sessionId: sessionId,
                baseUrl: getShareUrlBase(),
                investigationName:
                    sessionName.length > MAX_LENGTH_DISPLAYED_ON_EMAIL
                        ? `${sessionName.slice(0, MAX_LENGTH_DISPLAYED_ON_EMAIL)}...`
                        : sessionName,
                senderName: instance.getActiveAccount()?.name!,
                userRequest: userRequests,
            };

            sendEmail(emailRequest);
            setUserShareDetails([]);
        }
    };

    //show link copied model without people picker
    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(_, data) => {
                    if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                        handleClose();
                    }
                }}
                data-testid="share-session-dialog"
            >
                <DialogSurface className={classes.root}>
                    <DialogBody>
                        {!showLinkCopiedModel && (
                            <>
                                <DialogTitle
                                    data-testid="share-session-title"
                                    className={classes.header}
                                    action={
                                        <DialogTrigger action="close">
                                            <Button
                                                appearance="subtle"
                                                aria-label="close"
                                                icon={<DismissIcon />}
                                                onClick={handleClose}
                                            />
                                        </DialogTrigger>
                                    }
                                >
                                    <Tooltip
                                        content={t('_ShareSessionDialogSessionName', {
                                            0: sessionName,
                                        })}
                                        relationship="description"
                                    >
                                        <div className={classes.sessionNameDisplay} tabIndex={0}>
                                            {t('ShareSessionDialogSessionName', {0: sessionName})}
                                        </div>
                                    </Tooltip>
                                </DialogTitle>
                                <DialogContent className={classes.content}>
                                    <div>
                                        <PeoplePicker
                                            data-testid="share-session-peoplepicker"
                                            className={classes.peoplepicker}
                                            sendDataToParent={receiveDataFromPeoplePicker}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions position="start">
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button
                                            data-testid="share-session-send-button"
                                            icon={<SendIcon />}
                                            className={classes.actionButton}
                                            appearance="primary"
                                            onClick={handleSendEmail}
                                            disabled={userShareDetails.length === 0}
                                        >
                                            {t('ShareSessionDialogSendButton')}
                                        </Button>
                                    </DialogTrigger>
                                    <Popover
                                        onOpenChange={handleOpenChange}
                                        open={isPopoverVisible}
                                        trapFocus
                                    >
                                        <PopoverTrigger disableButtonEnhancement>
                                            <Button
                                                data-testid="share-session-copy-link-button"
                                                icon={<LinkMultipleIcon />}
                                                className={classes.actionButton}
                                                appearance="secondary"
                                                onClick={handleCopyToClipboard}
                                            >
                                                {t('ShareSessionDialogCopyLinkButton')}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverSurface>
                                            <PopoverContent />
                                        </PopoverSurface>
                                    </Popover>
                                </DialogActions>
                            </>
                        )}
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {isSuccess && !showLinkCopiedModel && (
                <ToastNotification
                    intent={Intent.Success}
                    message={t('ShareSessionDialogEmailSentMessage')}
                    sessionId={''}
                    timeout={2000}
                ></ToastNotification>
            )}
            {isError && !showLinkCopiedModel && (
                <ToastNotification
                    intent={Intent.Error}
                    message={t('ShareSessionDialogEmailFailedMessage')}
                    sessionId={''}
                    timeout={2000}
                ></ToastNotification>
            )}
            {showLinkCopiedModel && open && (
                <LinkCopiedDialog open={showLinkCopiedModel} onClose={onClose} />
            )}
        </>
    );
}
