import useClasses from './Overview.styles';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useWorkspaceState} from '../workspaces/workspaceStateProvider';
import {Button, Link} from '@fluentui/react-components';
import {formatLocaleDate} from '@/util/locale';
import MedeinaVariables from '@/util/variables';
import useGetEapExpired from '@/api/app/useGetEapExpired';
import {useGetCapacityByName} from '@/api/capacities';
import {EapSwitchConfirmation} from '.';

interface OverviewProps {
    onEarlyAccessEnd: () => void;
    shouldFocusOnSwitchCapacityButton: boolean;
    onSwitchCapacityButtonBlur: () => void;
}

export default function Overview(props: OverviewProps) {
    const {capacityName, isProviderLoaded, isWorkspaceCapacityEAP} = useWorkspaceState();

    const classes = useClasses();
    const {t} = useTranslation('admin');
    const switchCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isEapSwitchConfirmationOpen, setIsEapSwitchConfirmationOpen] = useState(false);

    const {
        data: expiryDateData,
        isFetched: expiryDateFetched,
        isSuccess: expiryDateSuccess,
        isError: expiryDateError,
    } = useGetEapExpired();

    const {
        data: capacity,
        isFetched: isGetCapacityNameFetched,
        isFetching: isGetCapacityNameFetching,
    } = useGetCapacityByName(capacityName || '', {
        enabled: !!capacityName,
        refetchOnMount: true,
    });

    const expiryDateTime = useMemo(() => {
        if (expiryDateSuccess && !!expiryDateData?.expiryDate) {
            // If the value is captured from the get auth expires At date
            try {
                return formatLocaleDate(expiryDateData?.expiryDate, 'MMM dd yyyy, hh:mm a');
            } catch {
                return '';
            }
        } else {
            // Expires at date associated with the capacity
            if (capacity?.properties?.expiresAt) {
                // If the value is available in the EAP capacity
                return formatLocaleDate(capacity.properties.expiresAt, 'MMM dd yyyy, hh:mm a');
            } else {
                return '';
            }
        }
    }, [expiryDateFetched, isProviderLoaded, isWorkspaceCapacityEAP, isGetCapacityNameFetching]);

    useEffect(() => {
        if (props.shouldFocusOnSwitchCapacityButton && switchCapacityButtonRef.current) {
            switchCapacityButtonRef.current.focus();
        }
    }, [props.shouldFocusOnSwitchCapacityButton]);

    return (
        <>
            <div>
                <div className={classes.subtitle}>{t('ownerSettings.overview.SectionHeading')}</div>
                <div className={classes.capacityInfo}>
                    <div>
                        <span>{t('ownerSettings.overview.CapacityInfoText')}</span>
                        <span>{capacityName}</span>
                    </div>
                    {/** If there's an expiryDate and there's no capacity ID the tenant has a fake capacity
                     * that will expire at some point and the user should be aware of it.
                     * For ISSVs, MSSPs, Internal teams, etc. the expiryDate will be null and we don't want to show this section
                     */}
                    {expiryDateTime && !capacity?.id && (
                        <div>
                            <span>{t('ownerSettings.overview.ExpiryDateInfoText')}</span>
                            <span>{expiryDateTime}</span>
                        </div>
                    )}
                    <Link href={MedeinaVariables.OverviewLearnMoreUri} target="_blank">
                        {t('ownerSettings.overview.LearnMoreLink')}
                    </Link>
                </div>
                <div>
                    <Button
                        className={classes.switchCapacityBtn}
                        ref={switchCapacityButtonRef}
                        onClick={() => setIsEapSwitchConfirmationOpen(true)}
                        onBlur={props.onSwitchCapacityButtonBlur}
                    >
                        {t('ownerSettings.azureResourceLinks.SwitchCapacityButtonText')}
                    </Button>
                    <EapSwitchConfirmation
                        isEapSwitchCapacityOpen={isEapSwitchConfirmationOpen}
                        onConfirmEndEarlyAccess={() => {
                            setIsEapSwitchConfirmationOpen(false);
                            props.onEarlyAccessEnd();
                        }}
                        onClose={() => {
                            setIsEapSwitchConfirmationOpen(false);
                            switchCapacityButtonRef.current?.focus();
                        }}
                    ></EapSwitchConfirmation>
                </div>
            </div>
        </>
    );
}
