import {useEffect, useState} from 'react';
import {useMsal} from '@azure/msal-react';
import {
    Avatar,
    Button,
    DrawerBody,
    Drawer,
    Card,
    CardHeader,
    CardFooter,
    Tooltip,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {NavMenuTab} from './NavMenuTab/NavMenuTab';
import {NavMenuTabList} from './NavMenuTab/NavMenuTabList';
import SignInSignOutButton from '@/components/SignInSignOutButton';
import {NavigationIcon} from '@/components/ui/icons';
import useClasses from './Menu.styles';
import UserSettingsModal from './UserSettingsModal';
import TenantSwitcher from './TenantSwitcher';
import MedeinaFeatures from '@/util/features';
import {useTranslation} from 'react-i18next';
import {useFeatureFlag} from '@/api/user';
import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import useIsOwner from '@/api/app/useGetIsOwner';
// import workspace context
export default function Menu() {
    // styling
    const classes = useClasses();
    const {t} = useTranslation('common');
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    const restoreFocusTargetAttribute = useRestoreFocusTarget();
    // FeatureFlags
    const isRBACEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisRBAC);
    const isFidelisGAEnabled = useFeatureFlag(MedeinaFeatures.LaunchExperienceFidelisGA);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const userPluginManagementAllowed = useFeatureFlag(
        MedeinaFeatures.IsUserPluginManagementAllowedByAdminEnabled,
    );

    // state
    const {accounts, instance} = useMsal();
    // navigation menu
    const [open, setOpen] = useState(false);
    // settings modal
    const [settingsOpen, setSettingsOpen] = useState(false);
    const {data: userAuthInfo, isSuccess} = useGetUserInfo();
    const {mutate: trackEvent} = useTrackEvent();
    const {data: tenantInfo} = useGetTenantInfo();

    // toggle the visibility of the nav
    const toggleOpen = () => setOpen(!open);

    const {isOwner, isAnyOwner} = useIsOwner();

    let tenantID = null;
    if (instance) {
        tenantID = instance.getActiveAccount()?.tenantId;
    }

    let canShowNavMenuItems = tenantInfo?.freRequirements?.freRequirementsMet ?? false;
    const canShowConsumptionModelItems: boolean =
        !!isFidelisGAEnabled && (tenantInfo?.freRequirements?.freRequirementsMet ?? false);

    const settingsName = t('MenuLinkSettings');

    useEffect(() => {
        if (isSuccess) {
            trackEvent({
                name: MedeinaTelemetryEvent.UserInteractionData.UserInfo,
                customProperties: {
                    isAdmin: userAuthInfo?.isAdmin,
                    isGlobalAdmin: userAuthInfo?.isGlobalAdmin,
                    isOperator: userAuthInfo?.isOperator,
                },
                eventType: MedeinaEvent.UserInteractionData,
            });
        }
    }, [isSuccess]);

    return (
        <>
            <Drawer
                type="overlay"
                separator
                open={open}
                onOpenChange={(_, {open}: any) => toggleOpen()}
                className={classes.root}
                modalType="modal"
                {...restoreFocusSourceAttribute}
            >
                <div className={classes.menuHeader}>
                    <div className={classes.menuHeaderTitle}>
                        <Tooltip content={t('MenuCloseSidePanelLabel')} relationship="description">
                            <Button
                                appearance="subtle"
                                size="small"
                                icon={<NavigationIcon />}
                                onClick={toggleOpen}
                                aria-label={t('MenuCloseSidePanelLabel')}
                            />
                        </Tooltip>
                        <h2 className={classes.menuHeaderTitleText}>{t('ApplicationTitle')}</h2>
                    </div>
                </div>

                <DrawerBody className={classes.drawerBody}>
                    <div className={classes.menuContent}>
                        {canShowNavMenuItems && (
                            <NavMenuTabList onClick={toggleOpen}>
                                <NavMenuTab to="/">{t('MenuLinkHome')}</NavMenuTab>
                                <NavMenuTab to="/sessions">{t('MenuLinkMySessions')}</NavMenuTab>
                                <NavMenuTab to="/promptbooks">
                                    {t('MenuLinkPromptbookLibrary')}
                                </NavMenuTab>
                            </NavMenuTabList>
                        )}
                        {isAnyOwner && (
                            <NavMenuTabList heading={t('MenuHeadingOwner')} onClick={toggleOpen}>
                                {isOwner && canShowConsumptionModelItems && (
                                    <NavMenuTab to="/owner-settings">
                                        {t('MenuLinkOwnerSettings')}
                                    </NavMenuTab>
                                )}
                                {isOwner &&
                                    canShowConsumptionModelItems &&
                                    userPluginManagementAllowed && (
                                        <NavMenuTab to="/plugin-settings">
                                            {t('MenuLinkPluginSettings')}
                                        </NavMenuTab>
                                    )}
                                {isOwner && canShowConsumptionModelItems && isRBACEnabled && (
                                    <NavMenuTab to="/role-assignment">
                                        {t('MenuLinkRoleAssignment')}
                                    </NavMenuTab>
                                )}
                                {isWorkspacesTestingEnabled && (
                                    <NavMenuTab to="/manage-workspaces">
                                        {t('MenuLinkManageWorkspaces')}
                                    </NavMenuTab>
                                )}
                                {isOwner && canShowConsumptionModelItems && (
                                    <NavMenuTab to="/usage-monitoring">
                                        {t('MenuLinkUsageMonitoring')}
                                    </NavMenuTab>
                                )}
                            </NavMenuTabList>
                        )}
                        {isOwner &&
                            !canShowConsumptionModelItems &&
                            userPluginManagementAllowed && (
                                <NavMenuTabList
                                    heading={t('MenuHeadingPlugin')}
                                    onClick={toggleOpen}
                                >
                                    <NavMenuTab to="/plugin-settings">
                                        {t('MenuLinkPluginSettings')}
                                    </NavMenuTab>
                                </NavMenuTabList>
                            )}
                    </div>
                    <div className={classes.menuFooter}>
                        <Button
                            className={classes.settingsButton}
                            onClick={() => setSettingsOpen(true)}
                            {...restoreFocusTargetAttribute}
                        >
                            {settingsName}
                        </Button>
                        <UserSettingsModal
                            data-testid="SettingsButton"
                            open={settingsOpen}
                            setOpen={setSettingsOpen}
                            setMenuOpen={setOpen}
                            {...restoreFocusSourceAttribute}
                        />
                        <Card className={classes.userCard} size="large">
                            <CardHeader
                                className={classes.userCardHeader}
                                image={<Avatar name={accounts?.[0]?.name} />}
                                header={
                                    <div className={classes.userCardName}>
                                        {instance.getActiveAccount()?.name}
                                    </div>
                                }
                                description={
                                    <div className={classes.userCardMetadata}>
                                        {instance.getActiveAccount()?.username}
                                    </div>
                                }
                                action={<SignInSignOutButton />}
                            />
                            <CardFooter className={classes.userCardFooter}>
                                <TenantSwitcher />
                            </CardFooter>
                        </Card>
                    </div>
                </DrawerBody>
            </Drawer>
            {/*
             * Drawer trigger
             */}
            <Tooltip content={t('MenuOpenSidePanelLabel')} relationship="description">
                <Button
                    appearance="subtle"
                    size="small"
                    icon={<NavigationIcon />}
                    onClick={toggleOpen}
                    aria-label={t('MenuOpenSidePanelLabel')}
                    data-testid="side-panel-menu-button"
                    {...restoreFocusTargetAttribute}
                />
            </Tooltip>
        </>
    );
}
