import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetCapacitiesResponse} from '.';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import useFetch from '../useFetch';
import securityGraphApi from '@/api/securityGraph.api';

export const capacitiesApiOptions = {
    path: '/account/capacities',
    version: '2023-12-01-preview',
    isGlobal: true,
};

export default function useGetCapacities(
    useQueryOptions?: Pick<UseQueryOptions<GetCapacitiesResponse>, 'enabled'>,
) {
    const url = securityGraphApi({
        path: '/account/capacities',
        version: '2023-12-01-preview',
        isGlobal: true,
    });
    const {customFetch} = useFetch();

    const response = useQuery({
        ...useQueryOptions,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['capacities'],
        queryFn: async () => {
            const response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                const error = {
                    code: response.status,
                    message: response.statusText,
                };

                throw error;
            }

            const data = await response.json();
            return data as GetCapacitiesResponse;
        },
        retry: DEFAULT_API_RETRY_COUNT,
    });

    return response;
}
