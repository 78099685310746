import {useAppState} from '@/api/app';
import {BarChartHoverCardProps} from './BarChartHoverCard.types';
import {useMemo} from 'react';
import {themes} from '@/components/theme/CustomFluentProvider';
import {FluentProvider} from '@fluentui/react-components';
import useClasses from './BarChartHoverCard.styles';
import {createHoverCard} from './BarChartHoverCardFactory';
import DateDisplaySection from './DateDisplaySection';

export default function BarChartHoverCard(props: BarChartHoverCardProps) {
    const classes = useClasses();
    const {calloutData} = props;

    // FluentV9 tokens aren't compatible with react-charting (V8).
    // This is a workaround to use v9 tokens by injecting FluentProvider within the CustomComponent.
    const {colorScheme} = useAppState();
    const theme = useMemo(() => themes[colorScheme] ?? themes.Default, [colorScheme]);

    const hoverCardTemplate = createHoverCard(props.chartType, {
        calloutData,
    });
    const usageDate = new Date(calloutData.xAxisPoint);

    return (
        <FluentProvider theme={theme}>
            <div className={classes.root}>
                <DateDisplaySection usageDate={usageDate} />
                {hoverCardTemplate}
            </div>
        </FluentProvider>
    );
}
