import {useFocusFinders} from '@fluentui/react-components';
import * as React from 'react';
import {useState, useEffect, useRef} from 'react';
import {
    useCreateSkillset,
    useUpdateSkillset,
    useDeleteSkillset,
    SkillsetCatalogScope,
    CreateSkillsetRequest,
    SkillsetDescriptor,
    UpdateSkillsetRequest,
} from '@/api/skills';
import useClasses from './ExternalConfigurationModalContent.styles';
import useTimer from '@/util/useTimer';
import {ExternalConfigurationModalContentProps} from './ExternalConfigurationModalContent.types';
import {ApiError} from '@/api/api';
import {
    DeleteConfirmationDialog,
    SummaryOverlayDialog,
    UploadPluginDialog,
} from '@/components/ui/ConnectorModal/ExternalConfigurationModal';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

const USER_SCOPE_STRING = SkillsetCatalogScope.User.toString();
const USER_WORKSPACE_SCOPE_STRING = SkillsetCatalogScope.UserWorkspace.toString();
const TENANT_SCOPE_STRING = SkillsetCatalogScope.Tenant.toString();
const WORKSPACE_SCOPE_STRING = SkillsetCatalogScope.Workspace.toString();

export default function ExternalConfigurationModalContent({
    onSuccessReturn: onReturn,
    selectedConnector,
    onDeleteReturn,
    showDeletePanel: showConfirmationModal,
    onClose,
}: ExternalConfigurationModalContentProps) {
    const classes = useClasses();
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const {
        mutateAsync: updateData,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        isSuccess: isUpdateSuccess,
    } = useUpdateSkillset();
    const {
        mutateAsync: sendData,
        isLoading: isCreateLoading,
        isError: isCreateError,
        isSuccess: isCreateSuccess,
    } = useCreateSkillset();
    const {
        mutateAsync: deleteData,
        isLoading: isDeleteLoading,
        isError: isDeleteError,
        isSuccess: isDeleteSuccess,
    } = useDeleteSkillset(
        selectedConnector?.name ?? '',
        selectedConnector?.catalogScope ??
            (isWorkspacesTestingEnabled
                ? SkillsetCatalogScope.UserWorkspace
                : SkillsetCatalogScope.User),
    );

    // Map of setting name to value based on the user's form input
    const [selectedScope, setSelectedScope] = useState(
        selectedConnector?.catalogScope
            ? selectedConnector.catalogScope.toString()
            : isWorkspacesTestingEnabled
            ? USER_WORKSPACE_SCOPE_STRING
            : USER_SCOPE_STRING,
    );
    // Toggle to show the overlay when the data is being saved
    const [showOverlay, setShowOverlay] = useState(false);
    const [isCreateOrUpdateError, setIsCreateOrUpdateError] = useState(false);

    const [errorResponse, setErrorResponse] = useState('');
    const [deleteErrorResponse, setDeleteErrorResponse] = useState('');
    const [createSkillsetResponse, setCreateSkillsetResponse] = useState<SkillsetDescriptor | null>(
        null,
    );

    const externalConfigurationModalRef = useRef<HTMLDivElement>(null);
    const {findFirstFocusable} = useFocusFinders();

    // Open Connector Modal when return button is clicked
    const handleReturn = () => {
        onReturn?.(createSkillsetResponse);
    };

    const handleDeleteReturn = () => {
        onDeleteReturn?.(createSkillsetResponse);
    };

    const handleConnectorReturn = () => {
        //when back button is clicked, enable the skillset if upload was successful
        isCreateSuccess ? handleReturn() : handleDeleteReturn();
    };

    const handleErrorResponse = (
        error: any,
        setError: React.Dispatch<React.SetStateAction<string>>,
    ) => {
        if (error instanceof ApiError && error.response) {
            error.response.text().then((e) => setError(`${JSON.parse(e).message}`));
        } else {
            setError(error.toString());
        }
    };

    const handleSkillsetDataResponse = (response: any) => {
        setCreateSkillsetResponse(response.descriptor);
    };

    const sendSkillsetData = async (skillset: CreateSkillsetRequest) => {
        setErrorResponse('');

        sendData(skillset)
            .then(handleSkillsetDataResponse)
            .catch((error) => handleErrorResponse(error, setErrorResponse));
    };

    const updateSkillsetData = async (skillset: UpdateSkillsetRequest) => {
        setErrorResponse('');

        updateData(skillset)
            .then(handleSkillsetDataResponse)
            .catch((error) => handleErrorResponse(error, setErrorResponse));
    };

    const handleDelete = () => {
        var response = deleteData();
        response.catch((error) => handleErrorResponse(error, setDeleteErrorResponse));
    };

    const timer = useTimer({time: 2000, onTimerEnd: handleReturn});

    const deleteTimer = useTimer({time: 2000, onTimerEnd: handleDeleteReturn});

    useEffect(() => {
        if (externalConfigurationModalRef.current) {
            const firstFocusable = findFirstFocusable(externalConfigurationModalRef.current);
            firstFocusable?.focus();
        }
    }, [findFirstFocusable]);

    useEffect(() => {
        if (isCreateError || isUpdateError) {
            setIsCreateOrUpdateError(true);
        } else {
            setIsCreateOrUpdateError(false);
        }
    }, [isCreateError, isUpdateError]);

    useEffect(() => {
        if (
            isCreateLoading ||
            isCreateSuccess ||
            isDeleteLoading ||
            isDeleteError ||
            isDeleteSuccess ||
            isUpdateLoading ||
            isUpdateSuccess ||
            isCreateOrUpdateError
        ) {
            setShowOverlay(true);

            if (
                (isCreateSuccess || isUpdateSuccess) &&
                (selectedScope == USER_SCOPE_STRING ||
                    selectedScope == TENANT_SCOPE_STRING ||
                    selectedScope == USER_WORKSPACE_SCOPE_STRING ||
                    selectedScope == WORKSPACE_SCOPE_STRING)
            ) {
                timer();
            } else if (isDeleteSuccess) {
                deleteTimer();
            }
        }
    }, [
        isCreateLoading,
        isCreateSuccess,
        isDeleteLoading,
        isDeleteError,
        isDeleteSuccess,
        isUpdateLoading,
        isUpdateSuccess,
        isCreateOrUpdateError,
        selectedScope,
        timer,
        deleteTimer,
    ]);

    return (
        <div ref={externalConfigurationModalRef}>
            {!showOverlay && !showConfirmationModal && (
                <UploadPluginDialog
                    selectedScope={selectedScope}
                    selectedConnector={selectedConnector}
                    handleConnectorReturn={handleConnectorReturn}
                    onScopeSelected={setSelectedScope}
                    onSendSkillsetData={sendSkillsetData}
                    onUpdateSkillsetData={updateSkillsetData}
                    externalConfigurationModalRef={externalConfigurationModalRef}
                    onClose={onClose}
                />
            )}
            {showOverlay && (
                <SummaryOverlayDialog
                    isCreateLoading={isCreateLoading}
                    isUpdateLoading={isUpdateLoading}
                    isDeleteLoading={isDeleteLoading}
                    isCreateSuccess={isCreateSuccess}
                    isUpdateSuccess={isUpdateSuccess}
                    isDeleteSuccess={isDeleteSuccess}
                    isCreateOrUpdateError={isCreateOrUpdateError}
                    isDeleteError={isDeleteError}
                    errorResponse={errorResponse}
                    deleteErrorResponse={deleteErrorResponse}
                    handleConnectorReturn={handleConnectorReturn}
                    onReturnClick={() => {
                        // Set isCreateOrUpdateError to false to prevent the error overlay from showing again when selectedScope changes
                        setIsCreateOrUpdateError(false);
                        setShowOverlay(false);
                    }}
                />
            )}
            {showConfirmationModal && !showOverlay && (
                <DeleteConfirmationDialog
                    selectedConnector={selectedConnector}
                    handleDelete={handleDelete}
                    handleConnectorReturn={handleConnectorReturn}
                />
            )}
        </div>
    );
}
