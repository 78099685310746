import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {customFetch} from '@/api/api';
import type {UserFeatures} from '@/api/user/user.types';

export default function useGetUserFeatureFlags(
    props: Pick<UseQueryOptions<UserFeatures>, 'enabled'>,
) {
    return useQuery({
        queryKey: ['userFeatureFlags'],
        enabled: props.enabled,
        staleTime: Infinity,
        queryFn: async () => {
            return await customFetch<UserFeatures>(`/users/features`, {
                method: 'GET',
            });
        },
    });
}
