import {
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
} from '@fluentui/react-components';
import {DataTableProps} from './DataTable.types';
import useClasses from './DataTable.styles';
import {useTranslation} from 'react-i18next';
import {SortingDirection} from '@/api/api';
import {useState} from 'react';

export default function DataTable<T>(props: DataTableProps<T>) {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    //If v2 is not implemented, shouldSortData will always be true, else if the feature is enabled, will render depending on
    // the sorting parameters
    const shouldSortData = props.sortingParameters?.isSortable ?? false;

    const sortColumn =
        props?.sortingParameters?.sortColumn?.[0]?.column ?? props.columns?.[0]?.columnId;

    const sortDirection = Boolean(props.sortingParameters?.sortDirection)
        ? props.sortingParameters?.sortDirection
        : 'descending';

    const [sortState, setSortState] = useState<
        Parameters<NonNullable<DataGridProps['onSortChange']>>[1]
    >(
        props.sortingParameters?.isSortable
            ? {
                  sortColumn: sortColumn,
                  sortDirection: sortDirection ?? 'descending',
              }
            : {
                  sortColumn: sortColumn,
                  sortDirection: 'descending',
              },
    );

    const onGridSortChange: DataGridProps['onSortChange'] = (e, nextSortState) => {
        if (Boolean(props.onSortChange) && props.sortingParameters?.isSortable) {
            e.preventDefault();
            e.stopPropagation();

            props.onSortChange?.(e, setSortState, {
                sortColumn: nextSortState.sortColumn as string,
                sortDirection: nextSortState.sortDirection as SortingDirection,
            });
        } else {
            setSortState(nextSortState);
        }
    };

    return (
        <div>
            <div className={classes.root}>
                <DataGrid
                    className={classes.grid}
                    items={props.data}
                    columns={props.columns}
                    sortable={shouldSortData}
                    focusMode="composite"
                    sortState={sortState}
                    onSortChange={onGridSortChange}
                    data-testid={props.dataTestId}
                >
                    <DataGridHeader>
                        <DataGridRow>
                            {({renderHeaderCell}) => (
                                <DataGridHeaderCell className={classes.gridHeaderCell}>
                                    {t(renderHeaderCell() as string)}
                                </DataGridHeaderCell>
                            )}
                        </DataGridRow>
                    </DataGridHeader>
                    <DataGridBody<T>>
                        {({item}) => (
                            <DataGridRow<T> className={classes.gridRow}>
                                {({renderCell}) => (
                                    <DataGridCell className={classes.gridRowCell}>
                                        {renderCell(item)}
                                    </DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                </DataGrid>
            </div>
        </div>
    );
}
