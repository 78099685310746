import {useQuery, UseQueryOptions} from '@tanstack/react-query';

import useFetch from '../useFetch';
import {
    DEFAULT_API_RETRY_COUNT,
    RESOURCE_SCOPES,
    WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
} from '../api.constants';
import {Workspace, usePutWorkspace} from '@/api/workspaces';
import securityGraphApi from '../securityGraph.api';

/** Iterate over all workspaces user has access to and check if user is
 * * owner of any workspace
 * * owner of current workspace
 * and returns the list of workspaces user owns
 */
interface WorkspacesOwnerCheckResponse {
    ownerOfAWorkspace?: boolean;
    ownerOfSelectedWorkspace?: boolean;
    ownedWorkspaces: string[];
}

function useWorkspacesOwnerCheck(
    workspaceName?: string,
    workspaces?: Workspace[],
    useQueryOptions?: Pick<
        UseQueryOptions<WorkspacesOwnerCheckResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const {customFetch} = useFetch();

    return useQuery<WorkspacesOwnerCheckResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: WORKSPACE_PROVIDER_DEFAULT_STALE_TIME,
        queryKey: ['workspaces', 'workspacesOwnerCheck', workspaceName],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async (): Promise<WorkspacesOwnerCheckResponse> => {
            let ownerOfSelectedWorkspace = false;
            let ownerOfAWorkspace = false;
            let ownedWorkspaces: string[] = [];

            await Promise.all(
                (workspaces || []).map(async (workspace) => {
                    //Check if user is owner of workspace by simulating a workspace update
                    let response = await customFetch<Response>(
                        getUrl(workspace),
                        {
                            method: 'PUT',
                            scopes: RESOURCE_SCOPES.FIDELIS,
                            //Header to simulate the operation without actually performing it
                            headersFromOptions: {
                                'x-ms-operation-whatif': 'true',
                            },
                        },
                        true,
                    );
                    if (response?.ok) {
                        ownerOfSelectedWorkspace =
                            workspace.name === workspaceName || ownerOfSelectedWorkspace;
                        ownerOfAWorkspace = true;
                        ownedWorkspaces.push(workspace.name);
                    }
                }),
            );

            return {
                ownerOfSelectedWorkspace,
                ownerOfAWorkspace,
                ownedWorkspaces,
            } as WorkspacesOwnerCheckResponse;
        },
        ...useQueryOptions,
    });
}

function getUrl(workspace: Workspace): string {
    return securityGraphApi({
        path: ``,
        version: '2024-01-01-preview',
        targetWorkspace: workspace,
    });
}

export default useWorkspacesOwnerCheck;
