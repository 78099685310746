import React, {useEffect, useState} from 'react';
import {Dropdown, Label, Option, mergeClasses, useId} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useClasses from '../Preferences.styles';
import {
    PluginRbacSettingOptions,
    PluginRbacSettingScopeLabel,
    WorkspacesPluginRbacSettingScopeLabel,
} from './Preferences.constants';
import {PluginRbacSetting, PluginRbacSettingScope} from './Preferences.types';
import {TenantInfo, usePatchTenantInfo} from '@/api/tenant';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {getDefaultPluginRbacSetting, PluginRbacDropdownOptions} from './Preferences.utils';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';
import {useGetWorkspaceSettings, useUpdateWorkspaceSettings} from '@/api/workspaces';
import {WorkspaceSettings} from '@/api/workspaces/workspaces.types';

interface PluginRbacManagementSettingProps {
    tenantInfo: TenantInfo | undefined;
    pluginRbacScope: PluginRbacSettingScope;
    isAdmin: boolean;
    overriddenLabelText?: string;
}

export default function PluginRbacManagementSetting({
    tenantInfo,
    pluginRbacScope,
    isAdmin,
    overriddenLabelText,
}: PluginRbacManagementSettingProps) {
    const pluginRbacDropdownId = useId(`pluginRbacDropdown${pluginRbacScope}`);
    const classes = useClasses();
    const {t} = useTranslation();
    const {mutate: patchTenantInfo, isLoading: patchTenantInfoLoading} = usePatchTenantInfo();
    const [pluginMgmtRbac, setPluginMgmtRbac] = useState(PluginRbacSetting.ANY);
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    // multi-workspaces
    const {data: workspace, isFetched: isWorkspacesDataFetched} = useGetWorkspaceSettings(
        undefined,
        {
            enabled: Boolean(isWorkspacesTestingEnabled),
        },
    );
    const {mutate: updateWorkspace, isLoading: updateWorkspaceInfoLoading} =
        useUpdateWorkspaceSettings({enabled: Boolean(isWorkspacesTestingEnabled)});

    const isUserRbacScope = pluginRbacScope === PluginRbacSettingScope.USER;

    useEffect(() => {
        // Set the plugin management RBAC text based on the tenantInfo response
        if (isWorkspacesTestingEnabled && workspace) {
            const rbacSetting: PluginRbacSetting = getDefaultPluginRbacSetting(
                pluginRbacScope,
                tenantInfo,
                workspace,
            );
            setPluginMgmtRbac(rbacSetting);
        } else {
            const rbacSetting: PluginRbacSetting = getDefaultPluginRbacSetting(
                pluginRbacScope,
                tenantInfo,
            );
            setPluginMgmtRbac(rbacSetting);
        }
    }, [tenantInfo, setPluginMgmtRbac, pluginRbacScope, workspace]);

    const handlePluginRbacChange = (rbacSetting: PluginRbacSetting) => {
        // Update the tenantInfo with the new plugin RBAC setting.
        // Include the existing tenantInfo values to populate the other fields
        const allowPluginUpload = rbacSetting === PluginRbacSetting.ANY;
        if (isWorkspacesTestingEnabled && workspace) {
            if (isUserRbacScope) {
                if (allowPluginUpload) {
                    const workspaceSettings: WorkspaceSettings = {
                        ...workspace,
                        workspaceId: workspace?.workspaceId ?? '',
                        allowWorkspacePluginUpload: allowPluginUpload,
                    };
                    updateWorkspace({
                        workspaceSettings,
                    });
                } else {
                    const workspaceSettings: WorkspaceSettings = {
                        ...workspace,
                        workspaceId: workspace?.workspaceId ?? '',
                        allowUserPluginUpload: false,
                        allowWorkspacePluginUpload: false,
                    };
                    // If selecting to disallow user plugin upload, also disallow tenant plugin upload.
                    updateWorkspace({
                        workspaceSettings,
                    });
                }
            } else {
                const workspaceSettings: WorkspaceSettings = {
                    ...workspace,
                    workspaceId: workspace?.workspaceId ?? '',
                    allowWorkspacePluginUpload: allowPluginUpload,
                };
                updateWorkspace({
                    workspaceSettings,
                });
            }
        } else {
            // original logic without multi-workspaces
            if (isUserRbacScope) {
                if (allowPluginUpload) {
                    patchTenantInfo({
                        ...tenantInfo,
                        allowUserPluginUpload: allowPluginUpload,
                    });
                } else {
                    // If selecting to disallow user plugin upload, also disallow tenant plugin upload.
                    patchTenantInfo({
                        ...tenantInfo,
                        allowUserPluginUpload: false,
                        allowTenantPluginUpload: false,
                    });
                }
            } else {
                patchTenantInfo({
                    ...tenantInfo,
                    allowTenantPluginUpload: allowPluginUpload,
                });
            }
        }
    };

    const rbacDropdownEnabled = () => {
        // Disabled for non-admins and while the patchTenantInfo response is loading
        if (isWorkspacesTestingEnabled && workspace) {
            if (overriddenLabelText || !isAdmin || updateWorkspaceInfoLoading) {
                return false;
            }
            // Enabled when the user is an admin and the workspace is defined.
            if (isUserRbacScope) {
                return workspace?.allowUserPluginUpload !== undefined;
            }
            return workspace?.allowWorkspacePluginUpload !== undefined;
        } else {
            // original logic
            if (overriddenLabelText || !isAdmin || patchTenantInfoLoading) {
                return false;
            }
            // Enabled when the user is an admin and the tenantInfo is defined.
            if (isUserRbacScope) {
                return tenantInfo?.allowUserPluginUpload !== undefined;
            }
            return tenantInfo?.allowTenantPluginUpload !== undefined;
        }
    };

    const renderDropdownLabel = () => {
        return (
            <Label
                id={pluginRbacDropdownId}
                className={
                    !rbacDropdownEnabled()
                        ? mergeClasses(classes.label, classes.disabledLabel)
                        : classes.label
                }
            >
                {isWorkspacesTestingEnabled
                    ? t(WorkspacesPluginRbacSettingScopeLabel[pluginRbacScope])
                    : t(PluginRbacSettingScopeLabel[pluginRbacScope])}
            </Label>
        );
    };

    const dropdownTextValue = t(PluginRbacSettingOptions[pluginMgmtRbac]);

    return (
        <div className={classes.rbacDropdown}>
            {overriddenLabelText ? (
                <MedeinaInfoLabel
                    labelContent={renderDropdownLabel()}
                    infoContent={overriddenLabelText}
                />
            ) : (
                renderDropdownLabel()
            )}
            <div>
                <Dropdown
                    className={classes.dropdownSelector}
                    value={dropdownTextValue}
                    selectedOptions={pluginMgmtRbac ? [pluginMgmtRbac] : []}
                    onOptionSelect={(_, data) => {
                        handlePluginRbacChange(data.optionValue as PluginRbacSetting);
                    }}
                    aria-labelledby={pluginRbacDropdownId}
                    disabled={!rbacDropdownEnabled()}
                >
                    {PluginRbacDropdownOptions.map((option) => (
                        <Option key={option.value} value={option.value} text={t(option.text)}>
                            {t(option.text)}
                        </Option>
                    ))}
                </Dropdown>
            </div>
        </div>
    );
}
