import {
    AgentSkillSettings,
    KQLSkillSettings,
    Skill,
    SkillGroupFormat,
    SkillInterface,
    Skillset,
    SkillSettings,
} from './SkillsetCreator.types';
import * as yaml from 'js-yaml';

export const convertYamlSkillset = (yamlString: string): Skillset => {
    const parsedData = yaml.load(yamlString) as any;

    const skillset: Skillset = {
        name: parsedData?.Descriptor?.Name,
        displayName: parsedData?.Descriptor?.DisplayName,
        description: parsedData?.Descriptor?.Description,
        skills: [],
    };

    parsedData?.SkillGroups?.forEach((group: any) => {
        group?.Skills?.forEach((skill: any) => {
            let settings: SkillSettings;
            if (group.Format === SkillGroupFormat.KQL) {
                settings = {
                    target: skill.Settings.Target,
                    cluster: skill.Settings.Cluster,
                    database: skill.Settings.Database,
                    template: skill.Settings.Template,
                } as KQLSkillSettings;
            } else if (group.Format === SkillGroupFormat.AGENT) {
                settings = {
                    instructions: skill.Settings.Instructions,
                } as AgentSkillSettings;
            } else {
                settings = skill.Settings;
            }
            const newSkill: Skill = {
                name: skill.Name,
                displayName: skill.Name,
                description: skill.Description,
                inputs: skill.Inputs.map((input: any) => ({
                    required: input.Required,
                    name: input.Name,
                    description: input.Description,
                })),
                settings: settings,
                childSkills: skill.ChildSkills,
                format: group.Format,
            };
            skillset.skills.push(newSkill);
        });
    });
    return skillset;
};

export const fetchYamlFromSkillset = (skillset: Skillset): string => {
    const yamlData = {
        Descriptor: {
            Name: skillset.name,
            Description: skillset.description,
            DisplayName: skillset.name,
        },
        SkillGroups: Object.values(SkillGroupFormat)
            .map((format) => {
                const skills = skillset.skills
                    .filter((skill) => skill.format === format)
                    .map((skill) => {
                        let settings: any;
                        if (format === SkillGroupFormat.KQL) {
                            settings = {
                                Target: (skill.settings as KQLSkillSettings).target,
                                Cluster: (skill.settings as KQLSkillSettings).cluster,
                                Database: (skill.settings as KQLSkillSettings).database,
                                Template: (skill.settings as KQLSkillSettings).template,
                            };
                        } else if (format === SkillGroupFormat.AGENT) {
                            settings = {
                                Instructions: (skill.settings as AgentSkillSettings).instructions,
                            };
                        } else {
                            settings = skill.settings;
                        }
                        return {
                            Name: skill.name,
                            DisplayName: skill.name,
                            Description: skill.description,
                            Interfaces:
                                format === SkillGroupFormat.AGENT ? [SkillInterface.Agent] : [],
                            Inputs: skill.inputs.map((input) => ({
                                Required: input.required,
                                Name: input.name,
                                Description: input.description,
                            })),
                            Settings: settings,
                            ChildSkills: skill.childSkills,
                        };
                    });
                return skills.length > 0
                    ? {
                          Format: format,
                          Settings: {HistoryPassDownMode: 'None', IncludeSessionHistory: false},
                          Skills: skills,
                      }
                    : null;
            })
            .filter((group) => group !== null),
    };

    const yamlString = yaml.dump(yamlData);
    return yamlString;
};

export const trimSkillsetYaml = (currentSkillsetYaml: string): string => {
    // trim any trailing whitespace from the yaml object and return the updated yaml
    try {
        const skillset: Skillset = yaml.load(currentSkillsetYaml) as Skillset;
        const trimmedSkillset = JSON.parse(JSON.stringify(skillset), (key, value) =>
            typeof value === 'string' ? value.trim() : value,
        );
        return yaml.dump(trimmedSkillset);
    } catch (e) {
        return currentSkillsetYaml;
    }
};
