import {useGetSkillsetCustomIcon} from '@/api/skills';
import {Badge, Tooltip, Image, mergeClasses} from '@fluentui/react-components';
import React from 'react';
import useClasses from './ExploreCardBadge.styles';

type Plugin = {
    skillset: string;
    icon: string;
    displayName: string;
};

type PluginIconProps = {
    className?: string;
    plugin: Plugin;
};

export default function ExploreCardBadge({className, plugin}: PluginIconProps) {
    const classes = useClasses();

    const PluginIcon = ({plugin}: PluginIconProps) => {
        const {data: iconPath, isLoading: loading} = useGetSkillsetCustomIcon(
            plugin?.skillset,
            plugin?.icon,
        );
        return iconPath !== '/images/icons/Default.svg' ? (
            <Image
                fit="default"
                className={classes.badgeImage}
                alt={''}
                aria-hidden
                src={iconPath}
            />
        ) : (
            <span></span>
        );
    };

    const conditionalClass =
        className && className in classes ? classes[className as keyof typeof classes] : '';

    return (
        <Tooltip relationship="label" content={plugin!.displayName}>
            <Badge
                shape="rounded"
                appearance="outline"
                color="informative"
                aria-label={plugin!.displayName}
                className={classes.badge}
                icon={<PluginIcon aria-hidden key={plugin!.skillset} plugin={plugin!} />}
                tabIndex={0}
            >
                <span className={mergeClasses(classes.label, conditionalClass)}>
                    {plugin?.displayName.trim() != '' ? plugin?.displayName : plugin.skillset}
                </span>
            </Badge>
        </Tooltip>
    );
}
