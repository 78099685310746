import {useMutation, useQueryClient} from '@tanstack/react-query';
import {GetWorkspaceByNameResponse, Workspace} from '.';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import {PutWorkspaceRequest} from './workspaces.types';
import securityGraphApi from '@/api/securityGraph.api';
/**
 * Hook to create or update a workspace - this allows user to update a target workspace that is not the current workspace
 * Call if FRE already completed and user is re-creating first workspace
 * if the workspace does not already exist the workspace will be created
 * This is a new version / non-FRE version of the useCreateFirstWorkspace hook
 * @returns GetWorkspaceByNameResponse
 */
interface CreateWorkspaceParams {
    request: PutWorkspaceRequest;
    targetWorkspace?: Workspace;
}
export default function usePutWorkspace() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({request, targetWorkspace}: CreateWorkspaceParams) => {
            console.log({request, targetWorkspace});
            const getUrl = () =>
                securityGraphApi({
                    path: ``,
                    version: '2023-12-01-preview',
                    targetWorkspace: targetWorkspace,
                });
            try {
                return await customFetch<GetWorkspaceByNameResponse>(getUrl(), {
                    method: 'PUT',
                    body: request,
                    scopes: RESOURCE_SCOPES.FIDELIS,
                });
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.removeQueries(['workspaces']);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
