import {QueryClient} from '@tanstack/react-query';
import {SettingsScope, SkillsetCatalogScope} from './skills.types';

export function doSkillsetNamespaceInvalidation(queryClient: QueryClient) {
    // first deal with any ObjectURLs in cache and revoke them to not leak memory
    const data = queryClient.getQueriesData({
        queryKey: ['skillsets'],
        predicate: (query) => query.queryKey[2] === 'icon',
    });
    data.forEach((tuple) => {
        const [tkey, tdata] = tuple;
        if (typeof tdata === 'string' && tdata.startsWith('blob:')) {
            URL.revokeObjectURL(tdata);
        }
    });
    // now invalidate the top level skillsets namespace
    queryClient.invalidateQueries(['skillsets']);
}

export function isValidURL(str?: string | null) {
    if (str == null || str === '') return false;
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
}

/**
 * Converts the Catalog Scope to Settings Scope.
 * Currently, only Tenant and User scopes are supported. Global and Workspace scopes default to User scope.
 *
 * @param scope The skillset catalog scope to be mapped.
 * @returns The corresponding settings scope.
 */
export function catalogScopeToSettingsScope(
    scope: SkillsetCatalogScope | null | undefined,
    fallbackSettingsScope?: SettingsScope | null | undefined,
): SettingsScope {
    if (scope == null || scope == undefined) {
        return fallbackSettingsScope ?? SettingsScope.User;
    }
    switch (scope) {
        case SkillsetCatalogScope.Tenant:
            return SettingsScope.Tenant;
        case SkillsetCatalogScope.User:
            return SettingsScope.User;
        case SkillsetCatalogScope.UserWorkspace:
            return SettingsScope.UserWorkspace;
        case SkillsetCatalogScope.Workspace:
            return SettingsScope.Workspace;
        case SkillsetCatalogScope.Global:
            return fallbackSettingsScope ?? SettingsScope.User;
        default:
            return fallbackSettingsScope ?? SettingsScope.User;
    }
}
