import {HourlyCapacityUsage} from '@/api/capacities';
import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import {IUsageChartConfigurator, OverageChartUsageData} from '../VStackedBarChart.types';
import {
    getDayName,
    getHourlyTimeframe,
    getZonedDate,
    roundToDecimalPlaces,
} from '../../UsageDashboard.utils';
import {
    CAPACITY_BUCKETS,
    CAPACITY_LIMIT_TYPES,
    UNIT_PLURAL_TEXT,
    USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
} from '../../UsageDashboard.constants';
import {tokens} from '@fluentui/react-components';
import {WarningIcon} from '@/components/ui/icons';

export default class overageChartConfigurator implements IUsageChartConfigurator {
    private translator!: Function;

    constructor(contentTranslator: Function) {
        this.translator = contentTranslator;
    }

    getMapForOnHoverMatching = (): {
        [key: string]: {icon: JSX.Element; status: string; ariaLabel: string};
    } => {
        return {
            [`${CAPACITY_LIMIT_TYPES.BELOW_OVERAGE}`]: {
                icon: <></>,
                status: '',
                ariaLabel: `${CAPACITY_LIMIT_TYPES.BELOW_OVERAGE}.UnitStatusAriaLabel`,
            },
            [`${CAPACITY_LIMIT_TYPES.ABOVE_OVERAGE}`]: {
                icon: <WarningIcon color="#FFBA66" data-testid="warning-above-overage" />,
                status: `${CAPACITY_LIMIT_TYPES.ABOVE_OVERAGE}.UnitStatusText`,
                ariaLabel: `${CAPACITY_LIMIT_TYPES.ABOVE_OVERAGE}.UnitStatusAriaLabel`,
            },
            [`${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}`]: {
                icon: <WarningIcon filled color="#C50F1F" data-testid="warning-throttled" />,
                status: `${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}.UnitStatusText`,
                ariaLabel: `${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}.UnitStatusAriaLabel`,
            },
        };
    };

    getChartConfiguration = (
        hourlyUsageMetrics: HourlyCapacityUsage,
    ): IVerticalStackedChartProps => {
        const usageData = this.getUsageData(hourlyUsageMetrics);
        return this.createGraphItem(hourlyUsageMetrics, usageData);
    };

    private getUsageData = (item: HourlyCapacityUsage): OverageChartUsageData => {
        const belowOverage = roundToDecimalPlaces(
            item.usedCapacity,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );

        const overageUsage = roundToDecimalPlaces(
            item.overageUsed,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );

        const exceededOverageUsage = roundToDecimalPlaces(
            item.overageExceeded || 0,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );

        let usageBucket = null;
        if (item.overageExceeded || 0 > 0) {
            usageBucket = CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE;
        } else if (item.overageUsed > 0 && item.overageUsed < item.overageAvailable) {
            usageBucket = CAPACITY_LIMIT_TYPES.ABOVE_OVERAGE;
        } else {
            usageBucket = CAPACITY_LIMIT_TYPES.BELOW_OVERAGE;
        }

        return {
            usageBelowOverage: belowOverage,
            overageUsage: overageUsage,
            exceededOverageUsage: exceededOverageUsage,
            usageBucket: usageBucket,
        };
    };

    private createGraphItem = (
        item: HourlyCapacityUsage,
        usageData: OverageChartUsageData,
    ): IVerticalStackedChartProps => {
        const {usageBelowOverage, overageUsage, usageBucket, exceededOverageUsage} = usageData;

        // Convert input UTC date to user timezone date
        const zonedDate = new Date(getZonedDate(item.aggregateStartTime));
        const stackAccessibilityData = this.getStackAccessibilityData(
            usageData,
            zonedDate,
            item.assignedCapacity,
        );

        return {
            chartData: [
                {
                    legend: CAPACITY_BUCKETS.BELOW_OVERAGE,
                    data: usageBelowOverage,
                    color: tokens.colorBrandForegroundLink,
                },
                {
                    legend: CAPACITY_BUCKETS.ABOVE_OVERAGE,
                    data: overageUsage,
                    color: tokens.colorCompoundBrandStrokePressed,
                },
                {
                    legend: CAPACITY_BUCKETS.EXCEEDED_OVERAGE,
                    data: exceededOverageUsage,
                    color: tokens.colorStatusDangerBackground3,
                },
            ],
            xAxisPoint: zonedDate, // BarChart doesn't respect string for custom formatting
            xAxisCalloutData: usageBucket, // Using as a placeholder to show usage status on custom callout
            lineData: [
                {
                    y: item.assignedCapacity,
                    legend: CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
                    color: tokens.colorStrokeFocus2,
                },
                {
                    y: item.assignedCapacity + item.overageUsed,
                    legend: CAPACITY_BUCKETS.OVERAGE_LIMIT,
                    color: tokens.colorStrokeFocus2,
                },
            ],
            // Override is required as xAxisCalloutData, used as a placeholder for the custom hover card component, also gets read.
            // The usageBucket, being a key for translation text rather than suitable display text, needs to be replaced.
            stackCallOutAccessibilityData: {
                ariaLabel: stackAccessibilityData,
            },
        };
    };

    private getStackAccessibilityData = (
        usageData: ReturnType<typeof this.getUsageData>,
        date: Date,
        assignedCapacity: number,
    ): string => {
        const {usageBelowOverage, overageUsage, usageBucket, exceededOverageUsage} = usageData;

        const matchingStatus = Object.entries(this.getMapForOnHoverMatching()).find(
            ([key]) => key === usageBucket,
        );

        const usageBucketText = `${this.translator(matchingStatus?.[1].ariaLabel ?? '')}`;

        const overageUsageText = `${this.translator(
            CAPACITY_BUCKETS.ABOVE_OVERAGE,
        )} ${overageUsage} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const belowOverage = `${this.translator(
            CAPACITY_BUCKETS.BELOW_OVERAGE,
        )} ${usageBelowOverage} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const exceededOverageUsageText = `${this.translator(CAPACITY_BUCKETS.EXCEEDED_OVERAGE)} ${
            usageData.exceededOverageUsage
        } ${this.translator(UNIT_PLURAL_TEXT)}`;

        const assignedCapacityText = `${this.translator(
            CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
        )} ${assignedCapacity} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const dayName = getDayName(date);
        const timeframe = getHourlyTimeframe(date);

        return `${usageBucketText}. ${dayName} from ${timeframe} . ${belowOverage}. ${overageUsageText}. ${overageUsage}. ${exceededOverageUsageText}. ${exceededOverageUsage}. ${assignedCapacityText}`;
    };
}
