import React from 'react';
import {useLocation} from 'react-router-dom';
import useClasses from './Breadcrumbs.styles';
import {useTourable} from './../../ui/Tour/useTourable';
import FeatureFlagIndicator from './../../FeatureFlagIndicator';
import {useViewportSize} from './../../ui/Grid';
import {MedeinaRoutes, MedeinaRoutesMap} from '@/components/Navigation/MedeinaRoutes';
import {findMatchingRouteConfiguration, getPathSegments} from './util';
import Default from './breadcrumbItems/Default';
import NewSession from './breadcrumbItems/NewSession';
import Session from './breadcrumbItems/Session';
import Promptbooks from './breadcrumbItems/Promptbooks';
import BreadcrumbItem from './breadcrumbItems/BreadcrumbItem';
import Home from './breadcrumbItems/Home';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface BreadcrumbItemProps {
    pathId: string;
    renderOnlyLast?: boolean;
}

// a switch that returns the correct breadcrumb item based on the route configuration
function ComponentBreadcrumbItems(props: BreadcrumbItemProps) {
    switch (props.pathId) {
        case MedeinaRoutesMap.promptbooks.id:
        case MedeinaRoutesMap.promptbook.id:
            return <Promptbooks />;
        case MedeinaRoutesMap.session.id:
            return <Session renderOnlyLast={props.renderOnlyLast} />;
        case MedeinaRoutesMap.newsessions.id:
            return <NewSession renderOnlyLast={props.renderOnlyLast} />;
        default:
            return <BreadcrumbItem segment={props.pathId} />;
    }
}

interface BreadcrumbProps {
    /** Component to use for workspace selection */
    WorkspaceSelector?: React.FC;
}

// The breadcrumbs component takes care of matching the current route to the breadcrumb configuration
// and rendering the breadcrumb items
// each BreadcrumbItem is responsible for rendering all segments of the path if using anything other than default
function Breadcrumbs(props: BreadcrumbProps) {
    const tourRef = useTourable({id: 'navbar'});

    const location = useLocation();
    const matchingRoute = findMatchingRouteConfiguration(location.pathname, MedeinaRoutes);
    // grab the breadcrumb configuration for the current route, if there is no matching route set nothing for the breadcrumb
    // if theres a matching route use any explicitly defined breadcrumb configuration or use the default
    const breadcrumbConfig = matchingRoute
        ? matchingRoute.breadcrumb
            ? matchingRoute.breadcrumb
            : {type: 'default', key: matchingRoute.id}
        : {type: 'none'};
    const hasBreadcrumbsEnabled = breadcrumbConfig.type !== 'none';
    const classes = useClasses();
    const segments = getPathSegments(location.pathname);
    const {sm: isSmallScreen} = useViewportSize();

    // check if this is the home route
    const isHome = location.pathname === '/';
    // check if we are on a tour route, this will matter for small screens
    const isTourRoute = location.pathname.includes('/tour/');
    // For small screens we'll show the home route for a few cases
    const showHomeOnSmallScreen = isHome || isTourRoute || !hasBreadcrumbsEnabled;
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    return (
        <nav ref={tourRef} className={classes.root} role="navigation">
            <ul className={classes.crumbList} role="menu">
                {(!isSmallScreen || (isSmallScreen && showHomeOnSmallScreen)) && (
                    <>
                        <li role="menuitem" className={classes.crumb} key="home">
                            <Home />
                        </li>
                        {props.WorkspaceSelector && isWorkspacesTestingEnabled && (
                            <li role="menuitem" className={classes.crumb} key="workspace-selector">
                                <props.WorkspaceSelector />
                            </li>
                        )}
                        <FeatureFlagIndicator />
                        {hasBreadcrumbsEnabled && (
                            <li role="separator" key="home separator" className={classes.spacer}>
                                /
                            </li>
                        )}
                    </>
                )}

                {hasBreadcrumbsEnabled &&
                    (breadcrumbConfig.type === 'component' ? (
                        matchingRoute && (
                            <ComponentBreadcrumbItems
                                pathId={matchingRoute.id}
                                renderOnlyLast={isSmallScreen}
                            />
                        )
                    ) : (
                        <Default pathSegments={segments} renderOnlyLast={isSmallScreen} />
                    ))}
            </ul>
        </nav>
    );
}

export default Breadcrumbs;
