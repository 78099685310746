import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {DEFAULT_API_RETRY_COUNT, RESOURCE_SCOPES} from '../api.constants';
import {customFetch} from '../api';
import securityGraphApi from '@/api/securityGraph.api';
import {GetWorkspaceCfSByNameResponse, Workspace} from './workspaces.types';

export default function useGetWorkspaceSettings(
    targetWorkspace?: Workspace,
    useQueryOptions?: Pick<
        UseQueryOptions<GetWorkspaceCfSByNameResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const url = securityGraphApi({
        path: `/securitycopilot/WorkspaceSettings`,
        version: '',
        targetWorkspace: targetWorkspace,
    });
    return useQuery<GetWorkspaceCfSByNameResponse>({
        refetchOnMount: useQueryOptions?.refetchOnMount ?? false,
        enabled: useQueryOptions?.enabled ?? false,
        refetchOnWindowFocus: false,
        queryKey: ['workspacesettings'],
        retry: DEFAULT_API_RETRY_COUNT,
        queryFn: async () =>
            await customFetch<GetWorkspaceCfSByNameResponse>(url, {
                scopes: RESOURCE_SCOPES.FIDELIS,
                method: 'GET',
            }),
    });
}
