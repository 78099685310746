import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    descriptionContainer: {
        marginTop: '12px',
        marginBottom: '12px',
    },
    titleContainer: {
        display: 'flex',
    },
});
