import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import MedeinaFeatures from '@/util/features';
import {
    readFromSessionStorage,
    writeToSessionStorage,
    deleteFromSessionStorage,
} from '@/util/sessionStorage.util';
import {WorkspaceSessionData} from './WorkspacesProvider.types';
import {WORKSPACE_NAMESPACE} from './workspaces.constants';
import {Workspace} from '@/api/workspaces';

export const GEO_SESSION_STORAGE_KEY = 'geopodEnabled';

interface GeoPodEnabled {
    enabled: boolean;
}

export const isGeoPodEnabled = () => {
    // the most consistent way to check if the geopods url is enabled is to check the value in local storage
    // populated by the User provider when the user logs in
    const geoEcsFlagEnabled = readFromSessionStorage(GEO_SESSION_STORAGE_KEY, {
        enabled: true,
    }) as GeoPodEnabled;
    // check if the environment has the feature flag for geopods enabled or if its present in the url feature flags
    return (
        hasStaticFeatureFlag(MedeinaFeatures.EnableGeoPod) ||
        hasStaticFeatureFlag(MedeinaFeatures.GeoPod) ||
        geoEcsFlagEnabled.enabled
    );
};

export const setIsGeoPodEnabled = async (value: boolean) => {
    writeToSessionStorage<GeoPodEnabled>(GEO_SESSION_STORAGE_KEY, {enabled: value});
};

export const setCurrentWorkspaceCache = (workspace: WorkspaceSessionData): void => {
    writeToSessionStorage<WorkspaceSessionData>(WORKSPACE_NAMESPACE, workspace);
};

/** This resets all session storage flags related to multiworkspaces */
export const clearCurrentWorkspaceCache = (): void => {
    // clear the WorkspaceSessionDate from session storage
    deleteFromSessionStorage(WORKSPACE_NAMESPACE);
    // clear the geopod flag from session storage
    // this will force the WorkspacesProvider to re-fetch the currentWorkspace and update the cache and flag
    // deleteFromSessionStorage(GEO_SESSION_STORAGE_KEY);
};

export const getCurrentWorkspaceCache = (): null | WorkspaceSessionData => {
    const cacheValue = readFromSessionStorage<WorkspaceSessionData>(WORKSPACE_NAMESPACE);
    if (!cacheValue) {
        return null;
    } else {
        return cacheValue;
    }
};

export function formatWorkspaceToSessionData(workspace: Workspace): WorkspaceSessionData {
    return {
        name: workspace?.name || '',
        path: workspace?.path || '',
        managementUrl: workspace?.managementUrl || '',
        // we want to capture the second part of the path of  pods/82e853fb-c82c-4d62-9812-439bf8a887c3/workspaces/default,
        podId: workspace.path?.split('/')[1] || '',
    };
}
