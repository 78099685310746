import {ContentTitle2} from '@/components/ui/Text';
import {Body1, mergeClasses, Subtitle2, tokens} from '@fluentui/react-components';
import React from 'react';
import useClasses from './ChangeCapacity.styles';

/**
 * Props for the UnitsDisplay component.
 *
 * @interface UnitsDisplayProps
 * @property {string} heading - The heading text to be displayed.
 * @property {string} numericDisplay - The numeric value to be displayed.
 * @property {string} footer - The footer text to be displayed.
 */

interface UnitsDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
    heading: string;
    numericDisplay: string;
    footer: string;
}

export default function UnitsDisplay(props: UnitsDisplayProps) {
    const classes = useClasses();
    return (
        <>
            <div className={mergeClasses(props.className, classes.unitsDisplayWrapper)} {...props}>
                {props.heading && <Subtitle2>{props.heading}</Subtitle2>}
                {props.numericDisplay && <ContentTitle2>{props.numericDisplay}</ContentTitle2>}
                {props.footer && (
                    <Body1 color={tokens.colorNeutralForeground2}>{props.footer}</Body1>
                )}
            </div>
        </>
    );
}
