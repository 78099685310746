import {
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    mergeClasses,
} from '@fluentui/react-components';
import useClasses from '../../ConnectorModalContent.styles';
import {SkillsetFilterTypes} from '@/api/skills';
import {useTranslation} from 'react-i18next';

interface StatusFilterDropdownProps {
    onFilterButtonClick: React.Dispatch<React.SetStateAction<SkillsetFilterTypes>>;
    displayedSkillsetsFilter: SkillsetFilterTypes;
}

// Status filter dropdown for the filtering toolbar in the "Manage plugins" popup
export default function StatusFilterDropdown({
    onFilterButtonClick,
    displayedSkillsetsFilter,
}: StatusFilterDropdownProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <Menu>
            <MenuTrigger>
                <MenuButton
                    appearance="transparent"
                    className={mergeClasses(classes.menuButton, classes.menuButtonMinWidth)}
                >
                    {t('ContentModal.ButtonLabels.Status')}: {displayedSkillsetsFilter}
                </MenuButton>
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuItem onClick={() => onFilterButtonClick(SkillsetFilterTypes.All)}>
                        {SkillsetFilterTypes.All}
                    </MenuItem>
                    <MenuItem onClick={() => onFilterButtonClick(SkillsetFilterTypes.On)}>
                        {SkillsetFilterTypes.On}
                    </MenuItem>
                    <MenuItem onClick={() => onFilterButtonClick(SkillsetFilterTypes.Off)}>
                        {SkillsetFilterTypes.Off}
                    </MenuItem>
                    <MenuItem onClick={() => onFilterButtonClick(SkillsetFilterTypes.NotSetUp)}>
                        {SkillsetFilterTypes.NotSetUp}
                    </MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}
