import {msalInstance, acquireMsalToken} from '@/util/msal';
import MedeinaVariables from '@/util/variables';
import MedeinaFeatures from '@/util/features';
import {CustomFetchOptions, FidelisErrorType, FeaturedFetch} from './api.types';
import {createTraceParent, formatTraceParent} from '@microsoft/1ds-core-js';
import {msalTokenRequest} from '@/util/msal/authConfig';
import i18n from 'i18next';
import {readFromLocalStorage} from '@/api/user/user';
import {hasStaticFeatureFlags, hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';
import securityGraphApi from './securityGraph.api';
import {RESOURCE_SCOPES} from './api.constants';
import {
    isUrlPathExcludedFromMsgRouting,
    isMultiWorkspacesEnabled,
} from './utils/multiWorkspaceUtils';

export * from './api.types';

const routingHintCookieName = 'x-msscp-routing-hint';
const routingHintHeaderName = 'X-MSSCP-Routing-Hint';

export const tenantIdUrlParam = () => {
    // Retrieve Tenant ID from the URL.
    if (typeof window !== 'undefined' && window.location.search) {
        return new URLSearchParams(window.location.search).get(MedeinaVariables.TenantIdUrlParam);
    }
};

// Determine the API endpoint based on feature flags.
// This is applied on page load for performance and to prevent potential conflicts with navigation.
export const apiUri = (() => {
    // For development environments, override the default API if enabled via feature flag.
    if (hasStaticFeatureFlags([MedeinaFeatures.LocalApi, 'localapi'])) {
        return MedeinaVariables.LocalApiUri;
    }

    // For Fidelis integration.
    if (hasStaticFeatureFlags([MedeinaFeatures.EnableFidelis, MedeinaFeatures.Fidelis])) {
        return MedeinaVariables.FidelisApiUri;
    }
    if (hasStaticFeatureFlags([MedeinaFeatures.EnableFidelis, MedeinaFeatures.FidelisAfd])) {
        return MedeinaVariables.FidelisAfdApiUri;
    }

    return MedeinaVariables.ApiUri;
})();

export const includePrototypeRoutingHeader = (() => {
    if (!MedeinaFeatures.PrototypeRoutingExperience) {
        return false;
    }
    const sessionStorageSignalPresent =
        typeof window !== 'undefined' &&
        window?.sessionStorage?.getItem(routingHintCookieName) === 'tiger';
    // const cookieSignalPresent =
    //     typeof window !== 'undefined' &&
    //     window?.document?.cookie
    //         ?.split(';')
    //         ?.some((cookie) => cookie.trim().startsWith(`${routingHintCookieName}=tiger`));
    const cookieSignalPresent = false;
    const featureFlagSignalPresent = hasStaticFeatureFlag('enableprototyperouting');
    return sessionStorageSignalPresent || cookieSignalPresent || featureFlagSignalPresent;
})();

export const setPrototypePreference = () => {
    if (MedeinaFeatures.PrototypeRoutingExperience) {
        window.sessionStorage.setItem(routingHintCookieName, 'tiger');
    }
};

export const clearPrototypePreference = () => {
    if (MedeinaFeatures.PrototypeRoutingExperience) {
        window.sessionStorage.removeItem(routingHintCookieName);
    }
};

export const isUntrustedApi = apiUri !== MedeinaVariables.ApiUri;
export const isFidelisApi =
    apiUri !== MedeinaVariables.ApiUri &&
    (apiUri === MedeinaVariables.FidelisApiUri || apiUri === MedeinaVariables.FidelisAfdApiUri);

// .env value
const envLocalizationEnabled = hasStaticFeatureFlag(MedeinaFeatures.EnableLocalization);
// URL param flag
const showAppLanguage = hasStaticFeatureFlag(MedeinaFeatures.ShowAppLanguage);
// ECS flag
const disableLocalization = hasStaticFeatureFlag(MedeinaFeatures.DisableLocalization);
const isLocalizationEnabled = (envLocalizationEnabled || showAppLanguage) && !disableLocalization;

// A custom fetch alias that includes MSAL authorization.
export const customFetch: FeaturedFetch = async <T>(
    url: string,
    {
        method = 'GET',
        body,
        headersFromOptions,
        scopes = msalTokenRequest,
        featureFlags,
    }: CustomFetchOptions = {},
    returnResponse: boolean = false,
    redirectCount: number = 0,
): Promise<T> => {
    // Grab the sharing token if there is one.
    // This would be in the URL's query params as `st=SHARING_TOKEN`.
    let sharingToken: string | null = null;
    if (typeof window !== 'undefined' && window.location.search) {
        const params = new URLSearchParams(window.location.search);
        sharingToken = params.get('st') ?? null;
    }

    let completeUrl: string;
    if (url.startsWith('http')) {
        completeUrl = url;
    } else {
        // Construct the complete URL.
        completeUrl = `${apiUri}${url}`;
        if (isMultiWorkspacesEnabled() && !isUrlPathExcludedFromMsgRouting(url)) {
            console.log('Routing through security graph API');
            completeUrl = securityGraphApi({path: '/securitycopilot' + url, version: ''});
            scopes = RESOURCE_SCOPES.FIDELIS;
        }
    }

    return new Promise<T>((resolve, reject) => {
        acquireMsalToken(msalInstance, scopes)
            .then(async (accessToken) => {
                if (accessToken) {
                    //Generate traceparent
                    const traceParent = createTraceParent();

                    // Set the default headers.
                    const headers: Record<string, string> = {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        ...headersFromOptions,
                        Authorization: `Bearer ${accessToken}`,
                        traceparent: formatTraceParent(traceParent),
                    };

                    if (isLocalizationEnabled && !readFromLocalStorage().useBrowserLanguage) {
                        headers['Accept-Language'] = i18n.language;
                    }

                    // Include the sharing token, if applicable.
                    if (sharingToken) {
                        // @ts-ignore
                        headers['X-Medeina-Sharing'] = sharingToken;
                    }

                    if (hasStaticFeatureFlag(MedeinaFeatures.Debug)) {
                        headers['X-Medeina-Portal-Debug'] = 'true';
                    }

                    // Check if the body is FormData (file upload)
                    if (body instanceof FormData) {
                        // Adjust headers for FormData, remove Content-Type so it can be set automatically
                        delete (headers as Record<string, any>)['Content-Type'];
                    }

                    if (includePrototypeRoutingHeader) {
                        // @ts-ignore
                        headers[routingHintHeaderName] = 'tiger';
                    }

                    // Call the API.
                    let response;
                    try {
                        response = await fetch(`${completeUrl}`, {
                            method,
                            headers,
                            body:
                                typeof body === 'string' || body instanceof FormData
                                    ? body
                                    : JSON.stringify(body),
                        });
                    } catch (error) {
                        // If an error occurred at this stage, the API request failed at the network layer.
                        // This can happen for a couple main reasons:
                        // - Service outage
                        // - Blocked for security reasons by the WAF with a response header:
                        //   `Server: Microsoft-Azure-Application-Gateway/v2`
                        reject(
                            new FetchApiError((error as Error)?.message ?? 'Failed to fetch', null),
                        );
                        return;
                    }

                    // If requesting a response object via  `fetch<Response>(,,true)`.
                    if (returnResponse) {
                        return resolve(response as T);
                    }
                    // Otherwise, return the JSON object.
                    else {
                        // Return data if there is a valid response
                        if (response.status >= 200 && response.status < 300) {
                            return headers.Accept && headers.Accept !== 'application/json'
                                ? response.text().then((text) => resolve(text as T))
                                : response.json().then((json) => resolve(json as T));
                        }
                        // 3xx: Redirects, follow the redirect and handle as normal
                        else if (response.status >= 300 && response.status < 400) {
                            const redirectUrl = response.headers.get('Location');
                            if (redirectUrl) {
                                if (redirectCount >= 5) {
                                    reject(
                                        new FetchApiError(
                                            `Too many redirects for "${url}".`,
                                            response,
                                        ),
                                    );
                                } else {
                                    return customFetch<T>(
                                        redirectUrl,
                                        {method, body, headersFromOptions, scopes},
                                        returnResponse,
                                        redirectCount + 1,
                                    );
                                }
                            } else {
                                reject(
                                    new FetchApiError(
                                        `Redirect without location for "${url}".`,
                                        response,
                                    ),
                                );
                            }
                        }
                        // 4xx: Client error, usually these are expected.
                        else if (response.status >= 400 && response.status < 500) {
                            reject(new ClientApiError(`Client API error for "${url}".`, response));
                        }
                        // 5xx: Server or unknown error.
                        else {
                            reject(new ServerApiError(`Server API error for "${url}".`, response));
                        }
                    }
                } else {
                    reject(
                        new AuthenticationApiError(`Unauthenticated API error for "${url}".`, null),
                    );
                }
            })
            .catch((error: Error) => {
                reject(error);
            });
    });
};

export abstract class ApiError extends Error {
    response?: Response | null;
    type: string;

    constructor(message: string, response: Response | null) {
        super(`${message}${response ? ` (${response?.status ?? '-'})` : ''}`);
        this.response = response;
        this.type = 'error';
    }
}

export class FetchApiError extends ApiError {
    constructor(message: string, response: Response | null) {
        super(message, response);
        this.type = 'fetch';
        this.name = 'FetchApiError';
    }
}

export class AuthenticationApiError extends ApiError {
    constructor(message: string, response: Response | null) {
        super(message, response);
        this.type = 'auth';
        this.name = 'AuthenticationApiError';
    }
}

export class ClientApiError extends ApiError {
    constructor(message: string, response: Response | null) {
        super(message, response);
        this.type = 'client';
        this.name = 'ClientApiError';
    }
}

export class ServerApiError extends ApiError {
    constructor(message: string, response: Response | null) {
        super(message, response);
        this.type = 'server';
        this.name = 'ServerApiError';
    }
}

// Encapsulation for translating Fidelis Error codes to Portal intelligible error handling
export class FidelisApiError extends ApiError {
    errorType: FidelisErrorType;
    rawErrorMessage: string;

    constructor(message: string, response: Response | null, errorType: FidelisErrorType) {
        super(message, response);
        this.type = 'fidelis';
        this.name = 'FidelisApiError';
        this.errorType = errorType;
        this.rawErrorMessage = message;
    }
}
