import {useState, useMemo, useEffect, useCallback} from 'react';
import {Field, FieldProps, Input, InputProps, InputOnChangeData} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import {Capacity} from '@/api/capacities';
import {useGetWorkspaces, Workspace} from '@/api/workspaces';
import {ValidationState, FieldValidationState} from '@/components/ui/forms';

interface WorkspaceNameProps {
    value?: string;
    required?: boolean;
    onChange?: (value: string) => void;
    fieldProps?: FieldProps;
    preventDuplicate?: boolean;
    disabled?: boolean;
}

export default function WorkspaceName(props: WorkspaceNameProps) {
    const {preventDuplicate, value: propValue, required, disabled, onChange} = props;
    // translation related functions by namespace
    const {t} = useTranslation('admin');
    const [value, setValue] = useState<string>(propValue || '');

    // when showOnlyAssignableCapacities is true, we only show capacities that are assignable to the workspace
    // we enable fetching of workspaces to get the assignable capacities
    const {data: workspacesData, error: workspaceError} = useGetWorkspaces({
        enabled: preventDuplicate,
    });

    // the value of the component is handled externally, update the internal value only when the prop value changes
    useEffect(() => {
        if (propValue !== value) {
            setValue(propValue || '');
        }
        const workspace = workspacesData?.value.find((w: Workspace) => w.name === propValue);
        if (workspace) {
            setValidationState({
                error: true,
                message: t('ManageWorkspaces.Errors.WorkspaceNameTaken'),
            });
        } else {
            setValidationState({});
        }
        // only trigger change when the prop value changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propValue]);

    const [validationState, setValidationState] = useState<FieldValidationState>({});

    // When the input changes and we have a value AND we are preventing duplicates
    // use the workspace data to update the validation state of the input
    const handleInputChange: InputProps['onChange'] = useCallback(
        (_ev: any, data: InputOnChangeData) => {
            // TODO: add regex test for valid workspace name
            if (preventDuplicate && data.value) {
                const workspace = workspacesData?.value.find(
                    (w: Workspace) => w.name === data.value,
                );
                if (workspace) {
                    setValidationState({
                        error: true,
                        message: t('ManageWorkspaces.Errors.WorkspaceNameTaken'),
                    });
                } else {
                    setValidationState({});
                }
            }
            // because we support internal and external values we need to call the onChange prop and check if the values are different
            if (onChange && data.value !== value) {
                onChange(data.value);
            }
            setValue(data.value);
        },
        [],
    );

    const fieldValidationProps = useMemo(() => {
        if (validationState.error) {
            return {
                validationState: 'error' as ValidationState,
                validationMessage: validationState.message,
            };
        }
        // if there's no error return a blank object that will be spread over Field
        return {};
    }, [validationState]);

    return (
        <Field
            label={t('Workspaces.Form.WorkspaceName')}
            {...fieldValidationProps}
            required={required}
        >
            <Input
                data-test-id="workspace-name"
                onChange={handleInputChange}
                value={value}
                disabled={disabled}
            />
        </Field>
    );
}
