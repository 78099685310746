import {readFromSessionStorage, writeToSessionStorage} from '@/util/sessionStorage.util';
import {rest, RestRequest} from 'msw';
import {merge as _merge} from 'lodash-es';

/** The namespace for msw cfs related items in session storage
 *  items stored using these utility functions will be prefixed with this namespace
 *  @example cfs.msw.tenantInfo
 *
 * For data that is meant to be read as a group/list you can use a combined key like cfs.msw.tenantInfo.[someTenantId]
 * to store tenant specific data and read as a list using getItemList('tenantInfo') which returns an array of all items that match the namespace
 *
 * To update an item in the list you can use patchItem('tenantInfo.[someTenantId]', {newData: 'newData'})
 * which will replace the new data with the existing data and still allow getItemList('tenantInfo') to return the updated list
 */
export const msw_namespace = 'cfs.msw';

/** returns the full sessionStorage path made up of msw_namespace and the passed in key string */
function getPath(key: string) {
    return `${msw_namespace}.${key}`;
}

/** A set of utility functions that mimics REST endpoints to store data during testing in session storage */
export const getItem = (key: string) => {
    return readFromSessionStorage(getPath(key));
};

/** utility function that will return all items that match a regex namespace using getItem
 * @example getItemList('capacities') will return all items that start with cfs.msw.capacities.*  in session storage
 */
export const getItemList = (keyName: string) => {
    const keys = Object.keys(sessionStorage);
    const namespace = getPath(keyName);
    const items = keys.filter((key) => key.startsWith(namespace));
    // using our existing utility function get the items concatenated with the namespace
    return items.map((item) => readFromSessionStorage(item));
};

/** Writes a key value pair to session storage */
export const putItem = (key: string, value: object) => {
    writeToSessionStorage(getPath(key), value);
};

/** Deletes an item from session storage */
export const deleteItem = (key: string) => {
    sessionStorage.removeItem(getPath(key));
};

/** Updates an item in session storage by merging the stored value with the passed in value */
export const patchItem = (key: string, value: object) => {
    const currentValue = getItem(key);
    const newValue = _merge(currentValue || {}, value);
    // debugger;
    putItem(key, newValue);
};

/** Indicates whether mswItems exist in session storage */
export const hasMswItems = () => {
    return Object.keys(sessionStorage).some((key) => key.startsWith(msw_namespace));
};

/** Delete all msw related sessionstorage items */
export const clearMswItems = () => {
    Object.keys(sessionStorage).forEach((key) => {
        if (key.startsWith(msw_namespace)) {
            sessionStorage.removeItem(key);
        }
    });
};

export const createInspectingHandler = (path: string, debug: boolean = false) =>
    rest.all(path, async (req, res, ctx) => {
        console.log(`Inspecting request to ${path}:`, req);
        if (debug) {
            debugger; // Add a breakpoint here
        }

        const originalResponse = await ctx.fetch(req);

        console.log(`Original response from ${path}:`, originalResponse);

        // Check if the response has a body
        const hasBody =
            originalResponse.headers.get('content-length') !== '0' &&
            originalResponse.headers.get('content-type') !== null;

        return hasBody
            ? res(ctx.status(originalResponse.status), ctx.json(await originalResponse.json()))
            : res(ctx.status(originalResponse.status));
    });

export const isPlayWrightRequest = (req: RestRequest) => {
    return req.headers.get('x-playwright-request') !== null;
};

export async function writeWithRetry(key: string, value: object, maxRetries = 5, delay = 100) {
    let retries = 0;

    while (retries < maxRetries) {
        try {
            // Attempt to write to sessionStorage
            sessionStorage.setItem(key, JSON.stringify(value));

            // Check if the item exists (sometimes it might fail silently)
            if (sessionStorage.getItem(key)) {
                return; // Success!
            }
        } catch (error) {
            console.error(`Error writing to sessionStorage (retry ${retries + 1}):`, error);
        }

        retries++;
        await new Promise((resolve) => setTimeout(resolve, delay)); // Wait before retrying
    }
    console.log('now throwing');
    throw new Error(`Failed to write to sessionStorage after ${maxRetries} retries.`);
}
