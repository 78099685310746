import {FilterButton, FilterButtonRow} from '../../../Buttons/FilterButton';
import useClasses from '../../ConnectorModalContent.styles';
import {useTranslation} from 'react-i18next';
import {SkillsetFilterTypes} from '@/api/skills';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface StatusFilterButtonsProps {
    onFilterButtonClick: React.Dispatch<React.SetStateAction<SkillsetFilterTypes>>;
    displayedSkillsetsFilter: SkillsetFilterTypes;
    enabledCount: number;
    disabledCount: number;
    notConfiguredCount: number;
    totalCount: number;
}

// Status filter buttons for the filtering toolbar in the "Manage plugins" popup
export default function StatusFilterButtons({
    onFilterButtonClick,
    displayedSkillsetsFilter,
    enabledCount,
    disabledCount,
    notConfiguredCount,
    totalCount,
}: StatusFilterButtonsProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const isAdvancedPluginFilteringEnabled = useFeatureFlag(
        MedeinaFeatures.EnableAdvancedPluginFiltering,
    );

    return (
        <FilterButtonRow className={classes.filterButtonRow}>
            <FilterButton
                size="small"
                name={SkillsetFilterTypes.All}
                onClick={() => onFilterButtonClick(SkillsetFilterTypes.All)}
                checked={displayedSkillsetsFilter == SkillsetFilterTypes.All}
            >
                {t('ContentModal.AllPlugins', {totalCount: totalCount})}
            </FilterButton>
            <FilterButton
                size="small"
                name={SkillsetFilterTypes.On}
                onClick={() => onFilterButtonClick(SkillsetFilterTypes.On)}
                checked={displayedSkillsetsFilter == SkillsetFilterTypes.On}
            >
                {t('ContentModal.AllPluginsTurnedOn', {enabledCount})}
            </FilterButton>
            <FilterButton
                size="small"
                name={SkillsetFilterTypes.Off}
                onClick={() => onFilterButtonClick(SkillsetFilterTypes.Off)}
                checked={displayedSkillsetsFilter == SkillsetFilterTypes.Off}
            >
                {t('ContentModal.AllPluginsTurnedOff', {disabledCount})}
            </FilterButton>
            {isAdvancedPluginFilteringEnabled && (
                <FilterButton
                    size="small"
                    name={SkillsetFilterTypes.NotSetUp}
                    onClick={() => onFilterButtonClick(SkillsetFilterTypes.NotSetUp)}
                    checked={displayedSkillsetsFilter == SkillsetFilterTypes.NotSetUp}
                >
                    {t('ContentModal.AllPluginsNotSetUp', {notConfiguredCount})}
                </FilterButton>
            )}
        </FilterButtonRow>
    );
}
