import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {motion} from 'framer-motion';
import {
    mergeClasses,
    Label,
    Input,
    Checkbox,
    Link,
    Option,
    OptionGroup,
    Dropdown,
    useId,
} from '@fluentui/react-components';
import {overlayAnimation} from '@/components/ui/Tour/Overlay';
import useClasses from './Setup.styles';
import useTourClasses from '@/components/sections/tours/TourContainer.styles';

import SetupInProgress from './SetupInprogress';
import SubscriptionSelector from './SubscriptionSelector';
import ResourceGroupSelector from './ResourceGroupSelector';
import {useTourContext, TourStageContainer, TourControls} from '@/components/ui/Tour';
import {TOUR_ERRORS, TOUR_STEPS, TourErrorTypes} from './AdminTour.types';
import {useTranslation} from 'react-i18next';
import {GEO_DATA, REGION_DATA} from '@/components/App.constants';
import MedeinaVariables from '@/util/variables';
import {FreStage, useManageTenantInfo} from '@/api/tenant';
import {ValidTourErrorPropCombination} from '@/components/ui/Tour';
import useManageTourTransition from './useManageTourTransition';
import {useNavigate} from 'react-router-dom';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {useSetupAndProvisionFidelisConstructs} from '@/api/capacities';
import {useQueryClient} from '@tanstack/react-query';
import useLocations from '../../../../../util/useLocations';

import CapacityUnitsField from './SetupFields/CapacityUnits';
import CapacityNameField from './SetupFields/CapacityName';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {ScreenSize, useViewportSize} from '@/components/ui/Grid';
import {Body, ParagraphContent, SmallFooterContent, TextH1} from '@/components/ui/Text';
import {SubscriptionSelectionResponse} from './SubscriptionSelector';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export const Setup = () => {
    const setupWrapper = useRef(null);

    const tourClasses = useTourClasses();
    const classes = useClasses();
    const tour = useTourContext();
    const {t} = useTranslation('tours');
    const capacityNameLabelId = useId('capacity-name');
    const promptLocationLabelId = useId('prompt-location');
    const capacityRegionLabelId = useId('capacity-region');
    const [selectedSubscription, setSelectedSubscription] = useState<string>('');
    const [resourceGroupName, setResourceGroupName] = useState<string>('');
    const [region, setRegion] = useState<string>('');
    const [regionDisplayName, setRegionDisplayName] = useState<string>('');
    const [geo, setGeo] = useState<string>('');
    const [capacityName, setCapacityName] = useState<string>('');
    const [capacityUnits, setCapacityUnits] = useState<number>(0);
    const [pricePerUnit, setPricePerUnit] = useState<number>(0);
    const [maxCapacity, setMaxCapacity] = useState<number>(0);
    const [minCapacity, setMinCapacity] = useState<number>(0);
    const [isNewResourceGroup, setIsNewResourceGroup] = useState<boolean>(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [crossRegionEvaluationChecked, setCrossRegionEvaluationChecked] = useState(false);
    const [isSettingUp, setIsSettingUp] = useState(false);
    const [isSubscriptionValid, setIsSubscriptionValid] = useState(false);

    const {validatePreCapacityOnCreateSetup} = useManageTourTransition();

    const {sm: isSmallScreen} = useViewportSize();

    const labelSize = isSmallScreen ? 'small' : 'medium';

    const {setTourErrorV2} = useTourContext();
    const navigate = useNavigate();
    const {update: providerUpdate} = useWorkspaceState();
    const queryClient = useQueryClient();

    const handleSubscriptionSelect = (subscription: string) => {
        setSelectedSubscription(subscription);
    };

    const handleResourceGroupSelect = (resourceGroupName: string, isNewResourceGroup: boolean) => {
        setResourceGroupName(resourceGroupName);
        setIsNewResourceGroup(isNewResourceGroup);
    };

    const handleGeoSelect = (geoKey: keyof typeof GEO_DATA) => {
        const selectedGeo = GEO_DATA[geoKey];
        if (!selectedGeo) {
            console.error(`Geo ${geoKey} not found in GEO_DATA`);
            return;
        }
        // Use the selected geo to find the default region, then set the corresponding region display name
        setGeo(geoKey);
        const defaultRegionKey = selectedGeo.defaultAzureRegion;
        setPricePerUnit(selectedGeo.pricing);
        setMaxCapacity(selectedGeo.maxCapacity);
        setMinCapacity(selectedGeo.minCapacity);
        if (capacityUnits === 0) {
            setCapacityUnits(1);
        }
        setRegion(defaultRegionKey);
        const regionDisplayName = REGION_DATA[defaultRegionKey].displayName;
        setRegionDisplayName(regionDisplayName);
    };

    const handleContinueClick = async () => {
        try {
            const step = await validatePreCapacityOnCreateSetup();

            if (step === TOUR_STEPS.None) {
                setIsSettingUp(true);
                await provisionCapacityLinkage();
            } else {
                if (step === TOUR_STEPS.HOME) {
                    await providerUpdate();
                    await queryClient.invalidateQueries(['auth', 'getUserInfo']);
                    navigate('/');
                    tour.quitTour();
                } else {
                    tour.setTourStep(step);
                }
            }
        } catch (error) {
            setIsSettingUp(false);
            // Handle the error here
            console.error(error);
        }
    };

    const setupScreenError = (error: ValidTourErrorPropCombination) => {
        setIsSettingUp(false);
        setTourErrorV2(error);
    };
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {provisionCapacityLinkage} = useSetupAndProvisionFidelisConstructs({
        capacityName,
        isNewResourceGroup,
        subscription: selectedSubscription,
        resourceGroup: resourceGroupName,
        geo,
        region,
        capacityUnits,
        isCapacityProvisioned: false,
        isCrossRegionAllowed: crossRegionEvaluationChecked,
        isWorkspacesTestingEnabled: isWorkspacesTestingEnabled,

        onAccountCreationError: (error) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.CreateCapacity,
            });
        },
        onCapacityCreationError: (error) => {
            if (error.includes('permission')) {
                setupScreenError({
                    ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                    actionButtonStep: TOUR_STEPS.CreateCapacity,
                });
            } else {
                setupScreenError({
                    ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                    actionButtonStep: TOUR_STEPS.CreateCapacity,
                });
            }
        },
        onWorkspaceProvisionError: (error) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.CreateCapacity,
            });
        },
        onWorkspaceMappingError: (error) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.CreateCapacity,
            });
        },
        onWorkspaceMappingCompletion: async () => {
            await performPostWorkspaceMappingOperations();
        },
        onResourceGroupCreationError: (error) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.CreateCapacity,
            });
        },
        onSubscriptionRegistrationError: (error) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.CreateCapacity,
            });
        },
    });

    const {patchFreMetadata} = useManageTenantInfo({
        onFetchTenantError: (error: any) => {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.CreateCapacity,
            });
        },
    });

    const performPostWorkspaceMappingOperations = async function () {
        try {
            await patchFreMetadata(FreStage.CapacitySetup);
            setIsSettingUp(false);
            //Go to the next page on the FRE
            tour.addTourSharedParameter('capacityStep', 'Create');
            tour.setTourStep(TOUR_STEPS.Locations);
        } catch (error) {
            setupScreenError({
                ...TOUR_ERRORS[TourErrorTypes.CapacityError],
                actionButtonStep: TOUR_STEPS.CreateCapacity,
            });
        }
    };

    const {geoOptions} = useLocations();
    const geoDropdownOptions = useMemo(() => {
        return geoOptions.map(({key, displayName}) => (
            <Option key={key} value={key}>
                {displayName}
            </Option>
        ));
    }, [geoOptions]);

    const handleSubscriptionValidation = useCallback(
        (response: SubscriptionSelectionResponse) => {
            const {validationState} = response;
            if (validationState === 'valid') {
                setIsSubscriptionValid(true);
            } else if (validationState === 'invalid' || validationState === 'empty') {
                setIsSubscriptionValid(false);
                // reset the resource group name if the subscription is invalid
                if (resourceGroupName) {
                    setResourceGroupName('');
                }
            }
        },
        [resourceGroupName],
    );

    // Calculate whether the continue button should be disabled
    const isContinueButtonDisabled =
        !selectedSubscription ||
        !resourceGroupName ||
        !capacityName ||
        !capacityUnits ||
        !isSubscriptionValid;
    !termsChecked;
    return (
        <>
            <motion.div
                key="terms"
                variants={overlayAnimation}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <TourStageContainer ref={setupWrapper}>
                    <TourStageContainer.StageContent>
                        <>
                            {isSettingUp ? (
                                <SetupInProgress
                                    heading={t('adminV2Fre.setup.InProgress')}
                                    subHeading={t('adminV2Fre.setup.Polite')}
                                ></SetupInProgress>
                            ) : (
                                <>
                                    <div className={tourClasses.tourXLargeSectionSpacing}>
                                        <div
                                            className={mergeClasses(tourClasses.tourSectionSpacing)}
                                        >
                                            <TextH1>{t('adminV2Fre.setup.HeaderContent')}</TextH1>
                                        </div>
                                        <div className={tourClasses.tourXLargeSectionSpacing}>
                                            <Body>{t('adminV2Fre.setup.Title')}</Body>
                                        </div>
                                        <SubscriptionSelector
                                            onSubscriptionSelect={handleSubscriptionSelect}
                                            onSubscriptionValidation={handleSubscriptionValidation}
                                            needsResponsiveStyles={true}
                                        ></SubscriptionSelector>
                                        <ResourceGroupSelector
                                            disabled={!selectedSubscription || !isSubscriptionValid}
                                            selectedSubscription={selectedSubscription}
                                            onResourceGroupSelect={handleResourceGroupSelect}
                                        ></ResourceGroupSelector>
                                        <div className={classes.field}>
                                            <div
                                                className={mergeClasses(
                                                    classes.leftAlignedContainer,
                                                    classes.label,
                                                )}
                                            >
                                                <MedeinaInfoLabel
                                                    labelContent={
                                                        <Label
                                                            id={capacityNameLabelId}
                                                            data-testid="capacity-name-label"
                                                            required
                                                            size={labelSize}
                                                        >
                                                            {t('adminV2Fre.setup.Capacity')}
                                                        </Label>
                                                    }
                                                    infoContent={t('adminV2Fre.setup.CapacityName')}
                                                    size="small"
                                                />
                                            </div>
                                            <div
                                                className={mergeClasses(
                                                    classes.leftAlignedContainer,
                                                    classes.columnLayout,
                                                )}
                                            >
                                                <CapacityNameField
                                                    setCapacityName={setCapacityName}
                                                    capacityName={capacityName}
                                                    labelSize={labelSize}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.field}>
                                            <div
                                                className={mergeClasses(
                                                    classes.leftAlignedContainer,
                                                    classes.label,
                                                )}
                                            >
                                                <MedeinaInfoLabel
                                                    labelContent={
                                                        <Label
                                                            id={promptLocationLabelId}
                                                            data-testid="prompt-evaluation-location-label"
                                                            required
                                                            size={labelSize}
                                                        >
                                                            {t(
                                                                'adminV2Fre.setup.PromptEvalLocation',
                                                            )}
                                                        </Label>
                                                    }
                                                    infoContent={t(
                                                        'adminV2Fre.setup.PromptEvaluation',
                                                    )}
                                                    size="small"
                                                />
                                            </div>
                                            <div className={classes.leftAlignedContainer}>
                                                <Dropdown
                                                    placeholder={t(
                                                        'adminV2Fre.setup.PlaceholderGeo',
                                                    )}
                                                    className={classes.dropdownSelector}
                                                    aria-required="true"
                                                    aria-label={t(
                                                        'adminV2Fre.setup.ariaLabel.PromptEvaluationLocation',
                                                    )}
                                                    data-testid="prompt-evaluation-location"
                                                    onOptionSelect={(event: any, data: any) => {
                                                        handleGeoSelect(data.optionValue);
                                                    }}
                                                >
                                                    <OptionGroup className="comboOptionGroup">
                                                        {geoDropdownOptions}
                                                    </OptionGroup>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className={mergeClasses(
                                                    classes.leftAlignedContainer,
                                                    classes.checkBoxContentLabel,
                                                )}
                                            >
                                                <span>
                                                    <Checkbox
                                                        className={mergeClasses(
                                                            classes.checkboxStyle,
                                                            classes.promptEvalLocationCheckBoxStyle,
                                                        )}
                                                        id="crossRegion"
                                                        data-testid="crossRegion-checkbox"
                                                        aria-label={t(
                                                            'adminV2Fre.setup.ariaLabel.CrossRegionCheckbox',
                                                        )}
                                                        checked={crossRegionEvaluationChecked}
                                                        aria-checked={crossRegionEvaluationChecked}
                                                        onChange={() =>
                                                            setCrossRegionEvaluationChecked(
                                                                (checkedValue) => !checkedValue,
                                                            )
                                                        }
                                                    />
                                                </span>
                                                <span>
                                                    <ParagraphContent
                                                        className={classes.allowCrossRegionLabel}
                                                        data-test-id="cross-region-label"
                                                    >
                                                        {t(
                                                            'adminV2Fre.setup.CrossRegionEvaluation',
                                                        )}
                                                    </ParagraphContent>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={classes.field}>
                                            <div
                                                className={mergeClasses(
                                                    classes.leftAlignedContainer,
                                                    classes.label,
                                                )}
                                            >
                                                <MedeinaInfoLabel
                                                    labelContent={
                                                        <Label
                                                            id={capacityRegionLabelId}
                                                            data-testid="azure-region-label"
                                                            required
                                                            size={labelSize}
                                                        >
                                                            {t('adminV2Fre.setup.AzureRegionLabel')}
                                                        </Label>
                                                    }
                                                    infoContent={t('adminV2Fre.setup.AzureRegion')}
                                                    size="small"
                                                />
                                            </div>
                                            <div className={classes.leftAlignedContainer}>
                                                <Input
                                                    className={mergeClasses(
                                                        classes.textFieldStyle,
                                                        tourClasses.textBox,
                                                    )}
                                                    required
                                                    type="text"
                                                    aria-label={t(
                                                        'adminV2Fre.setup.ariaLabel.AzureRegion',
                                                    )}
                                                    data-testid="compute-input"
                                                    value={regionDisplayName}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={tourClasses.tourXLargeSectionSpacing}>
                                        <h1
                                            className={mergeClasses(tourClasses.tourSectionSpacing)}
                                        >
                                            <TextH1>{t('adminV2Fre.setup.NoOfUnits')}</TextH1>
                                        </h1>
                                        <div className={tourClasses.tourXLargeSectionSpacing}>
                                            <Body>
                                                {t('adminV2Fre.setup.ComputeUnits', {
                                                    cost: pricePerUnit,
                                                })}
                                            </Body>
                                        </div>

                                        <CapacityUnitsField
                                            computeUnitsStyle={tourClasses.textBox}
                                            maxCapacity={maxCapacity}
                                            minCapacity={minCapacity}
                                            capacityUnits={capacityUnits}
                                            setCapacityUnits={setCapacityUnits}
                                            pricePerUnit={pricePerUnit}
                                            disabled={geo === ''}
                                            needsResponsiveStyles={true}
                                        />
                                        <div className={classes.field}>
                                            <SmallFooterContent
                                                className={classes.readMoreLabel}
                                                data-test-id="read-more-label"
                                            >
                                                <>
                                                    {t('adminV2Fre.setup.ReadMoreAbout')}
                                                    <Link
                                                        className={classes.termsLinkStyle}
                                                        href={MedeinaVariables.FreReadMoreurl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {t('adminV2Fre.setup.ComputeUnitsLink')}
                                                    </Link>
                                                    {t('adminV2Fre.setup.PostReadMoreAbout')}
                                                </>
                                            </SmallFooterContent>
                                        </div>
                                    </div>
                                    <div className={classes.termsButtonWrapper}>
                                        <div className={classes.field}>
                                            <div className={classes.leftAlignedContainer}>
                                                <ParagraphContent
                                                    className={mergeClasses(
                                                        classes.termsLabel,
                                                        classes.checkBoxContentLabel,
                                                    )}
                                                    data-testid="terms-label"
                                                >
                                                    <>
                                                        <span>
                                                            <Checkbox
                                                                className={mergeClasses(
                                                                    classes.checkboxStyle,
                                                                    classes.termsAndConditionsCheckBoxStyle,
                                                                )}
                                                                id="termsCheckbox"
                                                                data-testid="terms-checkbox"
                                                                aria-label={t(
                                                                    'adminV2Fre.setup.ariaLabel.AcknowledgementCheckbox',
                                                                )}
                                                                required
                                                                checked={termsChecked}
                                                                onChange={() =>
                                                                    setTermsChecked(
                                                                        (checkedValue) =>
                                                                            !checkedValue,
                                                                    )
                                                                }
                                                            />
                                                        </span>
                                                        <span>
                                                            {t('adminV2Fre.setup.Acknowledgement')}
                                                            <Link
                                                                className={classes.termsLinkStyle}
                                                                href={
                                                                    MedeinaVariables.FreTermsAndConditionsUrl
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {t(
                                                                    'adminV2Fre.setup.TermsAndConditions',
                                                                )}
                                                            </Link>
                                                        </span>
                                                    </>
                                                </ParagraphContent>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    </TourStageContainer.StageContent>
                    <TourStageContainer.FooterContent>
                        {!isSettingUp && (
                            <TourControls
                                spanFullScreen={true}
                                nextButton={t('adminV2Fre.setup.Continue')}
                                handleNext={handleContinueClick}
                                isNextButtonDisabled={isContinueButtonDisabled}
                                showPrevious={false}
                            ></TourControls>
                        )}
                    </TourStageContainer.FooterContent>
                </TourStageContainer>
            </motion.div>
        </>
    );
};

export default Setup;
