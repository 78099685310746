import {useMutation} from '@tanstack/react-query';
import useFetch from '../useFetch';
import {OAuthAuthorizationCodeRequest, SettingsScope} from './skills.types';
import {useFeatureFlag} from '../user';
import MedeinaFeatures from '@/util/features';

export default function useGetSkillsetAuthToken() {
    const {customFetch} = useFetch();
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    return useMutation({
        mutationFn: async (body: OAuthAuthorizationCodeRequest) => {
            return await customFetch<Response>(
                `/skillsets/${body?.skillsetName}/oauth2/token?scope=${
                    isWorkspacesTestingEnabled ? SettingsScope.UserWorkspace : SettingsScope.User
                }`,
                {
                    method: 'POST',
                    body,
                },
            );
        },
    });
}
