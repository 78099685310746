import {useMutation, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {customFetch} from '../api';
import {RESOURCE_SCOPES} from '../api.constants';
import {GetWorkspaceCfSByNameResponse, Workspace, WorkspaceSettings} from './workspaces.types';
import securityGraphApi from '@/api/securityGraph.api';
interface UpdateWorkspaceSettingsParams {
    workspaceSettings: WorkspaceSettings;
    targetWorkspace?: Workspace;
}
export default function useUpdateWorkspaceSettings(
    useQueryOptions?: Pick<
        UseQueryOptions<GetWorkspaceCfSByNameResponse>,
        'enabled' | 'refetchOnMount'
    >,
) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({workspaceSettings, targetWorkspace}: UpdateWorkspaceSettingsParams) => {
            const getUrl = () =>
                securityGraphApi({
                    path: '/securitycopilot/WorkspaceSettings',
                    version: '',
                    targetWorkspace: targetWorkspace,
                });
            try {
                let workspaceData: WorkspaceSettings;
                try {
                    workspaceData = await customFetch<GetWorkspaceCfSByNameResponse>(getUrl(), {
                        method: 'GET',
                        scopes: RESOURCE_SCOPES.FIDELIS, // needed for authentication since we are calling FIDELIS
                    });
                } catch (error) {
                    throw error;
                }
                if (!workspaceData) {
                    throw new Error('Workspace not found');
                }
                return await customFetch<GetWorkspaceCfSByNameResponse>(getUrl(), {
                    method: 'PATCH',
                    body: workspaceSettings,
                    scopes: RESOURCE_SCOPES.FIDELIS,
                });
            } catch (e) {
                throw e;
            }
        },
        onSuccess: (data, variables, context) => {},
        onSettled: (data, error, variables, context) => {
            queryClient.removeQueries(['workspaces']);
        },
        onError: (error, variables) => {},
        retry: 0,
    });
}
