import {UseQueryOptions, useMutation} from '@tanstack/react-query';
import useFetch from '@/api/useFetch';
import {ErrorResponse} from '@/api/api.types';
import {HelloRequest} from './hello.types';

export default function useCreateHello() {
    const {customFetch} = useFetch();
    return useMutation(['hello'], {
        mutationFn: async (body: HelloRequest) => {
            const result = await customFetch<Response>(
                `/hello`,
                {
                    method: 'POST',
                    body: JSON.stringify(body),
                },
                true,
            );
            if (!result.ok) {
                const error: ErrorResponse = {
                    code: result.status,
                    message: result.statusText,
                };

                throw error;
            }
            const data = await result.json();
            return data;
        },
    });
}
