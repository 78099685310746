import React, {useEffect, useMemo} from 'react';
import {WorkspacesContext as IWorkspacesContext} from './WorkspacesProvider.types';
import {useWorkspacesOwnerCheck, useGetWorkspaces} from '@/api/workspaces';
import useGetCurrentWorkspace from '@/api/user/useGetCurrentWorkspace';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

/**
 * This will be a new wrapping component in portal that will take care of the data lookups for WorkspacesManagement operation to facilitate the rendering of privileged UI elements.
It will:
Get current workspace information
Use the UserWorkspaceSettings API to look up the user's last used/preferred workspace selection for us to load into (Date: TBD)
Lookup all workspaces for the user across all geos
useGlobalListWorkspaces : this is just calling the useGetWorkspaces hook repeated across each available region that we have
This data should be cached and will be what is also used to populate the data in the workspace selector
Provide a flag that tells the UI whether to render Management related items
Using the results of useGlobalListWorkspaces we'll want to get the workspace policies for each workspace and check if they are an owner on any of them
We can cache this result for some time so that we are not constantly checking for these permissions, if the user does try to do a privileged operation in the time that our data has become stale the backend services will reject the operation. At most they'll just have access to the UI for that time
 */

export const WorkspacesContext = React.createContext<IWorkspacesContext>({} as IWorkspacesContext);

interface WorkspacesContextProviderProps extends IWorkspacesContext {
    children: React.ReactNode;
}
function WorkspacesContextProvider(props: WorkspacesContextProviderProps) {
    const {children, ...rest} = props;
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {data: workspaceName} = useGetCurrentWorkspace({
        enabled: Boolean(isWorkspacesTestingEnabled),
    });

    const {
        data: workspaces,
        isLoading: workspacesIsLoading,
        isFetched: workspacesIsFetched,
        isError: workspacesIsError,
        isSuccess: workspacesIsSuccess,
    } = useGetWorkspaces();

    const {
        data: workspacesOwner,
        isLoading: workspacesOwnerIsLoading,
        isFetched: workspacesOwnerIsFetched,
        isError: workspacesOwnerIsError,
        isSuccess: workspacesOwnerIsSuccess,
        refetch: refetchWorkspacesOwner,
    } = useWorkspacesOwnerCheck(workspaceName, workspaces?.value);

    useEffect(() => {
        refetchWorkspacesOwner();
    }, [workspaces, workspaceName]);

    const contextValue: IWorkspacesContext = useMemo(() => {
        return {
            ...rest,
            ownerOfAWorkspace: workspacesOwner?.ownerOfAWorkspace,
            ownerOfSelectedWorkspace: workspacesOwner?.ownerOfSelectedWorkspace,
            ownedWorkspaces: workspacesOwner?.ownedWorkspaces,
            workspaces: workspaces?.value,
            isLoading: workspacesIsLoading || workspacesOwnerIsLoading,
            isFetched: workspacesIsFetched && workspacesOwnerIsFetched,
            isError: workspacesIsError || workspacesOwnerIsError,
            isSuccess: workspacesOwnerIsSuccess && workspacesIsSuccess,
        };
    }, [workspacesOwner, workspacesOwnerIsLoading, workspaces, workspacesIsLoading]);

    return <WorkspacesContext.Provider value={contextValue}>{children}</WorkspacesContext.Provider>;
}

export function useWorkspacesContext() {
    return React.useContext(WorkspacesContext);
}

export default WorkspacesContextProvider;
