import React, {useMemo} from 'react';
import {Dialog, DialogSurface, mergeClasses} from '@fluentui/react-components';
import useClasses from './UserSettingsModal.styles';
import Preferences from './Preferences';
import DataAndPrivacy from './DataAndPrivacy';
import About from './About';
import {useAuthorized} from '@/api/app';
import {useTranslation} from 'react-i18next';
import {TabbedDialog, TabConfiguration, useDialogClasses} from '@/components/ui/Dialog';
import {InfoIcon, PrivacyIcon, SettingsIcon} from '../ui/icons';

interface UserSettingsModalProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
}

export default function UserSettingsModal({setOpen, setMenuOpen, open}: UserSettingsModalProps) {
    const classes = useClasses();
    const dialogClasses = useDialogClasses();
    const {t} = useTranslation();
    const settingsName = t('MenuLinkSettings');
    const {data: authorized} = useAuthorized();

    const tabConfiguration: Array<TabConfiguration> = useMemo(() => {
        const settingsPreferenceLabel = t('SettingsPreferenceTab');
        const settingsDataPrivacyLabel = t('SettingsDataPrivacyTab');
        const settingsAboutLabel = t('SettingsAboutTab');
        return [
            {
                value: 'preferences',
                view: <Preferences />,
                title: settingsPreferenceLabel,
                tabProps: {
                    icon: <SettingsIcon />,
                },
            },
            {
                value: 'dataAndPrivacy',
                view: <DataAndPrivacy setOpen={setMenuOpen} />,
                title: settingsDataPrivacyLabel,
                tabProps: {
                    icon: <PrivacyIcon />,
                },
            },
            {
                value: 'about',
                view: <About />,
                title: settingsAboutLabel,
                tabProps: {
                    icon: <InfoIcon />,
                },
            },
        ];
    }, [setMenuOpen, t]);

    if (!authorized?.isAuthorized) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onOpenChange={(_, data) => {
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    setOpen(data.open);
                }
            }}
        >
            <DialogSurface
                className={mergeClasses(dialogClasses.dialogSurface, classes.modalWrapper)}
            >
                <TabbedDialog
                    homeTitle={settingsName}
                    onClose={() => setOpen(false)}
                    tabConfiguration={tabConfiguration}
                />
            </DialogSurface>
        </Dialog>
    );
}
