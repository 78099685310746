import {Button, Image, Text} from '@fluentui/react-components';
import useClasses from '../../AdminConfigurationModalContent.styles';
import {SettingsScope, SkillsetDescriptor} from '@/api/skills';
import {DismissIcon, ResetIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '@/api/user';

interface ConfirmationModalProps {
    selectedSkillset: string | null;
    selectedSkillsetDescriptor: SkillsetDescriptor | null;
    scope: SettingsScope;
    icon: string | undefined;
    onReset: () => void;
    onCancel: () => void;
}

export default function ConfirmationModal({
    selectedSkillset,
    selectedSkillsetDescriptor,
    scope,
    icon,
    onReset,
    onCancel,
}: ConfirmationModalProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    return (
        <div className={classes.confirmationModal}>
            <Image
                className={classes.resetIcon}
                height={48}
                width={48}
                fit="default"
                src={icon}
                aria-hidden
                alt=""
            />
            <Text className={classes.resetTitleText} as="h1">
                {t('Admin.ResetPluginSettings', {pluginName: selectedSkillset})}
            </Text>
            <Text className={classes.resetText}>
                {scope == SettingsScope.User ||
                (isWorkspacesTestingEnabled ? scope == SettingsScope.UserWorkspace : false)
                    ? t('Admin.ResetWarningUserScope')
                    : isWorkspacesTestingEnabled
                    ? t('WorkspacesAdmin.ResetWarningWorkspaceScope')
                    : t('Admin.ResetWarningTenantScope')}
            </Text>
            <div className={classes.resetButtons}>
                <Button
                    className={classes.resetButton}
                    appearance="subtle"
                    size="medium"
                    icon={<ResetIcon />}
                    onClick={onReset}
                >
                    {t('common.Reset')}
                </Button>
                <Button
                    className={classes.cancelButton}
                    appearance="subtle"
                    size="medium"
                    icon={<DismissIcon />}
                    onClick={onCancel}
                >
                    {t('common.Cancel')}
                </Button>
            </div>
        </div>
    );
}
