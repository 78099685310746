import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    Button,
    Input,
} from '@fluentui/react-components';
import {DismissIcon} from '@/components/ui/icons';
import useUpdateSession from '@/api/sessions/useUpdateSession';
import React, {useState, useCallback, useEffect} from 'react';
import {UpdateSessionRequest} from '@/api/sessions/sessions.types';
import useClasses from './EditInvestigationNameDialog.styles';
import {useTranslation} from 'react-i18next';

/**
 * EditInvestigationNameDialog component allows the user to edit the name of an investigation
 * and save the changes.
 */

export default function EditInvestigationNameDialog({
    session,
    open,
    onClose = () => null,
    onSuccess = () => null,
}: UpdateSessionRequest) {
    //
    const {mutate: updateSessions} = useUpdateSession();
    const {t} = useTranslation(['mysessions']);
    const [isDisable, setDisabled] = useState<boolean>(true);
    const [editedInvestigationName, setEditedInvestigationName] = useState(session.name);
    const [previousInputValue, setPreviousInputValue] = useState(session.name);
    const classes = useClasses();

    useEffect(() => {
        setPreviousInputValue(session.name);
    }, [session]);

    //Updates the edited investigation name and enables/disables the save button based on the input value.
    //Disable the submit button when the input name is empty or unchnaged
    const handleOnChange = (name: string) => {
        if (name === previousInputValue || name === '') {
            setDisabled(true);
        } else setDisabled(false);

        setEditedInvestigationName(name);
    };

    //updatedInvestigation catches the updated name and modifies the editedInvestigation session object accordingly
    //updateSessions updates the name through an API call to backend
    const handleSubmit = () => {
        const updatedInvestigation = {...session, name: editedInvestigationName};
        updateSessions(updatedInvestigation, {onSuccess: onSuccess});
        setDisabled(true);
        setPreviousInputValue(editedInvestigationName);
    };

    //Handles the close event when the dialog is closed
    const handleClose = () => {
        onClose();
        setDisabled(true);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !isDisable) {
            handleSubmit();
        }
    };

    return (
        <Dialog
            modalType="modal"
            open={open}
            onOpenChange={(event, data) => {
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    handleClose();
                }
            }}
        >
            <DialogSurface className={classes.root} aria-describedby={undefined}>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label={t('CloseButton')}
                                    icon={<DismissIcon />}
                                    onClick={handleClose}
                                />
                            </DialogTrigger>
                        }
                    >
                        {t('EditNameDialogTitle')}
                    </DialogTitle>
                    <DialogContent className={classes.content}>
                        <Input
                            required
                            type="text"
                            aria-label={t('EditNameInputAriaLabel')}
                            defaultValue={session.name}
                            onChange={(event) => {
                                handleOnChange(event.target.value);
                            }}
                            onKeyDown={handleKeyDown}
                        />
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button onClick={handleClose} appearance="secondary">
                                {t('CancelButton')}
                            </Button>
                        </DialogTrigger>
                        <Button
                            disabled={isDisable}
                            type="submit"
                            appearance="primary"
                            onClick={handleSubmit}
                            data-testid="session-edit-confirm-button"
                        >
                            {t('SaveButtonLabel')}
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}
