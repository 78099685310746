import MedeinaVariables from '@/util/variables';
import {WorkspaceSessionData} from '@/components/sections/workspaces/WorkspacesProvider';
import {getCurrentWorkspaceCache} from '@/components/sections/workspaces/WorkspacesProvider/util';
import {Workspace} from './workspaces';
/** A utility function to help build Microsoft Security Graph APIs
 *  The utility will create return the full URL for the endpoint based on:
 *  the parameters passed in, the current workspace stored in session storage, and the feature flag for this utility
 */

interface MSGApiOptions {
    /** Request path */
    path: string;
    /** API version to append */
    version?: string;
    /** Global endpoints do not include geo/pod information */
    isGlobal?: boolean;
    /** Sets the path from podId and removes workspaces/{workspacename} */
    podPath?: boolean;
    // target workspace in case editing workspace configs of a workspace you're not currently in
    targetWorkspace?: Workspace;
}

const API_VERSION = '2024-03-01-preview';

export default function securityGraphApi(options: MSGApiOptions) {
    // Determine the API version and how we'll append
    let apiVersion = '';
    if (options.version && options.version !== '') {
        apiVersion = `${options.path.includes('?') ? '&' : '?'}api-version=${options.version}`;
    }
    // for cases where we don't want to append version like for calls to MSG to get CfS workspace settings data
    else if (options.version === '') {
        apiVersion = '';
    } else {
        apiVersion = `${options.path.includes('?') ? '&' : '?'}api-version=${API_VERSION}`;
    }
    let workspace: WorkspaceSessionData | null;
    if (options?.targetWorkspace) {
        // Get the current workspace from session storage, this was set during the app bootstrapping
        const workspaceData = {
            name: options.targetWorkspace?.name || '',
            path: options.targetWorkspace?.path || '',
            managementUrl: options.targetWorkspace?.managementUrl || '',
            podId: options.targetWorkspace?.path?.split('/')[1] || '',
        };
        workspace = workspaceData;
    } else {
        workspace = getCurrentWorkspaceCache();
    }

    if (workspace || options.isGlobal || options.podPath) {
        if (options.isGlobal) {
            const globalUrl = `${MedeinaVariables.SecurityGraphUri}${options.path}${apiVersion}`;
            return globalUrl;
        } else if (options.podPath) {
            const podUrl = `https://${workspace?.managementUrl}/pods/${workspace?.podId}${options.path}${apiVersion}`;
            return podUrl;
        } else {
            // workspace.path includes pod/{podID}/workspaces/{workspaceName}
            const podUrl = `https://${workspace?.managementUrl}/${workspace?.path}${options.path}${apiVersion}`;
            return podUrl;
        }
    }

    // If no workspace is found, default to the global endpoint
    const currentUrl = `${MedeinaVariables.SecurityGraphUri}${options.path}${apiVersion}`;
    return currentUrl;
}
