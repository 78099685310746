import React from 'react';
import useGetUserFeatureFlags from '@/api/user/useGetUserFeatureFlags';
import {UserFeatures} from '@/api/user/user.types';
import {msalInstance, isUserSignedIn} from '@/util/msal/authConfig';
import featureFlags from './staticFeatureFlags';

export const FeatureFlagContext = React.createContext<UserFeatures>(null!);

const emptyFeatureFlags: UserFeatures = {
    featureFlags: [],
    isEmailTriggerAllowed: false,
};

// a component that makes the call to /users/features and provides the feature flags to the rest of the app
// this blocks the rest of the application from loading until the feature flags are available
// it allows rendering the application if there is no user Authenticated so the rest of the application boots
function FeatureFlagProvider(props: any) {
    // check for URL parameter
    const isAuthenticated = isUserSignedIn(msalInstance);
    const {data, isLoading, isFetched, isError} = useGetUserFeatureFlags({
        enabled: isAuthenticated,
    });
    return isLoading && isAuthenticated && !data ? null : (
        <FeatureFlagContext.Provider
            value={
                isError && isAuthenticated
                    ? {featureFlags: featureFlags, isEmailTriggerAllowed: false}
                    : data || emptyFeatureFlags
            }
        >
            {props.children}
        </FeatureFlagContext.Provider>
    );
}

export default FeatureFlagProvider;
