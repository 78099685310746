import React, {useEffect, useState} from 'react';
import {Label, Link, Title1, mergeClasses} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';

import {usePatchTenantInfo} from '@/api/tenant';
import {useGetPluginManagementInfo} from '@/api/plugin';
import MedeinaFeatures from '@/util/features';
import {PluginRbacSettingScope} from '@/components/Navigation/Preferences/Preferences.types';
import {CustomSwitchTransition} from '@/components/ui/Switch/switch.types';
import PluginRbacManagementSetting from '@/components/Navigation/Preferences/PluginRbacManagement';
import ProgressiveSwitch from '@/components/ui/Switch';
import {MedeinaInfoLabel} from '@/components/ui/Buttons/MedeinaInfoLabel';
import {ErrorIcon} from '@/components/ui/icons';

import useClasses from '@/components/Navigation/Preferences.styles';
import useOrgClasses from './OrganizationData.styles';
import usePluginClasses from './PluginManagement/PluginManagement.styles';
import {SettingsProps} from './rbac/OwnerSettings.types';
import MedeinaVariables from '@/util/variables';
import PluginManagementList from './PluginManagement/PluginManagementList';
import {useFeatureFlag, useUserState} from '@/api/user';
import {useUpdateWorkspaceSettings, useGetWorkspaceSettings} from '@/api/workspaces';
import {WorkspaceSettings} from '@/api/workspaces/workspaces.types';
import SetPluginAvailabilityDialog from './PluginManagement/SetPluginAvailabilityDialog';

// Individual component that handles setting the plugin settings and rendering the error message if the update fails.
function PluginSettings(props: SettingsProps) {
    const {isAdmin, isGlobalAdmin, tenantInfo} = props;
    const classes = useClasses();
    const orgClasses = useOrgClasses();
    const pluginClasses = usePluginClasses();
    const {data: pluginManagementInfo} = useGetPluginManagementInfo();
    const {t} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const isTenantInfoSuccess =
        tenantInfo !== undefined && tenantInfo !== null && tenantInfo.tenantId;
    const [errorTagForO365Consent, setErrorTagForO365Consent] = useState<boolean>(false);
    const {mutateAsync: patchTenantInfo} = usePatchTenantInfo();
    const [updateO365Consent, setUpdateO365Consent] = useState<CustomSwitchTransition>({
        isChanging: false,
        isChangeSuccessful: false,
        hasChangeFailed: false,
    });
    const [updatePluginManagement, setUpdatePluginManagement] = useState<CustomSwitchTransition>({
        isChanging: false,
        isChangeSuccessful: false,
        hasChangeFailed: false,
    });
    const userPluginManagementAllowed = useFeatureFlag(
        MedeinaFeatures.IsUserPluginManagementAllowedByAdminEnabled,
    );
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const [isPluginAvailabilityDialogOpen, setPluginAvailabilityDialogOpen] =
        useState<boolean>(false);
    const [newPluginAvailabilityToggleValue, setNewPluginAvailabilityToggleValue] =
        useState<boolean>(false);

    // multi-workspaces
    const {data: workspace} = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const {mutate: updateWorkspace, isLoading: updateWorkspaceInfoLoading} =
        useUpdateWorkspaceSettings({enabled: Boolean(isWorkspacesTestingEnabled)});

    useEffect(() => {
        if (isTenantInfoSuccess) {
            setErrorTagForO365Consent(false);
        }
    }, [tenantInfo]);

    // Update O365 Compliance Flag and only Global Admin is Authorized to view and update.
    const {tenant: userTenant} = useUserState();
    const updateCompliancePreferences = async (allowO365DataCollection: boolean) => {
        setUpdateO365Consent({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        if (isWorkspacesTestingEnabled) {
            const workspaceSettings: WorkspaceSettings = {
                ...workspace,
                workspaceId: workspace?.workspaceId ?? '',
                tenantId: userTenant?.tenantId,
                complianceRequirements: {
                    allowO365DataCollection: {
                        enabled: allowO365DataCollection,
                    },
                },
                freRequirements: {
                    allowModelImprovement: workspace?.freRequirements?.allowModelImprovement,
                    allowProductImprovement: workspace?.freRequirements?.allowProductImprovement,
                    optOutDate: workspace?.freRequirements?.optOutDate,
                },
            };
            updateWorkspace(
                {
                    workspaceSettings,
                },
                {
                    onSuccess: () => {
                        setUpdateO365Consent({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                        setErrorTagForO365Consent(false);
                    },
                    onError: () => {
                        setUpdateO365Consent({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                        setErrorTagForO365Consent(true);
                    },
                },
            );
        } else {
            patchTenantInfo(
                {
                    ...tenantInfo,
                    name: tenantInfo?.name,
                    tenantId: tenantInfo?.tenantId,
                    complianceRequirements: {
                        allowO365DataCollection: {
                            enabled: allowO365DataCollection,
                        },
                    },
                },
                {
                    onSuccess: () => {
                        setUpdateO365Consent({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                        setErrorTagForO365Consent(false);
                    },
                    onError: () => {
                        setUpdateO365Consent({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                        setErrorTagForO365Consent(true);
                    },
                },
            );
        }
    };

    const updateUserPluginManagement = async (restrictUserPluginManagement: boolean) => {
        setUpdatePluginManagement({
            isChanging: true,
            isChangeSuccessful: false,
            hasChangeFailed: false,
        });
        if (isWorkspacesTestingEnabled) {
            const workspaceSettings: WorkspaceSettings = {
                ...workspace,
                workspaceId: workspace?.workspaceId ?? '',
                tenantId: userTenant?.tenantId,
                allowUserPluginManagement: !restrictUserPluginManagement,
                freRequirements: {
                    allowModelImprovement: workspace?.freRequirements?.allowModelImprovement,
                    allowProductImprovement: workspace?.freRequirements?.allowProductImprovement,
                    optOutDate: workspace?.freRequirements?.optOutDate,
                },
            };
            updateWorkspace(
                {
                    workspaceSettings,
                },
                {
                    onSuccess: () => {
                        setUpdatePluginManagement({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                    },
                    onError: () => {
                        setUpdatePluginManagement({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                    },
                },
            );
        } else {
            patchTenantInfo(
                {
                    ...tenantInfo,
                    // Invert the value of the "restricted" switch to get the "allowed" value
                    allowUserPluginManagement: !restrictUserPluginManagement,
                },
                {
                    onSuccess: () => {
                        setUpdatePluginManagement({
                            isChanging: false,
                            isChangeSuccessful: true,
                            hasChangeFailed: false,
                        });
                    },
                    onError: () => {
                        setUpdatePluginManagement({
                            isChanging: false,
                            isChangeSuccessful: false,
                            hasChangeFailed: true,
                        });
                    },
                },
            );
        }
    };

    // The setting for 365 can only be set by a global admin
    const needToDisableO365ConsentToggle = MedeinaFeatures.EnableO365ConsentStepInFRE
        ? !isAdmin
        : !isGlobalAdmin;

    // Display info label explaining why the tenant setting is disabled and overridden by user setting.
    const showUserSettingOverrideInfoLabel =
        isAdmin &&
        (isWorkspacesTestingEnabled && workspace
            ? workspace?.allowUserPluginUpload === false
            : tenantInfo?.allowUserPluginUpload === false);
    return (
        <div className={userPluginManagementAllowed ? pluginClasses.wrapper : undefined}>
            <Title1
                role="heading"
                className={userPluginManagementAllowed ? pluginClasses.title : classes.subtitle}
            >
                {userPluginManagementAllowed
                    ? t('SettingsPluginsManagementHeading')
                    : t('SettingsPluginsHeading')}
            </Title1>
            <PluginRbacManagementSetting
                tenantInfo={tenantInfo}
                pluginRbacScope={PluginRbacSettingScope.USER}
                isAdmin={isAdmin}
            />
            <PluginRbacManagementSetting
                tenantInfo={tenantInfo}
                pluginRbacScope={PluginRbacSettingScope.TENANT}
                isAdmin={isAdmin}
                overriddenLabelText={
                    showUserSettingOverrideInfoLabel
                        ? t('SettingsPluginsTenantUploadDescription')
                        : undefined
                }
            />
            {userPluginManagementAllowed && isAdmin && (
                <div>
                    <ProgressiveSwitch
                        label={t('SettingsPluginAvailabilityManagement')}
                        labelPosition="before"
                        className={classes.switch}
                        state={updatePluginManagement}
                        // Confirmation popup that appears when toggling this setting
                        onClick={(ev) => {
                            setNewPluginAvailabilityToggleValue(ev);
                            setPluginAvailabilityDialogOpen(true);
                        }}
                        // Switch is enabled when plugin management is restricted.
                        // Thus, the switch is checked when allowUserPluginManagement is false.
                        checked={
                            isWorkspacesTestingEnabled && workspace
                                ? !workspace?.allowUserPluginManagement
                                : !tenantInfo?.allowUserPluginManagement
                        }
                    />
                    <div className={classes.paragraphContent}>
                        {isWorkspacesTestingEnabled
                            ? t('WorkspacesSettingsPluginAvailabilityManagementDescription')
                            : t('SettingsPluginAvailabilityManagementDescription')}
                    </div>
                    {isWorkspacesTestingEnabled && workspace
                        ? workspace?.allowUserPluginManagement === false
                        : tenantInfo?.allowUserPluginManagement === false && (
                              <PluginManagementList
                                  tenantInfo={tenantInfo}
                                  pluginInfo={pluginManagementInfo}
                              />
                          )}
                    {/* Popup confirmation for enabling plugin management via toggle */}
                    <SetPluginAvailabilityDialog
                        isSetPluginAvailabilityDialogOpen={isPluginAvailabilityDialogOpen}
                        dialogTitle={
                            newPluginAvailabilityToggleValue
                                ? t(
                                      'SettingsPluginAvailabilityManagementToggleEnableConfirmationTitle',
                                  )
                                : t(
                                      'SettingsPluginAvailabilityManagementToggleDisableConfirmationTitle',
                                  )
                        }
                        dialogDescription={
                            newPluginAvailabilityToggleValue
                                ? t(
                                      'SettingsPluginAvailabilityManagementToggleEnableConfirmationDescription',
                                  )
                                : t(
                                      'SettingsPluginAvailabilityManagementToggleDisableConfirmationDescription',
                                  )
                        }
                        acceptTitle={
                            newPluginAvailabilityToggleValue
                                ? t('SettingsPluginAvailabilityManagementToggleEnableAcceptTitle')
                                : t('SettingsPluginAvailabilityManagementToggleDisableAcceptTitle')
                        }
                        onAccept={() => {
                            setPluginAvailabilityDialogOpen(false);
                            updateUserPluginManagement(newPluginAvailabilityToggleValue);
                        }}
                        onReject={() => {
                            setPluginAvailabilityDialogOpen(false);
                        }}
                    ></SetPluginAvailabilityDialog>
                </div>
            )}
            {!isAdmin && (
                <Label className={classes.warningLabel}>
                    *{t('SettingsPluginsAdminManagedLabel')}
                </Label>
            )}
            {MedeinaFeatures.EnableComplianceBasedPluginManagement && (
                <div>
                    <Title1 role="heading" className={classes.subtitle}>
                        {t('O365ConsentSettingsPluginsHeading')}
                    </Title1>
                    <div className={classes.paragraphContent}>
                        {t('O365ConsentSettingsPluginsInfoLabelV2')}{' '}
                        <Link
                            data-test-id="data-access-article-link"
                            href={MedeinaVariables.PrivacyAndDataSecurity}
                            target="_blank"
                        >
                            <div className={classes.linkContent}>
                                {t('O365ConsentSettingsPluginsMoreDetails')}{' '}
                            </div>
                        </Link>
                    </div>
                    <ProgressiveSwitch
                        data-testid="O365-compliance-toggle-switch"
                        label={
                            <div>
                                <MedeinaInfoLabel
                                    labelContent={t('O365ComplianceSwitchLabel')}
                                    infoContent={t('O365ComplianceSwitchInfoLabel')}
                                    defaultButtonWrap={true}
                                ></MedeinaInfoLabel>
                            </div>
                        }
                        labelPosition="before"
                        aria-label={t('O365ComplianceAriaLabel')}
                        disabled={needToDisableO365ConsentToggle}
                        checked={
                            isWorkspacesTestingEnabled && workspace
                                ? workspace?.complianceRequirements?.allowO365DataCollection
                                      ?.enabled ?? false
                                : tenantInfo?.complianceRequirements?.allowO365DataCollection
                                      ?.enabled ?? false
                        }
                        onClick={(ev) => updateCompliancePreferences(ev)}
                        state={updateO365Consent}
                        className={mergeClasses(
                            classes.switch,
                            needToDisableO365ConsentToggle && classes.disabledLabel,
                        )}
                    ></ProgressiveSwitch>
                    {errorTagForO365Consent && (
                        <div>
                            <div className={orgClasses.errorTag}>
                                <span>
                                    <ErrorIcon filled />
                                </span>
                                <span>{tAdmin('ownerSettings.organizationData.ErrorMessage')}</span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default PluginSettings;
