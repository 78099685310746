import {Body1} from '@fluentui/react-components';
import {PromptProps} from './PromptbookPrompt.types';
import useClasses from './PromptbookPrompt.styles';
import PromptbookPromptForm from '../promptbooks/PromptbookPromptForm';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

export default function PromptbookPrompt(props: PromptProps) {
    const {prompt} = props;
    const classes = useClasses();
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const skillInputDescriptors = prompt.skillInputDescriptors ?? [];
    const inputs = prompt.inputs ?? {};
    // Used to highlight parameters in the prompt when in modal view
    const setUpPromptModalText = (content: string) => {
        const promptText = content;
        const regex = /(<[^>]+>)/g;
        const parts = promptText.split(regex);
        if (!isDirectSkillInvocationEnabled) {
            return content;
        }
        return (
            <>
                {parts.map((part, index) =>
                    regex.test(part) ? (
                        <span key={index} className={classes.promptbookLibraryLabel}>
                            {part.slice(1, -1)}
                        </span>
                    ) : (
                        part
                    ),
                )}
            </>
        );
    };

    return (
        <div className={classes.promptLabel} data-test-id="promptbook-prompt" tabIndex={0}>
            <div className={classes.promptNumber}>{props.index ? props.index! + 1 : 1}</div>
            {prompt.skillName ? (
                <div>
                    <PromptbookPromptForm
                        skillName={prompt.skillName}
                        inputs={inputs}
                        skillInputDescriptors={skillInputDescriptors}
                        isModal={true}
                        checkValidity={false}
                    />
                </div>
            ) : (
                <Body1>{setUpPromptModalText(prompt.content)}</Body1>
            )}
        </div>
    );
}
