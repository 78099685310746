import * as React from 'react';
import {Button, Text, Image, Card} from '@fluentui/react-components';
import {SkillsetsIcon} from '@/components/ui/icons';
import useClasses from '../../ConnectorModalContent.styles';
import {SkillsetFilterTypes} from '@/api/skills';
import {useTranslation} from 'react-i18next';
import PluginNotFoundIcon from '../../../../../../public/images/icons/plugin-not-found.svg';
import {Styles} from 'docx';

interface EmptySearchComponentProps {
    isUserSearching: boolean;
    displayedSkillsetsFilter: SkillsetFilterTypes;
    onAddPluginClick: () => void;
    addPluginButtonRef: React.RefObject<HTMLButtonElement>;
}

export default function EmptySearchComponent({
    isUserSearching,
    displayedSkillsetsFilter,
    onAddPluginClick,
    addPluginButtonRef,
}: EmptySearchComponentProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    var titleText = t('ContentModal.PluginNotFound');
    var descriptionText = t('ContentModal.PluginNotFoundDescription');

    if (!isUserSearching) {
        switch (displayedSkillsetsFilter) {
            case SkillsetFilterTypes.On:
                // No enabled skillsets. All are off
                titleText = t('ContentModal.AllPluginsTurnedOffTitle');
                descriptionText = t('ContentModal.AllPluginsTurnedOffDescription');
                break;
            case SkillsetFilterTypes.Off:
                // No disabled skillsets. All are on
                titleText = t('ContentModal.AllPluginsTurnedOnTitle');
                descriptionText = t('ContentModal.AllPluginsTurnedOnDescription');
                break;
            case SkillsetFilterTypes.NotSetUp:
                // No not set up skillsets. All are set up.
                titleText = t('ContentModal.AllPluginsSetUpTitle');
                descriptionText = t('ContentModal.AllPluginsSetUpDescription');
                break;
            default:
                // No skillsets found.
                titleText = t('ContentModal.AllPluginsNotFoundTitle');
                descriptionText = t('ContentModal.AllPluginsNotFoundDescription');
                break;
        }
    }

    return (
        <div>
            <Card className={classes.emptySearch}>
                <Image className={classes.emptySearchImage} src={PluginNotFoundIcon.src} />

                <Text className={classes.emptySearchText}>{titleText}</Text>
                <Text className={classes.emptySearchText2}>{descriptionText}</Text>

                {isUserSearching && (
                    <Button
                        className={classes.connectServicesButton}
                        icon={<SkillsetsIcon className={classes.connectionIcon} />}
                        onClick={onAddPluginClick}
                        appearance="subtle"
                        size="medium"
                        ref={addPluginButtonRef}
                    >
                        {t('ContentModal.AddPlugin')}
                    </Button>
                )}
            </Card>
        </div>
    );
}
