import MedeinaFeatures from '@/util/features';
import {hasStaticFeatureFlag} from '@/util/hasStaticFeatureFlags';

export const isMultiWorkspacesEnabled = () => {
    return hasStaticFeatureFlag(MedeinaFeatures.WorkspacesEnabled);
};

const excludedPaths = [
    '/auth',
    '/datashare',
    '/userinfo',
    '/users/features',
    '/userpreferences',
    '/workspaces',
    'securityplatform',
];

export const isUrlPathExcludedFromMsgRouting = (url: string): boolean => {
    // check if lowerCaseUrl includes any string in the excludedPaths list
    const lowerCaseUrl = url.toLowerCase();
    console.info('Checking if URL is in the excluded paths list, Url IS', url);
    for (const path of excludedPaths) {
        if (lowerCaseUrl.includes(path)) {
            console.log('URL is in the excluded paths list');
            return true;
        }
        console.info('URL did not match any known patterns Url IS', url);
    }
    return false;
};
