import React, {Suspense, useCallback, useMemo} from 'react';
import {CodeIcon, DocumentIcon, ConnectIcon, LibraryIcon} from '@/components/ui/icons';
import {useTranslation} from 'react-i18next';
import {TabbedDialog, TabbedConfiguration} from '@/components/ui/Dialog';
import {useViewportSize} from '@/components/ui/Grid';
import SkillsetEditor from './SkillsetEditor';
import SkillsetDesigner from './SkillsetDesigner';
import {Skillset, SkillsetCreatorProps} from './SkillsetCreator.types';
import {convertYamlSkillset, fetchYamlFromSkillset, trimSkillsetYaml} from './SkillsetCreatorUtils';

export default function SkillsetCreator({onCloseCreator}: SkillsetCreatorProps) {
    const [currentSkillsetYaml, setCurrentSkillsetYaml] = React.useState<string>('');
    const [currentSkillsetName, setCurrentSkillsetName] = React.useState<string>('');
    const [isClosed, setIsClosed] = React.useState<boolean>(false);

    const updateCurrentSkillsetValue = (value: any) => {
        setCurrentSkillsetYaml(value);
    };

    const tabConfig = useCallback(
        () => [
            {
                title: 'Create an Agent skillset',
                tabTitle: 'Describe skillset',
                view: (
                    <SkillsetDesigner
                        onValueChange={updateCurrentSkillsetValue}
                        currentSkillsetYaml={currentSkillsetYaml}
                        currentSkillsetName={currentSkillsetName}
                        onChangeCurrentSkillset={setCurrentSkillsetName}
                    />
                ),
                value: 'describe-skillset',
                testId: 'describe-skillset-tab',
                tabProps: {
                    icon: <DocumentIcon />,
                },
            },
            {
                title: 'Create an Agent skillset',
                tabTitle: 'Edit YAML skillset',
                view: (
                    <SkillsetEditor
                        onValueChange={updateCurrentSkillsetValue}
                        currentSkillsetYaml={currentSkillsetYaml}
                        currentSkillsetName={currentSkillsetName}
                        onChangeCurrentSkillset={setCurrentSkillsetName}
                    />
                ),
                value: 'yaml-skillset',
                testId: 'yaml-skillset-tab',
                tabProps: {
                    icon: <CodeIcon />,
                },
            },
        ],
        [currentSkillsetYaml],
    );

    const onClose = () => {
        setIsClosed(true);
        if (onCloseCreator) {
            onCloseCreator();
        }
    };

    return (
        <>
            {!isClosed && (
                <TabbedDialog
                    homeTitle="Create an Agent skillset"
                    onClose={onClose}
                    tabConfiguration={tabConfig()}
                    vertical={false}
                    onTabChange={() =>
                        setCurrentSkillsetYaml(trimSkillsetYaml(currentSkillsetYaml))
                    }
                />
            )}
        </>
    );
}
