const excludedPaths = [
    '/auth',
    '/datashare',
    '/userinfo',
    '/users/features',
    '/userpreferences',
    '/workspaces',
    'securityplatform',
];

export const isUrlPathExcludedFromMsgRouting = (url: string): boolean => {
    // check if lowerCaseUrl includes any string in the excludedPaths list
    const lowerCaseUrl = url.toLowerCase();
    for (const path of excludedPaths) {
        if (lowerCaseUrl.includes(path)) {
            return true;
        }
    }
    return false;
};
