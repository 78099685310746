import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    promptLabel: {
        ...shorthands.padding(
            tokens.spacingHorizontalM,
            tokens.spacingHorizontalL,
            tokens.spacingHorizontalM,
            tokens.spacingHorizontalS,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: 'auto',
        backgroundColor: tokens.colorNeutralBackground5,
        display: 'flex',
        flexDirection: 'row',
        columnGap: tokens.spacingHorizontalS,
        alignItems: 'center',
    },
    promptNumber: {
        ...shorthands.padding(tokens.spacingHorizontalMNudge, tokens.spacingHorizontalSNudge),
        alignSelf: 'center',
    },
    promptbookLibraryLabel: {
        backgroundColor: tokens.colorNeutralStroke1,
        ...shorthands.borderRadius(tokens.borderRadiusSmall),
        ...shorthands.borderWidth('0px'),
        ...shorthands.padding(tokens.spacingHorizontalXS),
        ...shorthands.border('0px', 'none', 'transparent'),
        ...shorthands.outline('0px'),
        color: tokens.colorNeutralForeground1,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightRegular,
        fontFamily: tokens.fontFamilyBase,
        wordWrap: 'break-word',
    },
});
