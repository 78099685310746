import {
    Menu,
    MenuButton,
    MenuItemCheckbox,
    MenuList,
    MenuPopover,
    MenuTrigger,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useClasses from '../../ConnectorModalContent.styles';

interface CategoryFilterDropdownProps {
    selectedCategories: Record<string, string[]>;
    displayableCategories: string[];
    onCheckedValueChange: (
        e: any,
        {
            name,
            checkedItems,
        }: {
            name: string;
            checkedItems: string[];
        },
    ) => void;
}

// Category filter dropdown for the filtering toolbar in the "Manage plugins" popup
export default function CategoryFilterDropdown({
    selectedCategories,
    displayableCategories,
    onCheckedValueChange,
}: CategoryFilterDropdownProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    return (
        <Menu>
            <MenuTrigger>
                <MenuButton appearance="transparent" className={classes.menuButton}>
                    {t('ContentModal.ButtonLabels.Category')}:{' '}
                    {Object.keys(selectedCategories).length === 0 ||
                    Object.keys(selectedCategories).length === displayableCategories.length
                        ? 'All'
                        : Object.keys(selectedCategories).join(', ')}
                </MenuButton>
            </MenuTrigger>
            <MenuPopover className={classes.menuPopover}>
                <MenuList
                    checkedValues={selectedCategories}
                    onCheckedValueChange={onCheckedValueChange}
                >
                    {Object.entries(displayableCategories).map(([_, categoryName]) => {
                        return (
                            <MenuItemCheckbox
                                name={categoryName}
                                value={categoryName}
                                key={categoryName}
                            >
                                {categoryName}
                            </MenuItemCheckbox>
                        );
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}
