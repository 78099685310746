import {KeyValueFilterDetails, SelectedSearchFacet} from '@/api/capacities';
import FilterPills from './FilterPills/FilterPills';
import {UsageMonitoringFilterLabels} from './UsageDashboard.constants';
import {FilterAddIcon} from '@/components/ui/icons';
import {Divider, Tooltip, Button} from '@fluentui/react-components';
import useClasses from './UsageDashboard.styles';
import {useTranslation} from 'react-i18next';
import {useEffect, useRef} from 'react';

/**
 * Props for the FilterPillsSection component.
 */
interface FilterPillsSectionProps {
    /**
     * Indicates if overage is enabled.
     */
    isOverageEnabled: boolean;

    /**
     * Indicates if the hourly bar is active.
     */
    isHourlyBarActive: boolean;

    /**
     * Array of selected search filters.
     */
    searchFilters: SelectedSearchFacet[];

    /**
     * Map of copilot experience filters.
     */
    copilotExperienceMap: KeyValueFilterDetails[];

    /**
     * Indicates if the evaluation aggregate data is loading.
     */
    isEvaluationAggregateDataLoading: boolean;

    /**
     * Callback function to handle filter updates.
     * @param filters - The updated array of selected search filters.
     */
    onFiltersUpdated: (filters: SelectedSearchFacet[]) => void;

    /**
     * Callback function to handle filter button click.
     */
    onFilterButtonClick: () => void;

    /**
     * Indicates if the filter button should be focused.
     */
    shouldFocusFilterButton: boolean;
}

const INSTRUCTIONS_ID = 'filter-open-button-disabled-instructions';

export default function SelectedFilterPillsSection({
    isOverageEnabled,
    isHourlyBarActive,
    searchFilters,
    copilotExperienceMap,
    isEvaluationAggregateDataLoading,
    shouldFocusFilterButton,
    onFiltersUpdated,
    onFilterButtonClick,
}: FilterPillsSectionProps) {
    const classes = useClasses();
    const {t: tAdmin} = useTranslation('admin');

    const filterButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if (shouldFocusFilterButton && !isEvaluationAggregateDataLoading) {
            if (filterButtonRef.current) {
                filterButtonRef?.current?.focus();
            }
        }
    }, [shouldFocusFilterButton, searchFilters, isEvaluationAggregateDataLoading]);

    return (
        <>
            <FilterPills
                isOverageEnabled={isOverageEnabled}
                isDisabled={isHourlyBarActive}
                filters={searchFilters}
                onFiltersUpdated={(filters) => {
                    onFiltersUpdated(filters);
                }}
                copilotExperienceMap={copilotExperienceMap}
            />
            <Divider vertical className={classes.divider} />
            <Tooltip content={tAdmin(UsageMonitoringFilterLabels.Tooltip)} relationship="label">
                <Button
                    disabled={isHourlyBarActive}
                    aria-disabled={isHourlyBarActive}
                    aria-describedby={isHourlyBarActive ? INSTRUCTIONS_ID : undefined}
                    data-testid="filter-open-button"
                    appearance="transparent"
                    icon={<FilterAddIcon />}
                    onClick={onFilterButtonClick}
                    ref={filterButtonRef}
                />
            </Tooltip>
            {isHourlyBarActive && (
                <span id={INSTRUCTIONS_ID} className={classes.visuallyHidden}>
                    {tAdmin(UsageMonitoringFilterLabels.DisabledTooltip)}
                </span>
            )}
        </>
    );
}
