import * as React from 'react';
import {useState, useEffect} from 'react';
import useClasses from '../../ExternalConfigurationModalContent.styles';
import {
    CreateSkillsetRequest,
    SkillsetContentType,
    SkillsetDescriptor,
    SkillsetFormat,
    SkillsetUploadType,
    UpdateSkillsetRequest,
} from '@/api/skills';
import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import {Button, Link} from '@fluentui/react-components';
import {
    ScopeSelectionDropdown,
    UploadFormatSelectionField,
    SelectedPluginUploadField,
    UploadPluginDialogHeader,
} from '@/components/ui/ConnectorModal/ExternalConfigurationModal';
import {useTranslation} from 'react-i18next';
import {useGetWorkspaceByName, useGetWorkspaces, useGetWorkspaceSettings} from '@/api/workspaces';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface UploadPluginDialogProps {
    selectedScope: string;
    selectedConnector?: SkillsetDescriptor | null;
    handleConnectorReturn: () => void;
    onScopeSelected: (selectedScope: string) => void;
    onSendSkillsetData: (skillset: CreateSkillsetRequest) => void;
    onUpdateSkillsetData: (skillset: UpdateSkillsetRequest) => void;
    externalConfigurationModalRef: React.RefObject<HTMLDivElement>;
    onClose: () => void;
}

export default function UploadPluginDialog({
    selectedScope,
    selectedConnector,
    handleConnectorReturn,
    onScopeSelected,
    onSendSkillsetData,
    onUpdateSkillsetData,
    externalConfigurationModalRef,
    onClose,
}: UploadPluginDialogProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');
    const [isAdmin, setIsAdmin] = useState(false);
    const {data: userInfo, isSuccess: adminSuccess} = useGetUserInfo();
    const {data: tenantInfo} = useGetTenantInfo();
    // multi-workspaces
    const {data: workspacesData, isFetched: isWorkspacesDataFetched} = useGetWorkspaces();

    const {data: workspace} = useGetWorkspaceByName(workspacesData?.value?.[0]?.name || '', {
        enabled: isWorkspacesDataFetched,
        refetchOnMount: true,
    });
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const {data: workspaceCfS} = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesTestingEnabled),
    });

    const [formValues, setFormValues] = useState<Map<string, string>>(new Map());
    const [selectedUploadType, setSelectedUploadType] = useState<SkillsetUploadType>();
    const [selectedFormat, setSelectedFormat] = useState('');

    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [parsedSkillset, setParsedSkillset] = useState<string | null>(null);
    const [fileParsed, setFileParsed] = useState(false);

    // List of settings that failed validation when attempting to submit
    const [failedValidationSettings, setFailedValidationSettings] = useState<string[]>([]);

    const [isUrlUpload, setIsUrlUpload] = useState(false);

    useEffect(() => {
        if (adminSuccess && userInfo) {
            setIsAdmin(userInfo.isAdmin);
        }
    }, [adminSuccess, userInfo]);

    const updateFormValues = (ev: any, config: string) => {
        const innerMap = formValues ?? new Map<string, string>();
        innerMap?.set(config, ev.target.value);
        setFormValues(new Map(innerMap));
    };

    const handleUploadTypeSelection = (uploadType: SkillsetUploadType) => {
        setSelectedUploadType(uploadType);
        if (uploadType == SkillsetUploadType.OpenAI) {
            setSelectedFormat(SkillsetFormat.PluginManifestJson);
        }
    };

    const updateFormat = (_ev: any, data: any) => {
        if (data.optionValue == '.yaml') {
            setSelectedFormat(SkillsetFormat.SkillsetYaml);
        } else if (data.optionValue == '.json') {
            setSelectedFormat(SkillsetFormat.SkillsetJson);
        }
        setUploadedFile(null);
        setParsedSkillset(null);
        setFileParsed(false);
    };

    const onSwitchChangeHandler = (ev: any) => {
        setIsUrlUpload(ev.target.checked);
        setUploadedFile(null);
        setParsedSkillset(null);
        setFileParsed(false);
        setFailedValidationSettings([]);
    };

    const handleUploadedFile = (file: File | null) => {
        setUploadedFile(file);
        const fileName = file?.name;
        if (!file) {
            setSelectedFormat('');
        }
        if (fileName && (fileName.endsWith('.yaml') || fileName.endsWith('.yml'))) {
            setSelectedFormat(SkillsetFormat.SkillsetYaml);
        } else if (fileName && fileName.endsWith('.json')) {
            setSelectedFormat(SkillsetFormat.SkillsetJson);
        }
        let reader = new FileReader();
        if (file) {
            reader.onload = (event) => {
                const fileContent = event.target?.result as string;
                setParsedSkillset(fileContent);
                setFileParsed(true);
            };
            reader.readAsText(file);
        }
    };

    const validateForm = () => {
        const failedValidations = [];
        if (!selectedScope || !selectedUploadType) {
            if (!selectedScope) {
                failedValidations.push('scope');
            }
            if (!selectedUploadType) {
                failedValidations.push('format');
            }
            setFailedValidationSettings(failedValidations);
        }
        if (selectedUploadType == SkillsetUploadType.Copilot && !isUrlUpload && !uploadedFile) {
            failedValidations.push('file');
            setFailedValidationSettings(failedValidations);
        }
        if (
            (isUrlUpload || selectedUploadType == SkillsetUploadType.OpenAI) &&
            !formValues.get('url')
        ) {
            failedValidations.push('url');
            setFailedValidationSettings(failedValidations);
        }
        if (selectedUploadType == SkillsetUploadType.Copilot && isUrlUpload && !selectedFormat) {
            failedValidations.push('type');
            setFailedValidationSettings(failedValidations);
        }

        if (failedValidations.length > 0) {
            return false;
        }
        return true;
    };

    // form CreateSkillsetRequest based on the type of file upload
    const buildCreateSkillsetRequest = (): CreateSkillsetRequest => {
        const skillset: CreateSkillsetRequest = {
            body:
                isUrlUpload || selectedFormat === SkillsetFormat.PluginManifestJson
                    ? ''
                    : parsedSkillset ?? null,
            scope: selectedScope,
            format: selectedFormat,
            contentType:
                !isUrlUpload && selectedFormat === SkillsetFormat.SkillsetYaml
                    ? SkillsetContentType.Yaml
                    : '',
            contentUrl:
                isUrlUpload || selectedFormat === SkillsetFormat.PluginManifestJson
                    ? formValues.get('url')
                    : '',
        };

        return skillset;
    };

    const buildUpdateSkillsetRequest = (): UpdateSkillsetRequest => {
        const skillset: UpdateSkillsetRequest = {
            name: selectedConnector?.name ?? '',
            ...buildCreateSkillsetRequest(),
        };

        return skillset;
    };

    const shouldSendSkillsetData = (): boolean => {
        return (
            (!isUrlUpload &&
                (selectedFormat === SkillsetFormat.SkillsetJson ||
                    selectedFormat === SkillsetFormat.SkillsetYaml) &&
                fileParsed) ||
            selectedFormat === SkillsetFormat.PluginManifestJson ||
            isUrlUpload
        );
    };

    const handleUpdate = async () => {
        // validate if all required inputs have been set
        if (!validateForm()) {
            return;
        }

        // if there is no selected connector, we are creating a new plugin
        const skillset = buildUpdateSkillsetRequest();

        // send the data and perform error handling if needed
        if (shouldSendSkillsetData()) {
            onUpdateSkillsetData(skillset);
        }
    };

    const handleSubmit = async () => {
        // validate if all required inputs have been set
        if (!validateForm()) {
            return;
        }

        // form CreateSkillsetRequest based on the type of file upload
        const skillset = buildCreateSkillsetRequest();

        // send the data and perform error handling if needed
        if (shouldSendSkillsetData()) {
            onSendSkillsetData(skillset);
        }
    };

    const shouldRenderScopeSelectionDropdown = () => {
        // Show the scope selection dropdown only for the add modal.
        // User must be an admin or the Allow setting must be enabled.
        // Do not allow changing scope when editing the plugin.
        if (isWorkspacesTestingEnabled && workspaceCfS) {
            return (isAdmin || workspaceCfS?.allowTenantPluginUpload) && !selectedConnector;
        }
        return (isAdmin || tenantInfo?.allowTenantPluginUpload) && !selectedConnector;
    };

    return (
        <div className={classes.content}>
            <UploadPluginDialogHeader
                selectedConnector={selectedConnector}
                handleConnectorReturn={handleConnectorReturn}
                onClose={onClose}
            />
            <div>
                <div className={classes.subTextRow}>
                    {selectedConnector ? (
                        <p>{t('FollowInstructionsToUpload')}</p>
                    ) : (
                        <p>
                            {t('CompleteInfoToAdd')}{' '}
                            <Link
                                href={
                                    'https://learn.microsoft.com/en-us/security-copilot/manage-plugins'
                                }
                                target="_blank"
                            >
                                {t('common.LearnMore')}
                            </Link>
                        </p>
                    )}
                </div>
                {shouldRenderScopeSelectionDropdown() && (
                    <ScopeSelectionDropdown
                        selectedScope={selectedScope}
                        onScopeSelected={onScopeSelected}
                        externalConfigurationModalRef={externalConfigurationModalRef}
                    />
                )}
                <form
                    className={classes.form}
                    onSubmit={selectedConnector ? handleUpdate : handleSubmit}
                >
                    <div className={classes.grid}>
                        {
                            <UploadFormatSelectionField
                                selectedUploadType={selectedUploadType}
                                failedValidationSettings={failedValidationSettings}
                                onUploadTypeSelection={handleUploadTypeSelection}
                            />
                        }
                        {
                            <SelectedPluginUploadField
                                pluginUploadType={selectedUploadType}
                                failedValidationSettings={failedValidationSettings}
                                isUrlUpload={isUrlUpload}
                                onSwitchChangeHandler={onSwitchChangeHandler}
                                onFormValuesUpdated={updateFormValues}
                                onFileTypeSelected={updateFormat}
                                onFileUploaded={handleUploadedFile}
                            />
                        }
                    </div>
                </form>
                <div className={classes.buttons}>
                    <div>
                        <Button
                            className={classes.cancelButton}
                            appearance="subtle"
                            size="medium"
                            onClick={handleConnectorReturn}
                        >
                            {t('common.Cancel')}
                        </Button>
                        {selectedConnector && (
                            <Button
                                className={classes.saveButton}
                                type="submit"
                                onClick={handleUpdate}
                                disabled={formValues.size == 0 && !uploadedFile ? true : false}
                            >
                                {t('common.Save')}
                            </Button>
                        )}
                        {!selectedConnector && (
                            <Button
                                className={classes.saveButton}
                                type="submit"
                                onClick={handleSubmit}
                                disabled={formValues.size == 0 && !uploadedFile ? true : false}
                            >
                                {t('common.Add')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
