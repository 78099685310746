import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import useFetch from '../useFetch';
export default function useGetCurrentWorkspace(
    useQueryOptions?: Pick<UseQueryOptions<string>, 'enabled' | 'refetchOnMount'>,
) {
    const {customFetch} = useFetch();
    const getCurrentWorkspace = async () => {
        try {
            return await customFetch<string>(`/userPreferences/currentWorkspace`, {
                method: 'GET',
            });
        } catch (error) {
            throw error;
        }
    };
    return useQuery({
        queryFn: getCurrentWorkspace,
        queryKey: ['userPreferences', 'currentWorkspace'],
        enabled: useQueryOptions?.enabled ?? true,
        // we only want to fetch the user preferences once per session
        // so we set staleTime to Infinity
        staleTime: Infinity,
        // don't refetch the user preferences when the window regains focus
        refetchOnWindowFocus: false,
        // don't refetch the user preferences when the user is idle
        refetchOnMount: false,
    });
}
