import React from 'react';
import {Handle, Position} from '@xyflow/react';
import useClasses from './Agent.styles';

const truncate = (str: string, length: number) => {
    return str?.length > length ? str.substring(0, length) + '...' : str;
};

const AgentNode = ({data, type, id}: any) => {
    const {onClick, ...restData} = data;
    const excludedKeys = [
        'Skill Output',
        'System Prompt',
        'Exception',
        'Skill Invocation ID',
        'Parent Skill Invocation ID',
    ];
    const classes = useClasses();

    return (
        <div
            className={restData?.Exception ? classes.agentNodeError : classes.agentNode}
            onClick={() => onClick(id, restData)}
        >
            {type === 'customNode' && (
                <>
                    <Handle type="target" position={Position.Top} />
                    <Handle type="source" position={Position.Bottom} />
                </>
            )}
            {type === 'evaluationNode' && (
                <>
                    <Handle type="target" position={Position.Left} />
                    <Handle type="source" position={Position.Right} />
                </>
            )}
            {Object.entries(restData)
                .filter(
                    ([key, value]) => !excludedKeys.includes(key) && value != null && value !== '',
                )
                .map(([key, value]) => (
                    <div key={key} className={classes.agentNodeText}>
                        <strong>{key}:</strong> {truncate(value as string, 80)}
                    </div>
                ))}
        </div>
    );
};

export default AgentNode;
