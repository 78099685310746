import {makeStyles, shorthands, tokens} from '@fluentui/react-components';

export default makeStyles({
    flexContainerRow: {
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
        minHeight: '56px',
    },
    flexGrow: {
        display: 'flex',
        flexGrow: 1,
        position: 'relative',
    },
    promptbookInputActionsContainer: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    toolBarButton: {
        ...shorthands.padding(tokens.spacingVerticalS),
        height: tokens.lineHeightBase500,
    },
    toolBarButtonPrompt: {
        marginTop: tokens.spacingVerticalL,
        visibility: 'hidden',
        ...shorthands.padding(tokens.spacingVerticalXXS),
        height: tokens.lineHeightBase500,
    },
    promptLabel: {
        ...shorthands.padding(
            tokens.strokeWidthThin,
            tokens.spacingVerticalM,
            tokens.strokeWidthThicker,
        ),
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        width: 'auto',
        backgroundColor: tokens.colorNeutralBackground5,
        ...shorthands.margin(tokens.spacingHorizontalXS, 0),
        minHeight: tokens.lineHeightBase600,
        [`&:hover *, &:focus-within *`]: {
            visibility: 'visible',
        },
        display: 'flex',
        background: tokens.colorNeutralBackground5,
    },
    verticalLine: {
        ...shorthands.borderLeft(tokens.borderRadiusSmall, 'solid'),
        height: tokens.lineHeightBase500,
        ...shorthands.margin(0, tokens.spacingHorizontalXS),
        borderLeftColor: tokens.colorNeutralStroke1,
    },
    directSkillsMenu: {
        position: 'absolute',
        bottom: '70px',
        right: '-12px',
        zIndex: '1',
    },
    promptFormWrapper: {
        ...shorthands.flex(1, 1, 'auto'),
        ...shorthands.borderRadius(tokens.borderRadiusLarge),
        ...shorthands.padding(
            tokens.spacingVerticalL,
            tokens.spacingHorizontalXL,
            tokens.spacingVerticalM,
            tokens.spacingHorizontalS,
        ),
    },
    promptNumber: {
        ...shorthands.padding(tokens.spacingHorizontalXS),
        paddingTop: tokens.spacingVerticalL,
        fontSize: tokens.fontSizeBase300,
    },
    toolBarContainer: {
        position: 'absolute',
        right: '2%',
        top: '25%',
    },
    promptOptionsContainer: {
        position: 'absolute',
        right: '2%',
    },
    promptTextArea: {
        border: 'none',
        background: tokens.colorNeutralBackground5,
        '&:focus': {
            outline: 'none',
            border: 'none',
        },
        lineHeight: tokens.lineHeightBase200,
        fontSize: tokens.fontSizeBase300,
        fontFamily: tokens.fontFamilyBase,
        fontWeight: tokens.fontWeightRegular,
        color: tokens.colorNeutralForeground1,
        resize: 'none',
        width: '470px',
        height: 'auto',
        overflowY: 'auto',
        ...shorthands.margin(tokens.spacingVerticalM, 0, tokens.spacingVerticalS, 0),
        flexGrow: 0,
    },
    skillIcon: {
        color: tokens.colorBrandForeground1,
    },
    skill: {
        ...shorthands.flex(0, 0, 'auto'),
        color: tokens.colorNeutralForeground2,
        fontSize: tokens.fontSizeBase300,
        ...shorthands.padding(
            tokens.spacingVerticalXS,
            tokens.spacingHorizontalXS,
            tokens.spacingVerticalXS,
            tokens.spacingHorizontalXS,
        ),
        ...shorthands.margin(tokens.spacingVerticalS, 0, tokens.spacingVerticalXS, 0),
    },
    dismissIcon: {
        paddingLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightBold,
    },
});
