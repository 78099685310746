import {
    FilterAddIcon,
    SearchIcon,
    ClockIcon,
    DismissIcon,
    SelectAllIcon,
} from '@/components/ui/icons';
import {
    Toolbar,
    Input,
    Button,
    ToolbarDivider,
    mergeClasses,
    Tooltip,
} from '@fluentui/react-components';
import {FilterButton, FilterButtonRow} from '@/components/ui/Buttons/FilterButton';
import {useState, useEffect, useReducer, useCallback} from 'react';
import useClasses from './SessionFilters.styles';
import useFilterButtonClasses from '@/components/ui/Buttons/FilterButton.styles';
import {motion, AnimatePresence} from 'framer-motion';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {formatLocaleDate as format} from '@/util/locale';
import {
    DateRange,
    DateRangeOption,
    DateRangeOptions,
} from '@/components/sections/sessions/SearchFilter/SearchFilter.types';

const getInitialState = (translationFx: (input: any) => string) => {
    const newFilterText = translationFx('SessionFiltersAllTimeFiltersText');
    return {
        expandedSearchBar: false,
        expandedFilterButton: false,
        recentlyOpenedSelected: false,
        newFiltertext: newFilterText,
        selectedChip: 'ALL',
        isCancelButton: false,
    };
};

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'FilterClick':
            return {
                ...state,
                selectedChip: 'FILTERCHECKED',
                newFiltertext: i18n.t('SessionFiltersAllTimeFiltersText', {ns: 'mysessions'}),
                expandedFilterButton: !state.expandedFilterButton,
                recentlyOpenedSelected: false,
                isCancelButton: false,
            };
        case 'CancelFilterClick':
            return {
                ...state,
                selectedChip: 'ALL',
                newFiltertext: i18n.t('SessionFiltersAllTimeFiltersText', {ns: 'mysessions'}),
                expandedFilterButton: false,
                isCancelButton: true,
                recentlyOpenedSelected: false,
            };
        case 'FilterEditClick':
            return {
                ...state,
                selectedChip: 'FILTERCHECKED',
                recentlyOpenedSelected: false,
                isCancelButton: false,
            };
        case 'AllSessions':
            return {
                ...state,
                selectedChip: 'ALL',
                recentlyOpenedSelected: false,
            };
        case 'RecentlyOpenedChip':
            return {
                ...state,
                selectedChip: 'RECENTLYOPENED',
                recentlyOpenedSelected: true,
            };
        case 'SetRecentlyOpened':
            return {...state, recentlyOpenedSelected: action.value};
        case 'SETSelectedChip':
            return {...state, selectedChip: action.value};
        case 'SETCancelButton':
            return {...state, isCancelButton: action.value};
        case 'SETExpandedSearchBar':
            return {...state, expandedSearchBar: !state.expandedSearchBar};
        case 'SETExpandedFilterButton':
            return {...state, expandedFilterButton: action.value};
        case 'SETNewFilterText':
            return {...state, newFiltertext: action.value};
    }
};

interface SessionFiltersProps {
    createdAt: DateRange | DateRangeOption;
    updatedAt: DateRange | DateRangeOption;
    onObjectChange: (value: string) => void;
    recentlyOpened: (value: boolean) => void;
    onFilterClick: (value: boolean) => void;
    setCreatedAt: (value: DateRange | DateRangeOption) => void;
    setUpdatedAt: (value: DateRange | DateRangeOption) => void;
    selectedChipIcon: (value: string) => void;
}

export default function SessionFilters({
    createdAt,
    updatedAt,
    onObjectChange,
    recentlyOpened,
    onFilterClick,
    setCreatedAt,
    setUpdatedAt,
    selectedChipIcon,
}: SessionFiltersProps) {
    const classes = useClasses();
    const {t} = useTranslation('mysessions');
    const filterClasses = useFilterButtonClasses();
    const [reducerState, dispatch] = useReducer(reducer, getInitialState(t));
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    var cancelExecuted = false;

    const getButtonLabel = useCallback(
        (createdAt: string, updatedAt: string) => {
            const createdAtText = t('SessionFiltersCreatedAtUpdatedAt', {
                createdAt,
                updatedAt,
            });
            return createdAtText;
        },
        [t],
    );

    useEffect(() => {
        let createdAtLabel;
        let updatedAtLabel;
        if (typeof createdAt !== 'string') {
            const startDate = format(createdAt.startDate, 'MM/dd/yyyy');
            const endDate = format(createdAt.endDate, 'MM/dd/yyyy');
            createdAtLabel = `${startDate} to ${endDate}`;
        } else {
            createdAtLabel = t(createdAt);
        }
        if (typeof updatedAt !== 'string') {
            const startDate = format(updatedAt.startDate, 'MM/dd/yyyy');
            const endDate = format(updatedAt.endDate, 'MM/dd/yyyy');
            updatedAtLabel = `${startDate} to ${endDate}`;
        } else {
            updatedAtLabel = t(updatedAt);
        }
        const createdAtText = getButtonLabel(createdAtLabel, updatedAtLabel);
        dispatch({
            type: 'SETNewFilterText',
            value: createdAtText,
        });
    }, [createdAt, updatedAt, getButtonLabel]);

    // handle the initial load of the filter
    useEffect(() => {
        const allTimeLabel = t('DateRangeAllTime');
        const createdAtText = t('SessionFiltersCreatedAtUpdatedAt', {
            createdAt: allTimeLabel,
            updatedAt: allTimeLabel,
        });
        dispatch({
            type: 'SETNewFilterText',
            value: createdAtText,
        });
    }, []);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }
        recentlyOpened(reducerState.recentlyOpenedSelected);
    }, [reducerState.recentlyOpenedSelected]);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }
        onFilterClick(reducerState.expandedFilterButton);
    }, [reducerState.expandedFilterButton]);

    useEffect(() => {
        selectedChipIcon(reducerState.selectedChip);
    }, [reducerState.selectedChip]);

    return (
        <div className={classes.root}>
            <Toolbar size="small">
                <div>
                    <AnimatePresence mode="wait">
                        {!reducerState.expandedSearchBar && (
                            <Tooltip
                                content={t('SessionFiltersTooltipSearchSessions')}
                                relationship="description"
                            >
                                <Button
                                    onClick={() => dispatch({type: 'SETExpandedSearchBar'})}
                                    className={classes.btnSearchStart}
                                    icon={<SearchIcon />}
                                    shape="circular"
                                    aria-label={t('SessionFiltersButtonAriaLabelSearchSessions')}
                                    data-testid="SearchFilter"
                                />
                            </Tooltip>
                        )}
                        {reducerState.expandedSearchBar && (
                            <motion.div
                                key="searchBar"
                                initial={{opacity: 0.5, width: 0}}
                                animate={{opacity: 1, width: 324}}
                                transition={{duration: 0.3, ease: 'backOut'}}
                                exit={{
                                    opacity: 0,
                                    width: 20,
                                    transition: {duration: 0.3, ease: 'backOut'},
                                }}
                            >
                                <Input
                                    className={classes.searchbar}
                                    type="text"
                                    placeholder={t('SessionFiltersSearchSessionsText')}
                                    onChange={(event: any, textbox: any) => {
                                        onObjectChange(textbox.value);
                                    }}
                                    contentBefore={<SearchIcon />}
                                    appearance="underline"
                                    data-testid="sessions-search-bar"
                                    contentAfter={
                                        <Tooltip
                                            content={t('SessionFiltersCloseSearchBarText')}
                                            relationship="description"
                                        >
                                            <Button
                                                onClick={() => {
                                                    onObjectChange('');
                                                    dispatch({type: 'SETExpandedSearchBar'});
                                                }}
                                                className={classes.subButton}
                                                size="small"
                                                shape="circular"
                                                appearance="outline"
                                                icon={<DismissIcon />}
                                                aria-label={t('SessionFiltersClearSearchText')}
                                            />
                                        </Tooltip>
                                    }
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <ToolbarDivider />
                <FilterButtonRow>
                    <FilterButton
                        icon={<SelectAllIcon />}
                        onClick={() => dispatch({type: 'AllSessions'})}
                        checked={reducerState.selectedChip == 'ALL'}
                        data-testid="AllFilter"
                    >
                        {t('SessionFiltersFilterButtonAll')}
                    </FilterButton>
                    <FilterButton
                        icon={<ClockIcon />}
                        onClick={() => dispatch({type: 'RecentlyOpenedChip'})}
                        checked={reducerState.selectedChip == 'RECENTLYOPENED'}
                        data-testid="RecentFilter"
                    >
                        {t('SessionFiltersFilterButtonRecent')}
                    </FilterButton>
                    <AnimatePresence mode="wait">
                        {!reducerState.expandedFilterButton && (
                            <Tooltip
                                content={t('SessionFiltersTooltipAddFilters')}
                                relationship="description"
                            >
                                <Button
                                    onClick={() => {
                                        dispatch({type: 'FilterClick'});
                                        setUpdatedAt(DateRangeOptions.AllTime);
                                        setCreatedAt(DateRangeOptions.AllTime);
                                    }}
                                    shape="circular"
                                    appearance="outline"
                                    icon={<FilterAddIcon />}
                                    aria-label={t('SessionFiltersButtonAriaLabelAddFilter')}
                                    data-testid="AddFilter"
                                />
                            </Tooltip>
                        )}
                        {reducerState.expandedFilterButton && (
                            <motion.div
                                key="btnFilter"
                                initial={{opacity: 0.5}}
                                animate={{
                                    opacity: 1,
                                    transition: {
                                        duration: 0.5,
                                        ease: 'easeOut',
                                    },
                                }}
                                exit={{
                                    opacity: 0,
                                    transition: {duration: 0.3, ease: 'linear'},
                                }}
                            >
                                <Tooltip
                                    content={t('SessionFiltersTooltipCloseFilterMenu')}
                                    relationship="description"
                                >
                                    <Button
                                        onClick={() => {
                                            cancelExecuted = true;
                                            dispatch({type: 'CancelFilterClick'});
                                            setUpdatedAt(DateRangeOptions.AllTime);
                                            setCreatedAt(DateRangeOptions.AllTime);
                                        }}
                                        appearance="outline"
                                        shape="circular"
                                        icon={<DismissIcon />}
                                        iconPosition="after"
                                        aria-label={t('SessionFiltersButtonAriaLabelClearFilter', {
                                            0: reducerState.newFiltertext,
                                        })}
                                        className={mergeClasses(
                                            reducerState.selectedChip === 'FILTERCHECKED' &&
                                                filterClasses.checked,
                                        )}
                                        data-testid="CustomFilter"
                                    >
                                        {reducerState.newFiltertext}
                                    </Button>
                                </Tooltip>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </FilterButtonRow>
            </Toolbar>
        </div>
    );
}
