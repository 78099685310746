import {useGetUserInfo} from '@/api/app';
import {useGetTenantInfo} from '@/api/tenant';
import {useCallback} from 'react';
import {useGetWorkspaceByName, useGetWorkspaces, useGetWorkspaceSettings} from '../workspaces';
import {useFeatureFlag} from '../user';
import MedeinaFeatures from '@/util/features';

/**
 * This hook is used to determine whether the user is allowed to manage User scope custom plugins.
 * @returns {isUserScopeUploadAllowed} `isUserScopeUploadAllowed` function that returns a boolean
 */
export default function useUserScopePluginUpload() {
    const {data: tenantInfo, isFetched: isTenantInfoFetched} = useGetTenantInfo();
    const {data: userInfo, isSuccess: adminSuccess} = useGetUserInfo();
    // multi-workspaces
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);

    const {data: workspaceCfS} = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesTestingEnabled),
    });

    const isUserScopeUploadAllowed = useCallback((): boolean => {
        // If the user is an admin, they can add custom User plugins.
        if (adminSuccess && userInfo?.isAdmin) {
            return true;
        }

        // For non-admins, check the User setting.
        if (isWorkspacesTestingEnabled && workspaceCfS) {
            if (isTenantInfoFetched && workspaceCfS) {
                // If setting not defined, default to allowing plugin upload
                if (workspaceCfS?.allowUserPluginUpload === undefined) {
                    return true;
                }
                return workspaceCfS?.allowUserPluginUpload;
            }
        } else {
            if (isTenantInfoFetched && tenantInfo) {
                // If setting not defined, default to allowing plugin upload
                if (tenantInfo.allowUserPluginUpload === undefined) {
                    return true;
                }
                return tenantInfo.allowUserPluginUpload;
            }
        }

        return false;
    }, [userInfo, adminSuccess, isTenantInfoFetched, tenantInfo]);

    return {isUserScopeUploadAllowed};
}
