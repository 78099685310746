import React, {useEffect, useState} from 'react';
import {Dropdown, Label, Option, useId} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useClasses from '../Preferences.styles';
import {FileRbacSettingOptions} from './Preferences.constants';
import {FileRbacSetting} from './Preferences.types';
import {TenantInfo, usePatchTenantInfo} from '@/api/tenant';
import {FileRbacDropdownOptions, getCurrentFileSetting} from './Preferences.utils';
import {useGetWorkspaceSettings, useUpdateWorkspaceSettings} from '@/api/workspaces';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {WorkspaceSettings} from '@/api/workspaces/workspaces.types';

interface FileRbacManagementSettingProps {
    tenantInfo: TenantInfo | undefined;
    isAdmin: boolean;
}

export default function FileRbacManagementSetting({
    tenantInfo,
    isAdmin,
}: FileRbacManagementSettingProps) {
    const fileRbacDropdownId = useId(`fileRbacDropdown`);
    const classes = useClasses();
    const {t} = useTranslation();
    const {mutate: patchTenantInfo, isLoading: patchTenantInfoLoading} = usePatchTenantInfo();
    const [fileMgmtRbac, setFileMgmtRbac] = useState(FileRbacSetting.ON);
    // multi-workspaces
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {
        data: workspace,
        refetch: refetchWorkspace,
        isFetched: workspacedFetched,
    } = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const {mutate: updateWorkspace, isLoading: updateWorkspaceInfoLoading} =
        useUpdateWorkspaceSettings({enabled: Boolean(isWorkspacesTestingEnabled)});

    useEffect(() => {
        // Set the file management RBAC text based on the tenantInfo response
        if (!isWorkspacesTestingEnabled) {
            const rbacSetting: FileRbacSetting = getCurrentFileSetting(tenantInfo);
            setFileMgmtRbac(rbacSetting);
        }
    }, [tenantInfo, setFileMgmtRbac]);

    useEffect(() => {
        if (isWorkspacesTestingEnabled && workspace && workspacedFetched) {
            const rbacSetting: FileRbacSetting = getCurrentFileSetting(undefined, workspace);
            setFileMgmtRbac(rbacSetting);
        }
    }, [workspace, setFileMgmtRbac, refetchWorkspace, workspacedFetched]);

    const handleFileRbacChange = (rbacSetting: FileRbacSetting) => {
        // Update the tenantInfo with the new file RBAC setting.
        // Include the existing tenantInfo values to populate the other fields
        const allowFileUpload = rbacSetting === FileRbacSetting.ON;
        if (isWorkspacesTestingEnabled && workspace) {
            const workspaceSettings: WorkspaceSettings = {
                ...workspace,
                workspaceId: workspace?.workspaceId ?? '',
                allowUserFileUpload: allowFileUpload,
            };
            updateWorkspace(
                {
                    workspaceSettings: workspaceSettings,
                },
                {
                    onSuccess: () => {
                        refetchWorkspace();
                    },
                },
            );
        } else {
            patchTenantInfo({
                ...tenantInfo,
                allowUserFileUpload: allowFileUpload,
            });
        }
    };

    const rbacDropdownEnabled = () => {
        // Disabled for non-admins and while the patchTenantInfo response is loading
        if (isWorkspacesTestingEnabled && workspace) {
            if (!isAdmin || updateWorkspaceInfoLoading) {
                return false;
            }
            return workspace?.allowUserFileUpload !== undefined;
        } else {
            if (!isAdmin || patchTenantInfoLoading) {
                return false;
            }
            return tenantInfo?.allowUserFileUpload !== undefined;
        }
    };

    const renderDropdownLabel = () => {
        return (
            <Label id={fileRbacDropdownId} className={classes.label}>
                {t('SettingsFileUploadsLabel')}
            </Label>
        );
    };

    const dropdownTextValue = t(FileRbacSettingOptions[fileMgmtRbac]);

    return (
        <div className={classes.rbacDropdown}>
            {renderDropdownLabel()}
            <div>
                <Dropdown
                    className={classes.dropdownSelector}
                    value={dropdownTextValue}
                    selectedOptions={fileMgmtRbac ? [fileMgmtRbac] : []}
                    onOptionSelect={(_, data) => {
                        handleFileRbacChange(data.optionValue as FileRbacSetting);
                    }}
                    aria-labelledby={fileRbacDropdownId}
                    disabled={!rbacDropdownEnabled()}
                >
                    {FileRbacDropdownOptions.map((option) => (
                        <Option key={option.value} value={option.value} text={t(option.text)}>
                            {t(option.text)}
                        </Option>
                    ))}
                </Dropdown>
            </div>
        </div>
    );
}
