import {TenantInfo} from '@/api/tenant';
import {FileRbacSetting, PluginRbacSetting, PluginRbacSettingScope} from './Preferences.types';
import {FileRbacSettingOptions, PluginRbacSettingOptions} from './Preferences.constants';
import {WorkspaceSettings} from '@/api/workspaces/workspaces.types';

/**
 * Generates an array of dropdown options for the PluginRbacSetting enum.
 * Each option contains a value and corresponding text.
 *
 * @returns {Array<{ value: string, text: string }>} An array of dropdown options.
 */
export const PluginRbacDropdownOptions = Object.keys(PluginRbacSetting).map((setting) => {
    return {
        value: setting,
        text: PluginRbacSettingOptions[setting as PluginRbacSetting],
    };
});

/**
 * Generates an array of dropdown options for the FileRbacDropdown enum.
 * Each option contains a value and corresponding text.
 *
 * @returns {Array<{ value: string, text: string }>} An array of dropdown options.
 */
export const FileRbacDropdownOptions: Array<{value: string; text: string}> = Object.keys(
    FileRbacSetting,
).map((setting) => {
    return {
        value: setting,
        text: FileRbacSettingOptions[setting as FileRbacSetting],
    };
});

/**
 * Retrieves the default plugin RBAC (Role-Based Access Control) setting based on the provided plugin RBAC scope and tenant information.
 * @param {PluginRbacSettingScope} pluginRbacScope - The plugin RBAC scope.
 * @param {TenantInfo} [tenantInfo] - Optional tenant information.
 * @returns {PluginRbacSetting} The default plugin RBAC setting.
 */
export const getDefaultPluginRbacSetting = (
    pluginRbacScope: PluginRbacSettingScope,
    tenantInfo?: TenantInfo,
    workspace?: WorkspaceSettings,
): PluginRbacSetting => {
    let useRbacSettingAny = true;
    // multi-workspaces
    if (workspace) {
        if (pluginRbacScope === PluginRbacSettingScope.USER) {
            useRbacSettingAny = workspace.allowUserPluginUpload !== false;
        } else if (pluginRbacScope === PluginRbacSettingScope.TENANT) {
            useRbacSettingAny = workspace.allowWorkspacePluginUpload !== false;
        }
    } else {
        if (tenantInfo) {
            if (pluginRbacScope === PluginRbacSettingScope.USER) {
                useRbacSettingAny = tenantInfo.allowUserPluginUpload !== false;
            } else if (pluginRbacScope === PluginRbacSettingScope.TENANT) {
                useRbacSettingAny = tenantInfo.allowTenantPluginUpload !== false;
            }
        }
    }
    return useRbacSettingAny ? PluginRbacSetting.ANY : PluginRbacSetting.ADMIN;
};

/**
 * Retrieves the current file RBAC (Role-Based Access Control) setting based on the provided tenant information.
 * @param {TenantInfo} [tenantInfo] - Optional tenant information.
 * @returns {FileRbacSetting} The current file RBAC setting.
 */
export const getCurrentFileSetting = (
    tenantInfo?: TenantInfo,
    workspace?: WorkspaceSettings,
): FileRbacSetting => {
    if (workspace) {
        return workspace?.allowUserFileUpload ? FileRbacSetting.ON : FileRbacSetting.OFF;
    } else {
        return tenantInfo?.allowUserFileUpload ? FileRbacSetting.ON : FileRbacSetting.OFF;
    }
};
