import {WarningIcon} from '@/components/ui/icons';
import {CAPACITY_BUCKETS, CAPACITY_LIMIT_TYPES} from '../../UsageDashboard.constants';

export const getDefaultMapForHoverMatching = (): {
    [key: string]: {icon: JSX.Element; status: string; ariaLabel: string};
} => {
    return {
        [`${CAPACITY_LIMIT_TYPES.BELOW_THRESHOLD}`]: {
            icon: <></>,
            status: '',
            ariaLabel: `${CAPACITY_LIMIT_TYPES.BELOW_THRESHOLD}.UnitStatusAriaLabel`,
        },
        [`${CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD}`]: {
            icon: <WarningIcon color="#FFBA66" data-testid="above-threshold-warning-icon" />,
            status: `${CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD}.UnitStatusText`,
            ariaLabel: `${CAPACITY_LIMIT_TYPES.ABOVE_THRESHOLD}.UnitStatusAriaLabel`,
        },
        [`${CAPACITY_LIMIT_TYPES.THROTTLED}`]: {
            icon: <WarningIcon filled color="#C50F1F" data-testid="throttled-warning-icon" />,
            status: `${CAPACITY_LIMIT_TYPES.THROTTLED}.UnitStatusText`,
            ariaLabel: `${CAPACITY_LIMIT_TYPES.THROTTLED}.UnitStatusAriaLabel`,
        },
    };
};
