import {useTranslation} from 'react-i18next';
import useClasses from './UsageDashboard.styles';
import {NumericDisplayEmphasis, SmallTitle} from '@/components/ui/Text';
import {
    BASE_UNITS_PER_HOUR,
    ButtonLabels,
    CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE,
    ScuAllocationLabels,
    UNIT_PLURAL_TEXT,
    UNIT_SINGLE_TEXT,
} from './UsageDashboard.constants';
import {Button} from '@fluentui/react-components';
import {useCallback, useEffect, useRef, useState} from 'react';
import ChangeCapacity from '../ChangeCapacityDialog/ChangeCapacity';
import ChangeUnitsButtonPalette from '../ChangeCapacityDialog/ChangeUnitsButtonPalette';
import {OverageCapacityDisplayData} from './UsageDashboard.types';
import UnitsDisplay from '../ChangeCapacityDialog/UnitsDisplay';
import DateFilterDropdownV2 from './DateFilterDropdown/DateFilterDropdownV2';
import {DateFilterChangedProps} from './DateFilterDropdown/DateFilterDropdown.types';
import UsageDashboardFilterContainer from './Filter/UsageDashboardFilterContainer';
import {KeyValueFilterDetails, SearchFacet, SelectedSearchFacet} from '@/api/capacities';
import {convertFacetsToSelectedFilters} from './UsageDashboard.utils';
import SelectedFilterPillsSection from './SelectedFilterPillsSection';

/**
 * Props for the CapacityAllocationSection component.
 *
 * @interface CapacityAllocationSectionProps
 *
 * @property {boolean} showChangeUnitsComponents - Flag to show or hide the components for changing units.
 * @property {boolean} disableChangeCapacity - Flag to enable or disable the capacity change functionality.
 * @property {number} assignedCapacityUnits - The number of capacity units assigned.
 * @property {OverageCapacityDisplayData} usageDisplayDetails - The details to be displayed for overage capacity.
 * @property {boolean} isPageLoading - Flag to indicate if the page is loading.
 * @property {boolean} isCapacityUsageError - Flag to indicate if there is an error in capacity usage.
 * @property {string} dateRangeOption - The selected date range option.
 * @property {Function} dateRangeOptionChanged - The function to be called when the date range option is changed.
 * @property {string} dateRangeOption - The selected date range option.	
 * 

 */
interface CapacityAllocationSectionProps {
    isOverageDisplayEnabled: boolean;
    dateRangeOption: string;
    dateRangeOptionChanged: (title: DateFilterChangedProps) => void;
    showChangeUnitsComponents: boolean;
    disableChangeCapacity: boolean;
    assignedCapacityUnits: number;
    usageDisplayDetails: OverageCapacityDisplayData;
    isPageLoading: boolean;
    isCapacityUsageError: boolean;
    isCapacityUsageDataPresent: boolean;
    areSearchFiltersPristine: boolean;
    searchFilters: SelectedSearchFacet[];
    filterDate: SearchFacet[];
    isHourlyBarActive: boolean;
    isEvaluationAggregateDataLoading: boolean;
    copilotExperienceMap: KeyValueFilterDetails[];
    onFiltersUpdate: (filters: SelectedSearchFacet[]) => void;
}

export default function CapacityAllocationSection(props: CapacityAllocationSectionProps) {
    const {t: tAdmin} = useTranslation('admin');
    const {t: tCommon} = useTranslation('common');

    const classes = useClasses();

    // Used to open and close the change capacity dialog
    const changeCapacityButtonRef = useRef<HTMLButtonElement>(null);
    const [isChangeCapacityDialogOpen, setChangeCapacityDialogOpen] = useState<boolean>(false);
    const [shouldFocusChangeUnitsButton, setShouldFocusChangeUnitsButton] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [shouldFocusFilterButton, setShouldFocusFilterButton] = useState<boolean>(false);

    const getCapacityUnitsText = () => {
        if (!props.assignedCapacityUnits) {
            return '';
        }
        const unitText =
            props.assignedCapacityUnits === 1 ? tAdmin(UNIT_SINGLE_TEXT) : tAdmin(UNIT_PLURAL_TEXT);
        return `${props.assignedCapacityUnits} ${unitText}`;
    };

    useEffect(() => {
        if (isChangeCapacityDialogOpen) {
            setShouldFocusChangeUnitsButton(false);
        }
    }, [isChangeCapacityDialogOpen]);

    const performActionsOnCapacityDialogClosure = () => {
        setChangeCapacityDialogOpen(false);
        !props.isOverageDisplayEnabled && changeCapacityButtonRef.current?.focus();
        props.isOverageDisplayEnabled && setShouldFocusChangeUnitsButton(true);
    };

    const containerClass = props.isOverageDisplayEnabled
        ? classes.metadataOverage
        : classes.metaData;

    //Populating overage content
    const isSingularUnit = useCallback((unit: number) => unit == 1, []);

    //Assigning Provisioned units items
    const {
        provisionedUnitsAvailable,
        provisionedUnitsUsed,
        overageUnitsAvailable,
        overageUnitsUsed,
        totalOverageUnitsUsed,
    } = props.usageDisplayDetails;

    const provisionedUnitDetailsHeader = tAdmin(ScuAllocationLabels.ProvisionedHeader);

    const provisionedUnits = {used: provisionedUnitsUsed, available: provisionedUnitsAvailable};
    const provisionedUnitsNumericDisplay = isSingularUnit(provisionedUnitsAvailable)
        ? tAdmin(ScuAllocationLabels.UnitsAvailableSingular, provisionedUnits)
        : tAdmin(ScuAllocationLabels.UnitsAvailablePlural, provisionedUnits);

    const provisionedUnitsFooter = tAdmin(ScuAllocationLabels.ProvisionedFooter);

    //Assigning Overage units items
    const overageUnitDetailsHeader = tAdmin(ScuAllocationLabels.OverageHeader);
    const overageUnits = {used: overageUnitsUsed, available: overageUnitsAvailable};
    const overageUnitsNumericDisplay = isSingularUnit(overageUnitsAvailable)
        ? tAdmin(ScuAllocationLabels.UnitsAvailableSingular, overageUnits)
        : tAdmin(ScuAllocationLabels.UnitsAvailablePlural, overageUnits);

    const overageUnitsFooter = tAdmin(ScuAllocationLabels.OverageFooter);

    //Assigning Total units items
    const totalUnitDetailsHeader = tAdmin(ScuAllocationLabels.TotalOverageHeader);

    const noOfUnitsParameters = {0: totalOverageUnitsUsed};
    const totalUnitsNumericDisplay = isSingularUnit(totalOverageUnitsUsed)
        ? tAdmin(ScuAllocationLabels.TotalUnitsSingular, noOfUnitsParameters)
        : tAdmin(ScuAllocationLabels.TotalUnitsPlural, noOfUnitsParameters);

    const totalUnitsFooter = tAdmin(ScuAllocationLabels.TotalOverageFooter);

    return (
        <>
            <div className={containerClass}>
                {props.showChangeUnitsComponents && !props.isOverageDisplayEnabled && (
                    <>
                        <div>
                            <SmallTitle data-testid="base-units-element">
                                {tAdmin(BASE_UNITS_PER_HOUR)}
                            </SmallTitle>
                        </div>
                        <div
                            className={classes.capacityValueContainer}
                            data-testid="capacity-units-display"
                        >
                            <NumericDisplayEmphasis>
                                {getCapacityUnitsText()}
                            </NumericDisplayEmphasis>
                        </div>
                    </>
                )}
                <div data-testid="show-change-units-section">
                    {!props.isOverageDisplayEnabled && props.showChangeUnitsComponents ? (
                        <Button
                            appearance="secondary"
                            onClick={() => setChangeCapacityDialogOpen(true)}
                            ref={changeCapacityButtonRef}
                            disabled={props.disableChangeCapacity}
                        >
                            {tCommon(ButtonLabels.CHANGE_CAPACITY)}
                        </Button>
                    ) : (
                        <div />
                    )}
                </div>

                {props.showChangeUnitsComponents && props.isOverageDisplayEnabled && (
                    <>
                        <ChangeUnitsButtonPalette
                            shouldFocusChangeUnitsButton={shouldFocusChangeUnitsButton}
                            onChangeUnitsClick={() => setChangeCapacityDialogOpen(true)}
                        ></ChangeUnitsButtonPalette>
                        <div
                            className={classes.capacityDataDisplayWrapper}
                            data-testid="overage-units-display-container"
                        >
                            <div className={classes.displayWrapperOne}>
                                <UnitsDisplay
                                    data-testid="provisioned-units-section"
                                    heading={provisionedUnitDetailsHeader}
                                    numericDisplay={provisionedUnitsNumericDisplay}
                                    footer={provisionedUnitsFooter}
                                ></UnitsDisplay>
                                <UnitsDisplay
                                    data-testid="overage-units-section"
                                    heading={overageUnitDetailsHeader}
                                    numericDisplay={overageUnitsNumericDisplay}
                                    footer={overageUnitsFooter}
                                ></UnitsDisplay>
                            </div>
                            <div className={classes.displayWrapperTwo}>
                                <UnitsDisplay
                                    data-testid="total-allocated-units-section"
                                    heading={totalUnitDetailsHeader}
                                    numericDisplay={totalUnitsNumericDisplay}
                                    footer={totalUnitsFooter}
                                ></UnitsDisplay>
                                <div
                                    className={classes.dateAndFilterWrapper}
                                    data-testid="overage-date-filter-section"
                                >
                                    <DateFilterDropdownV2
                                        className={classes.dateFilterDropdown}
                                        isVisible={
                                            !props.isPageLoading && !props.isCapacityUsageError
                                        }
                                        onOptionChanged={props.dateRangeOptionChanged}
                                        selectedOptionText={props.dateRangeOption}
                                        minSearchStartDate={
                                            CUSTOM_CAPACITY_USAGE_SEARCH_DATE_RANGE.LAST_90_DAYS
                                        }
                                    />
                                    {!props.isCapacityUsageError && (
                                        <div className={classes.rightAlign}>
                                            <SelectedFilterPillsSection
                                                isOverageEnabled={props.isOverageDisplayEnabled}
                                                copilotExperienceMap={props.copilotExperienceMap}
                                                isHourlyBarActive={props.isHourlyBarActive}
                                                isEvaluationAggregateDataLoading={
                                                    props.isEvaluationAggregateDataLoading
                                                }
                                                searchFilters={props.searchFilters}
                                                shouldFocusFilterButton={shouldFocusFilterButton}
                                                onFiltersUpdated={props.onFiltersUpdate}
                                                onFilterButtonClick={() => {
                                                    setIsFilterOpen(true);
                                                    setShouldFocusFilterButton(false);
                                                }}
                                            ></SelectedFilterPillsSection>
                                        </div>
                                    )}
                                    {!props.isPageLoading && (
                                        <>
                                            <UsageDashboardFilterContainer
                                                filterData={props.filterDate ?? []}
                                                isOpen={isFilterOpen}
                                                onFilterClose={() => {
                                                    setIsFilterOpen(false);
                                                    setShouldFocusFilterButton(true);
                                                }}
                                                onFiltersUpdate={(filters) => {
                                                    setShouldFocusFilterButton(true);
                                                    props.onFiltersUpdate(filters);
                                                }}
                                                shouldResetFilters={props.areSearchFiltersPristine}
                                                selectedFilters={convertFacetsToSelectedFilters(
                                                    props.searchFilters,
                                                )}
                                            ></UsageDashboardFilterContainer>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {props.showChangeUnitsComponents && (
                    <ChangeCapacity
                        isChangeCapacityDialogOpen={isChangeCapacityDialogOpen}
                        onChangeCapacityDialogClose={performActionsOnCapacityDialogClosure}
                        isOverageEnabled={props.isOverageDisplayEnabled}
                    />
                )}
            </div>
        </>
    );
}
