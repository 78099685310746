import {format} from 'date-fns';
import {getDayName, getHourlyTimeframe} from '../../UsageDashboard.utils';
import {DATETIME_FORMAT} from '../../UsageDashboard.constants';

interface DateDisplaySectionProps {
    usageDate: Date;
}

export default function DateDisplaySection({usageDate}: DateDisplaySectionProps) {
    const usageDayName = getDayName(usageDate);
    const displayDate = format(usageDate, DATETIME_FORMAT.TABLE_COLUMN_DATE);
    const hourlyTimeframe = getHourlyTimeframe(usageDate);

    return (
        <div>
            <div data-testid="hovercard-display-date">{displayDate}</div>
            <div data-testid="hovercard-day-name">{usageDayName}</div>
            <div data-testid="hovercard-timeframe">{hourlyTimeframe}</div>
        </div>
    );
}
