import * as React from 'react';
import {
    Button,
    Input,
    ToolbarDivider,
    mergeClasses,
    useRestoreFocusSource,
    useRestoreFocusTarget,
} from '@fluentui/react-components';
import {SearchIcon, DismissIcon} from '@/components/ui/icons';
import {SkillsetFilterTypes} from '@/api/skills';
import useClasses from '../../ConnectorModalContent.styles';
import {useTranslation} from 'react-i18next';
import StatusFilterButtons from './StatusFilterButtons';
import StatusFilterDropdown from './StatusFilterDropdown';
import CategoryFilterDropdown from './CategoryFilterDropdown';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';

interface FilterButtonsComponentProps {
    searchText: string;
    searchModeEnabled: boolean;
    onSearchIconClick: () => void;
    onSearchTextChange: (text: string) => void;
    onClearSearch: () => void;
    enabledCount: number;
    disabledCount: number;
    notConfiguredCount: number;
    totalCount: number;
    displayedSkillsetsFilter: SkillsetFilterTypes;
    onFilterButtonClick: React.Dispatch<React.SetStateAction<SkillsetFilterTypes>>;
    setSelectedCategories: (selectedCategories: Record<string, string[]>) => void;
    selectedCategories: Record<string, string[]>;
    displayableCategories: string[];
}

export default function FilterButtonsComponent({
    searchText,
    searchModeEnabled,
    onSearchIconClick,
    onSearchTextChange,
    onClearSearch,
    enabledCount,
    disabledCount,
    notConfiguredCount,
    totalCount,
    displayedSkillsetsFilter,
    onFilterButtonClick,
    setSelectedCategories,
    selectedCategories,
    displayableCategories,
}: FilterButtonsComponentProps) {
    const classes = useClasses();
    const {t} = useTranslation('plugins');

    // focus utility to help restore focus to the correct element after closing the modal, this is where we can come from
    const restoreFocusSourceAttribute = useRestoreFocusSource();
    // focus utility to help restore focus to the correct element after closing the modal, this is what we want to go back to
    const restoreFocusTargetAttribute = useRestoreFocusTarget();

    // update selectedCategories when categories are checked/unchecked
    const handleCheckedCategoriesChange = (
        e: any,
        {name, checkedItems}: {name: string; checkedItems: string[]},
    ) => {
        // Add category if it is checked, otherwise remove it
        if (checkedItems.length > 0) {
            setSelectedCategories({...selectedCategories, [name]: checkedItems});
        } else {
            const {[name]: _, ...rest} = selectedCategories;
            setSelectedCategories(rest);
        }
    };

    const isAdvancedPluginFilteringEnabled = useFeatureFlag(
        MedeinaFeatures.EnableAdvancedPluginFiltering,
    );

    return (
        <div className={classes.popoverSearch}>
            <Button
                {...restoreFocusTargetAttribute}
                onClick={onSearchIconClick}
                className={mergeClasses(
                    classes.btnSearchStart,
                    searchModeEnabled && classes.btnSearchHidden,
                )}
                icon={<SearchIcon />}
                aria-label={t('ContentModal.SearchPlugins')}
                tabIndex={0}
            />

            {searchModeEnabled && (
                <Input
                    {...restoreFocusSourceAttribute}
                    className={classes.searchbar}
                    type="text"
                    autoFocus
                    placeholder={t('ContentModal.SearchPlugins')}
                    value={searchText}
                    onChange={(_event: any, textbox: any) => {
                        onSearchTextChange(textbox.value);
                    }}
                    contentBefore={<SearchIcon />}
                    appearance="underline"
                    contentAfter={
                        <Button
                            onClick={onClearSearch}
                            className={classes.btnCancel}
                            size={'small'}
                            appearance={'outline'}
                            icon={<DismissIcon />}
                            aria-label={t('ContentModal.ClearSearch')}
                        />
                    }
                />
            )}
            <ToolbarDivider className={classes.hrule} />

            {/* Status filter buttons */}
            {!searchModeEnabled && (
                <StatusFilterButtons
                    onFilterButtonClick={onFilterButtonClick}
                    displayedSkillsetsFilter={displayedSkillsetsFilter}
                    enabledCount={enabledCount}
                    disabledCount={disabledCount}
                    notConfiguredCount={notConfiguredCount}
                    totalCount={totalCount}
                ></StatusFilterButtons>
            )}

            {/* Status filter dropdown */}
            {isAdvancedPluginFilteringEnabled && searchModeEnabled && (
                <StatusFilterDropdown
                    onFilterButtonClick={onFilterButtonClick}
                    displayedSkillsetsFilter={displayedSkillsetsFilter}
                ></StatusFilterDropdown>
            )}
            {/* {isAdvancedPluginFilteringEnabled && 
                (<ToolbarDivider className={classes.hrule} />)
            } */}

            {/* Category filter dropdown */}
            {isAdvancedPluginFilteringEnabled && (
                <>
                    <ToolbarDivider className={classes.hrule} />
                    <CategoryFilterDropdown
                        selectedCategories={selectedCategories}
                        displayableCategories={displayableCategories}
                        onCheckedValueChange={handleCheckedCategoriesChange}
                    ></CategoryFilterDropdown>
                </>
            )}
        </div>
    );
}
