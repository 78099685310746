import {
    IVerticalStackedChartProps,
    IVSChartDataPoint,
    VerticalStackedBarChart,
} from '@fluentui/react-charting';
import {VStackedBarChartProps} from './VStackedBarChart.types';
import BarChartHoverCard from './BarChartHoverCard/BarChartHoverCard';
import {useEffect, useMemo, useRef, useState} from 'react';
import useClasses from './VStackedBarChart.styles';
import {transformDataForGraph} from '../UsageDashboard.utils';
import {format, getHours} from 'date-fns';
import {DATETIME_FORMAT} from '../UsageDashboard.constants';
import {useTranslation} from 'react-i18next';
import {useFocusableGroup} from '@fluentui/react-components';
import {addHours} from 'date-fns';
import useGetBarChartMeasurements from './useGetBarChartAttributes';

export default function VStackedBarChart(props: VStackedBarChartProps) {
    const classes = useClasses();
    const {data} = props;
    const {t: tAdmin} = useTranslation('admin');
    const focusAttributes = useFocusableGroup({tabBehavior: 'limited-trap-focus'});
    const [barSelected, setBarSelected] = useState<boolean>(false);
    const {barWidth, barRadius, barGapMax, enableReflow} = useGetBarChartMeasurements();
    const chartType = props.version == 'overage' ? 'overage' : 'v2';
    const [barChartData, setBarChartData] = useState([] as IVerticalStackedChartProps[]);

    // Transform the data to the format required by the graph
    // Replaced the earlier useMemo to useEffect, as the former has issues with accessing the bars within the graph
    useEffect(
        () =>
            setBarChartData(
                transformDataForGraph(data, tAdmin, props.version, props.isDisplayingFilteredData),
            ),
        [data, props.isDisplayingFilteredData, props.version],
    );

    const componentRef = useRef<HTMLDivElement>(null);

    // The xTickValues are used to set the x-axis ticks for the chart based on customFormatting
    const xTickValues = useMemo(() => {
        return barChartData.map((item) => new Date(item.xAxisPoint));
    }, [barChartData]);

    /**
     * The VerticalStackedBarChart height doesn't adapt to line data, which can hide the assignedCapacity line if it significantly exceeds usedCapacity.
     * To ensure visibility, we calculate maxCapacityValue as the maximum of assignedCapacity and usedCapacity.
     * We then multiply this value by 1.2 to ensure some spacing after max value.
     */
    const maxCapacityValue = useMemo(() => {
        const value = data.reduce(
            (max, item) => Math.max(max, item.assignedCapacity, item.usedCapacity),
            0,
        );

        return 1.2 * value;
    }, [data]);

    // Custom callout component to display as per our own design and use case.
    const renderCalloutPerStack = (props?: IVerticalStackedChartProps) => {
        return props ? <BarChartHoverCard calloutData={props} chartType={chartType} /> : null;
    };

    // Custom formatter for displaying 12pm and day name as appropriate
    const formatXAxisTicks = (date: Date): string => {
        const hours = getHours(date);

        let formattedDate = '';

        if (hours === 12) {
            formattedDate = '12PM';
        } else if (hours === 0) {
            formattedDate = format(date, DATETIME_FORMAT.BARCHART_XAXIS_TICKS);
        }

        return formattedDate;
    };

    // Custom formatter for small values in Y-Axis
    // For values less than 1, bar chart by default follows SI system and shows 900m instead of 0.9
    const formatYAxisTicks = (value: number): number => Math.round(value * 10) / 10;

    function graphBarClickHandler(
        event: React.MouseEvent<SVGElement, MouseEvent>,
        data: IVerticalStackedChartProps | IVSChartDataPoint,
    ): void {
        const xAxisPoint = new Date((data as IVerticalStackedChartProps).xAxisPoint);
        const startDate = new Date(xAxisPoint);
        const endDate = addHours(startDate, 1);

        if (event.type === 'click' || event.type === 'keydown') {
            props.onGraphBarClick?.(startDate, endDate);
        }
        setBarSelected(true);
    }

    useEffect(() => {
        const handleGraphBarClick = (event: any) => {
            // Added the check on event.target.tagName to avoid the click event on the callout to be considered as a click on the bar
            if (barSelected && event?.target.tagName == 'svg') {
                setBarSelected(false);
                props.onGraphBarBlur?.();
            }
        };
        componentRef.current?.addEventListener('click', handleGraphBarClick);

        return () => {
            componentRef.current?.removeEventListener('click', handleGraphBarClick);
        };
    }, [barSelected]);

    return (
        <div
            className={classes.root}
            tabIndex={0}
            {...focusAttributes}
            ref={componentRef}
            data-testid={props.dataTestId}
        >
            <VerticalStackedBarChart
                yMaxValue={maxCapacityValue}
                data={barChartData}
                enableReflow={enableReflow}
                showXAxisLablesTooltip={true}
                allowHoverOnLegend={false}
                hideLegend={true}
                yAxisTickFormat={formatYAxisTicks}
                customDateTimeFormatter={formatXAxisTicks}
                tickValues={xTickValues}
                isCalloutForStack={true}
                onRenderCalloutPerStack={renderCalloutPerStack}
                onBarClick={graphBarClickHandler}
                barGapMax={barGapMax}
                barWidth={barWidth}
                barCornerRadius={barRadius}
                styles={{
                    xAxis: classes.axisTicks,
                    yAxis: classes.axisTicks,
                    axisTitle: classes.axisTitle,
                    chartWrapper: classes.chartWrapper,
                }}
            />
        </div>
    );
}
