import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {GetAccountResponse} from '.';
import useFetch from '../useFetch';
import {FIDELIS_ERROR_CODES, RESOURCE_SCOPES} from '../api.constants';
import {FidelisApiErrorModel, FidelisErrorType} from '../api.types';
import {FidelisApiError} from '../api';
import securityGraphApi from '@/api/securityGraph.api';

export default function useGetAccount(
    useQueryOptions?: Pick<UseQueryOptions<GetAccountResponse>, 'enabled' | 'onError'>,
) {
    const url = securityGraphApi({path: '/provisioning/account', isGlobal: true});
    const {customFetch} = useFetch();

    return useQuery<GetAccountResponse>({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['account'],
        queryFn: async () => {
            var response = await customFetch<Response>(
                url,
                {
                    method: 'GET',
                    scopes: RESOURCE_SCOPES.FIDELIS,
                },
                true,
            );

            if (!response.ok) {
                if (response.status === 404) {
                    try {
                        var errorContent = (await response.json()) as FidelisApiErrorModel;
                    } catch (error) {
                        // This is for the case where the error is not a JSON object
                        throw new Error(`Failed to get account: ${response.statusText}`);
                    }

                    if (
                        errorContent?.error?.code === String(FIDELIS_ERROR_CODES.TENANT_NOT_EXISTS)
                    ) {
                        // If the Fidelis error code is 3000 for http code 404 , then the account or geo does not exist
                        throw new FidelisApiError(
                            errorContent?.error?.message,
                            response,
                            FidelisErrorType.ACCOUNT_GEO_NOT_EXISTS,
                        );
                    }
                }

                // This is for the fallback case where the error is not a FidelisApiError
                throw new Error(`Failed to get account: ${response.statusText}`);
            }

            var data = await response.json();
            return data as GetAccountResponse;
        },
        retry: false,
        ...useQueryOptions,
    });
}
