import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
} from '@fluentui/react-components';
import {useTranslation} from 'react-i18next';
import useClasses from './SetPluginAvailabilityDialog.styles';

interface SetPluginAvailabilityDialogProps {
    isSetPluginAvailabilityDialogOpen: boolean;
    dialogTitle: string;
    dialogDescription: string;
    acceptTitle: string;
    onAccept: () => void;
    onReject: () => void;
}

export default function SetPluginAvailabilityDialog({
    isSetPluginAvailabilityDialogOpen,
    dialogTitle,
    dialogDescription,
    acceptTitle,
    onAccept,
    onReject,
}: SetPluginAvailabilityDialogProps) {
    const {t} = useTranslation('plugins');
    const classes = useClasses();

    return (
        <>
            <Dialog open={isSetPluginAvailabilityDialogOpen}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle className={classes.titleContainer}>
                            <div>{dialogTitle}</div>
                        </DialogTitle>
                        <DialogContent className={classes.descriptionContainer}>
                            <div>{dialogDescription}</div>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                data-test-id="settings-button"
                                appearance="primary"
                                aria-label={acceptTitle}
                                onClick={onAccept}
                            >
                                {acceptTitle}
                            </Button>
                            <Button
                                data-test-id="settings-button"
                                appearance="secondary"
                                aria-label={t('common.Cancel')}
                                onClick={onReject}
                            >
                                {t('common.Cancel')}
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
}
